<template>
  <a-row
    type="flex"
    :gutter="16"
  >
    <a-col :xs="{ span: 24 }" :lg="{ span: 5 }">
      <ol
        v-scroll-spy-link
        v-scroll-spy-active="{ class: 'active' }"
        class="position-sticky p-0 m-0 mt-4"
      >
        <li
          v-for="(label, key) in sections"
          :key="key"
        >
          <a
            class=""
            @click.prevent
          >{{ label }}</a>
        </li>
      </ol>
    </a-col>

    <a-col :xs="{ span: 24 }" :lg="{ span: 19 }">
      <ValidationObserver ref="validationObserver" slim>
        <a-form novalidate @submit.prevent="saveProducts">
          <fieldset
            v-scroll-spy="{ data: 'activeSection', offset: 125 }"
            class="main"
            :disabled="submitting || loadingDetail"
          >
            <DetailProduct
              v-model="model"
              :categories="categories"
              :tiktok-categories="tiktokCategories"
              class="mb-5"
              :detail="detailModel"
              :loading="submitting || loadingDetail || loadingVariantTiktok"
              :rendering="loading"
              :permission="permission"
              :attributes="rawAttributeLists"
              @offscreen="runOnDraft(debouncedSaveDetail)"
              @fetchVariant="fetchVariantTiktok"
              @changeEditable="changeEditable"
              @saveEdit="saveEdit"
              @onChangeChannelAttribute="onChangeChannelAttribute"
            />

            <PhotoAndVideo
              v-model="model"
              class="mb-5"
              :permission="permission"
              :loading="loading"
              :submitting="submitting || loadingDetail"
              @change="debouncedSaveImages"
              @changeEditable="changeEditable"
              @saveEdit="saveEdit"
            />

            <TypeAndPrice
              v-model="model"
              :business="business"
              class="mb-5"
              :loading="loading"
              :catalogs="catalogsModel"
              :submitting="submitting || loadingDetail"
              :permission="permission"
              @offscreen="runOnDraft(debouncedSaveCatalogues)"
              @changeuom="onChangeUom"
              @changeEditable="changeEditable"
              @saveEdit="saveEdit"
            />

            <WeightAndShipping
              v-model="model.units"
              :product="model"
              class="mb-5"
              :loading="loading"
              :permission="permission"
              :item-cod="itemCod"
              @input:physical="(value) => model.detail.physical = value"
              @cancelBaseUomChange="(value) => {
                model.catalogs.items[0].price.uom = value
              }"
              @offscreen="runOnDraft(debouncedSaveWeightAndShipping)"
              @changeUkuranPaket="changeUkuranPaket"
              @changeEditable="changeEditable"
              @changeCodProduct="changeCodProduct"
            />

            <!--<WarehouseAndInventories
              v-model="model"
              :warehouses="warehouses"
              :submitting="submitting"
              :loading="loading"
              :inventory-list="inventoryList"
              :permission="permission"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveWarehousesAndInventories)"
              @changeEditable="changeEditable"
              @createAllocation="createAllocation"
            />-->

            <!-- <ChannelPublication 
              v-model="model.channels"
              :channels="channels"
              :product="model"
            /> -->

            <div class="mt-4 mb-5 py-3 border-top text-right footer">
              <!-- <div>
                <a-button
                  size="large"
                  type="primary"
                  ghost
                  class="px-5 mr-3 ml-auto"
                  @click="onCancel"
                >
                  {{ $t('utils.cancel') }}
                </a-button>

                :disabled="submitting || !$can('group1.product.product-master.WRITE')"
                <a-button
                  size="large"
                  type="primary"
                  class="px-5"
                  html-type="submit"
                  :disabled="submitting || !$can('group1.product.product-master.WRITE') || loadingDetail"
                >
                  <a-icon v-if="submitting" type="loading" class="mr-2" />
                  {{ $t('utils.save') }}
                </a-button>
              </div> -->

              <a-button
                size="large"
                type="primary"
                ghost
                class="px-5 mr-3 ml-auto"
                :disabled="submitting"
                @click="onCancel"
              >
                {{ $t('utils.cancel') }}
              </a-button>

              <a-button
                v-if="permission.includes('WRITE') && !$route.query.edit"
                size="large"
                type="primary"
                class="px-5"
                html-type="submit"
                :loading="submitting"
                :disabled="submitting || loadingDetail"
              >
                {{ $t('utils.save') }}
              </a-button>
            </div>
          </fieldset>
        </a-form>
      </ValidationObserver>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue'
import DetailProduct from './DetailProduct.vue'
import { ValidationObserver } from 'vee-validate'
import PhotoAndVideo from './PhotoAndVideo.vue'
import debounce from 'lodash/debounce'
import TypeAndPrice from './TypeAndPrice/index.vue'
import { useFormula } from './TypeAndPrice/Price/useFormula'
import WeightAndShipping from './WeightAndShipping/index.vue'
// import WarehouseAndInventories from './WarehouseAndInventories/index.vue'
// import ChannelPublication from './Channel/ChannelPublication.vue'
import {
  getChannelCategories,
  getChannelAttributes,
  channelEventProcess,
  syncChannelAttributes,
  addChannelProduct,
  updateProductChannel,
  deleteChannelDraft,
} from '@/api/channels/index'
import { getInventoryListV2, getUom, addWarehouse, addAllocationStock, saveProductInventory } from '@/api/inventory'
import { getShippingUnit, saveProductShipping } from '@/api/shipping'
import {
  getProductCategories,
  addProductChannel,
  saveProductImages,
  crudProduct,
  updateCatalogsProduct,
  deleteProduct,
} from '@/api/product'
import { getPriceRange, saveProductPrice } from '@/api/price'
import { getBusinessChannel } from '@/api/business'
import { getWarehouseTiktok } from '@/api/channels/tiktok'
import buildTree from '@/utils/buildTree'

const { fetchPriceFormula } = useFormula()

const DEFAULT_MODEL = () => ({
  id: null,
  status: 'DRAFT',
  detail: {
    brand_id: null,
    category_id: null,
    category_tiktok_id: [],
    condition: 'NEW',
    imported: false,
    long_description: '',
    title: '',
    physical: true,
    vat_type: 'INCLUDE',
  },
  images: [],
  imagesSize: [],
  catalogs: {
    attributes: null,
    options: null,
    items: [
      {
        id: null,
        factory_sku: null,
        images: [],
        option: null,
        price: {
          currency: 'IDR',
          value: 0,
          min_qty: 1,
          price_qty: 1,
          uom: 'PCS',
          price_id: null,
          formula_id: null,
          product_variables: [],
        },
        sku: null,
        status: true,
        title: null,
        ext_catalog_id: null,
      },
    ],
    bundling: {
      type: null,
      bundles: [],
    },
  },
  units: [
    {
      unit: 'PCS',
      numerator: 1,
      denumerator: 1,
      package: {
        length: null,
        length_unit: 'cm',
        width: null,
        width_unit: 'cm',
        height: null,
        height_unit: 'cm',
        weight: null,
        weight_unit: 'kg',
      },
      collapseOpen: false,
      logistic_info: [],
    },
  ],
  inventories: [],
  channels: [],
  warehouse: {},
})

const parseErrorMessage = msg => {
  let result = 'Coba lagi beberapa saat'
  if (msg && msg.includes('Sku Already in Business')) {
    result = `Kode SKU ${msg.split(' ').pop()} telah terdaftar pada produk lain`
  }
  return 'Oops, produk gagal disimpan! ' + result
}

export default defineComponent({
  name: 'CreateProductForm',
  components: {
    DetailProduct,
    ValidationObserver,
    PhotoAndVideo,
    TypeAndPrice,
    WeightAndShipping,
    // WarehouseAndInventories,
    // ChannelPublication,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      default: () => {},
    },
    productIdDraft: {
      type: String,
      default: '',
    },
    draftImage: {
      type: Array,
      default: () => [],
    },
    permission: {
      type: [Object, Array],
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    getDetail: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      activeSection: 0,
      saving: {
        detail: false,
      },
      /**
       * @type {import('@/types/product').ProductFormModel}
       */
      model: DEFAULT_MODEL(),
      /** @type {import('@/types/warehouse').Warehouse[]} */
      warehouses: [],
      channels: [],
      shippingUnits: [],
      categories: [],
      tiktokCategories: [],
      tiktokAttributes: [],
      inventoryList: [],
      submitting: false,
      loadingDetail: false,
      request_id_temp: '',
      eventProcess: false,
      draftPayload: {},
      detailModel: {},
      catalogsModel: {},
      editable: {
        detail: false,
        photo: false,
        price: false,
        shipping: false,
        inventory: false,
      },
      attribute_lists: [],
      rawAttributeLists: [],
      loadingVariantTiktok: false,
      itemCod: 0,
    }
  },
  computed: {
    sections() {
      return {
        detail: this.$t('product.product_details'),
        gallery: this.$t('product.photo_video'),
        price: this.$t('product.product_type_and_price'),
        shipping: this.$t('product.weight_product_shipping'),
        inventory: this.$t('product.inventory'),
        // channel: this.$t('product.channel'),
      }
    },
    businessId() {
      return this.business?.business_id || (this.$route.query.business_id ? String(this.$route.query.business_id) : null)
    },
    productId() {
      return this.model.id
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId,
      }
    },
    httpParams() {
      return {
        business_id: this.businessId,
      }
    },
    isNewProduct() {
      return !this.model.id
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value?.product_id) {
          this.createProductModel()
        }
      },
    },
    productId: {
      immediate: true,
      handler(value) {
        if (value && this.product) {
          this.fetchShippingAndInventoryUnits()
          this.fetchCatalogPrices()
          this.fetchInventory()
        }
      },
    },
    'businessId': {
      immediate: true,
      handler(businessId) {
        this.loadingDetail = true
        Promise.all([
          this.fetchCategories(),
          this.fetchCategoriesTiktok(),
          this.fetchWarehouse(),
          this.fetchChannels(),
          fetchPriceFormula({ businessId }),
        ])
        .finally(() => {
          this.loadingDetail = false
        })
      },
    },
    'model.detail.category_id'(ids) {
      const id = Number(ids[ids.length - 1])
      this.model.detail.category = !id ? {} : this.findCategory(this.categories, id)
    },
  },
  created() {
    this.debouncedSaveDetail = debounce(this.saveDetail, 50)
    this.debouncedSaveImages = debounce(this.saveImages, 500)
    this.debouncedSaveCatalogues = debounce(this.saveCatalogues, 500)
    this.debouncedSaveWeightAndShipping = debounce(this.saveWeightAndShipping, 500)
    this.debouncedSaveWarehousesAndInventories = debounce(this.saveWarehousesAndInventories, 500)
  },
  // mounted() {
  // },
  methods: {
    findCategory(lists, matched) {
      let length = lists.length
      let result = null
      for (let i = 0; i < length; i++) {
        if (lists[i].id == matched) {
          const { id, name } = lists[i]
          return { id, name }
        } else if (lists[i].children) {
          result = this.findCategory(lists[i].children, matched)
          if (result.id) return result
        }
      }
      return {}
    },
    findChildren(item, matched) {
      if (item.id === matched) {
        return [item.id];
      } else if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
          const result = this.findChildren(item.children[i], matched);
          if (result) {
            return [item.id, ...result];
          }
        }
      }
      return null;
    },
    findParent(array, matched) {
      for (let i = 0; i < array.length; i++) {
        const result = this.findChildren(array[i], matched);
        if (result) {
          return result;
        }
      }
      return [];
    },
    changeEditable(e) {
      const key = Object.keys(e)[0];
      this.editable[key] = e[key];
    },
    getEventProcess: debounce(async function(request_id){
      this.eventProcess = false
      try {
        const response = await channelEventProcess({
          business_id: this.$route.query.business_id || this.$store.state.user.businessList[0].business_id,
          channel_code: 'tiktok',
          channel_id: this.$route.params.id,
          request_id,
        })
        if (response.data.status === "FAILED") {
          this.submitting = false
          this.eventProcess = false
          this.request_id_temp = ''
          this.$message.error(parseErrorMessage('Product Gagal Ditambahkan, Coba Lagi!'), 5)
        } if (response.data.status === "ON_PROGRESS") {
          this.eventProcess = true
          this.getEventProcess(request_id)
        } else if (response.data.status === "FINISHED") {
          this.eventProcess = false
          this.submitting = false
          this.$message.success('Produk berhasil di Simpan!', 5)
          this.$refs.validationObserver.reset()
          // this.deleteDraftProduct()
          this.$nextTick(() => setTimeout(() => {
            this.submitting = false
            if(!this.$route.query.edit) {
              const { workspace_id, business_id, redirect_url } = this.$route.query
              this.$router.push({ path: redirect_url, query: { workspace_id, business_id } })
            } else {
              this.getDetail()
            }
          }, 150))
        }
      } catch (e) {
        console.error({e})
        if (e.response.status === 404) {
          this.eventProcess = true
          this.request_id_temp = request_id
        } else {
          this.submitting = false
          this.eventProcess = false
          this.request_id_temp = ''
        }
      }
    }, 1000),
    changeUkuranPaket(val, index) {
      this.model.units.forEach((item, idx) => {
        if (idx === index) {
          item.package = {
            ...item.package,
            length_unit: val,
            width_unit: val,
            height_unit: val,
            // weight_unit: val,
          }
        }
      })
    },
    onChangeUom(uom) {
      this.model.catalogs.items.forEach(item => {
        item.price = { ...item.price, uom }
      })
    },
    runOnDraft(fn) {
      if (this.model.status === 'DRAFT') {
        return fn()
      }
    },
    fetchCategories: debounce(async function() {
      const { data: { data } } = await getProductCategories(this.businessId)
      this.categories = data || []
      const parent = this.product?.detail?.category?.id ? this.findParent(data, this.product.detail.category.id) : []
      if (parent.length) this.model.detail.category_id = parent
    }, 500),
    async syncCategoriesShopee() {
      const shop_id = 'c9ee7774-8494-45e3-8398-bf3e528be375'
      await syncChannelAttributes({
        business_id: this.businessId,
        channel_code: 'shopee_id',
        channel_id: this.$route.params.id,
        path: 'categories',
        data: { shop_id },
      })
    },
    async syncAttributesTiktok() {
      const shop_id = 'c9ee7774-8494-45e3-8398-bf3e528be375'
      await syncChannelAttributes({
        business_id: this.businessId,
        channel_code: 'tiktok_id',
        channel_id: this.$route.params.id,
        path: 'attributes',
        data: { shop_id },
      })
    },
    async fetchInventory() {
      this.loadingDetail = true

      let bodyValue = {
        business_id: this.$route.query.business_id,
        workspace_id: this.$route.query.workspace_id,
        product_id: this.model.id,
      }
      
      await getInventoryListV2(bodyValue)
        .then(({ data }) => {
          this.inventoryList = data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => this.loadingDetail = false)
    },
    fetchCategoriesTiktok: debounce(async function() {
      const { data } = await getChannelCategories({
        business_id: this.businessId,
        channel_code: 'tiktok',
        channel_id: this.$route.params.id,
      })
      this.tiktokCategories = buildTree(data.data.category_list, 'category_id', 'parent_id')
    }, 500),
    async fetchWarehouse() {
      const { data: { data } } = await getWarehouseTiktok({
        business_id: this.businessId,
        channel_id: this.$route.params.id,
      })
      const temp = data?.data?.map(v => {
        const newObj = {
          ...v,
          warehouse_id: v.warehouse_master_id,
          name: v.warehouse_master_name,
        }
        return newObj
      })
      this.warehouses = temp || []
      this.model.inventories = this.model.inventories.map(inventory => {
        return {
          ...inventory,
          id: temp[0].warehouse_master_id,
        }
      })
    },
    async fetchChannels() {
      const { data: { data } } = await getBusinessChannel(this.businessId)
      this.channels = data?.channels || []
    },
    async fetchShippingAndInventoryUnits() {
      const fetchShippingUnitPromise = getShippingUnit({
        product_id: this.productId,
        business_id: this.businessId,
      })

      const fetchInventoryUnitPromise = getUom({
        id: this.productId,
        params: { business_id: this.businessId },
      })

      const [{ data: { data: shippingUnitResponse }}, { data: { data: inventoryUnitResponse }}] = await Promise.all([
        fetchShippingUnitPromise,
        fetchInventoryUnitPromise,
      ])

      const shippingUnits = shippingUnitResponse?.product_units || []
      const inventoryUnits = inventoryUnitResponse?.units || []

      this.model.units = shippingUnits.length
        ? shippingUnits.map(({ unit, ...unitPackage }) => ({
          unit,
          package: unitPackage,
          collapseOpen: false,
          ...(inventoryUnits.find(u => u.unit === unit) || {}),
        }))
        : inventoryUnits.length ? inventoryUnits.map(({ unit, numerator, denumerator }) => {
          // eslint-disable-next-line no-unused-vars
          const { unit: _, ...unitPackage } = shippingUnits.find(u => u.unit === unit) || {}
          return {
            unit,
            numerator,
            denumerator,
            collapseOpen: false,
            package: unitPackage,
          }
        })
        : this.model.units
    },
    async fetchCatalogPrices() {
      const { data: { data } } = await getPriceRange({
        business_id: this.businessId,
        product_id: this.productId,
      })

      this.model.catalogs.items.forEach((item) => {
        const priceData = data.find(p => p.catalog_id === item.id)
        const price = priceData?.price[0]

        if (price) {
          item.price = {
            ...item.price,
            ...price,
          }
        }
      })
    },
    createProductModel() {
      const product = this.product
      const id = product?.product_id
      if (!id) return

      const img = product?.images
      const catalog = product?.catalogs
      const brand = {
        brand_id: product?.brand?.brand_id,
        original_brand_name: product?.brand?.original_brand_name,
      }
      // const optonId = this.$route.query.edit && product.catalogs?.[0].attributes !== null ? [Object.keys(product.catalogs?.[0].attributes)] : [product?.catalogs?.[0]?.attribute_details?.[0]?.data?.id]

      if (this.$route.name !== 'product-channel.product-synchronize-edit') {
        var { channels, ...detail } = product.detail
      }

      this.model.id = product?.product_id
      this.model.channel_product_id = product?.channel_product_id,
      this.model.attributes_details = [],
      this.model.warehouse.pre_order = product?.pre_order?.is_pre_order,
      this.model.warehouse.days_to_ship = product?.pre_order?.days_to_ship,
      this.model.productAttributes = product?.product_attribute || [],
      this.model.status = this.$route.name === 'product-channel.product-synchronize-edit'
        ? product.product_status
        : product.detail.status
      this.model.detail = this.$route.name === 'product-channel.product-synchronize-edit'
        ? {
            ...this.model.detail,
            title: product.title,
            category: {
              id: product.category.category_id,
              name: product.category.name,
            },
            category_id: product.category?.id || null,
            category_name: product.category.name,
            category_tiktok_id: product.category_details?.map(v => {return v.data?.id}),
            long_description: product.long_description,
            imported: product.is_imported,
            condition: product.is_new ? 'NEW' : 'USED',
            category_details: product.category_details,
            physical: true,
            brand_id: brand.brand_id == 0
              ? brand.original_brand_name
              : brand.brand_id,
            brand_name: brand.original_brand_name,
            attribute_list: product?.product_attributes || [],
          }
        :
          {
            ...this.model.detail,
            ...detail,
            category_id: [
              Number(product.detail.category?.id),
            ].filter(Boolean),
            physical: product.detail.physical === null ? true : product.detail.physical,
          },
      this.model.images = img?.map((image, index) => (this.$route.name === 'product-channel.product-synchronize-edit'
        ? {
            ...image,
            id: image.image_id,
            channel_image_id: image?.channel_image_id,
            name: '',
            order: null,
            ref_id: image.channel_image_id,
            slug: null,
            tags: index === 0 ? ["SHOW", "THUMB"] : ["SHOW"],
            url: image?.url_list?.[0],
          }
        : {
            ...image,
            uploading: false,
            deleted: false,
            file: null,
          }
      ))

      let warehouseId
      const catalogItems = catalog.map((item) => {
        let findMaster = []
        let findChannel = []
        let stock_master = 0
        let stock_channel = 0
        let allocated = 0
        if (this.$route.query.edit) {
          for(const prop in item.attributes) {
            const attrIndex = this.model.attributes_details.findIndex(attr => attr.name === prop)
            if(attrIndex < 0) {
              this.model.attributes_details.push({
                name: prop,
                option_list: [{ option: item.attributes[prop] }],
              })
            } else {
              const optIndex = this.model.attributes_details[attrIndex].option_list.findIndex(opt => opt.option === item.attributes[prop])
              if(optIndex < 0) this.model.attributes_details[attrIndex].option_list.push({ option : item.attributes[prop]})
            }
          }
          findMaster = product.stock_masters.find(el => {
            return el.catalog_id === item.master_catalog_id
          })
          findChannel = product.stock_channels.find(el => el.catalog_id === item.master_catalog_id)
          stock_master = findMaster?.availability || 0
          stock_channel = findChannel?.availability || 0
          allocated = findChannel?.allocate_value || 0
        }
        warehouseId = warehouseId || findMaster?.warehouse_id || item.warehouses?.[0]?.warehouse_id || '' 
      
        const temp = this.$route.name === 'product-channel.product-synchronize-edit'
          ? {
              id: item.catalog_id,
              channel_catalog_id: item.channel_catalog_id,
              master_catalog_id: item.master_catalog_id,
              master_product_id: item.master_product_id,
              factory_sku: null,
              images: this.setCatalogImages(item.images),
              option: item.attributes,
              price: {
                currency: item.price?.[0].currency,
                value: item.price?.[0].original_price,
                min_qty: 1,
                price_qty: 1,
                uom: product.shipping_unit.unit,
                price_id: null,
                formula_id: null,
                product_variables: [],
              },
              sku: item.sku,
              status: item.status === 'CONFIRMED' ? true : false,
              status2: item.status,
              title: item.title,
              ext_catalog_id: null,
              attributes: item.attribute_details,
              warehouse_list: item.warehouses || [],
              stock_master,
              stock_channel,
              allocated,
            }
          : {
              ...item,
              price: DEFAULT_MODEL().catalogs.items[0].price,
            }
        return temp
      })

      let attributes = {}
      let options = []
      if (this.$route.name === 'product-channel.product-synchronize-edit') {
        this.model.attributes_details.forEach(attribute => {
          attributes[attribute.name] = attribute.option_list.map(option => option.option)
          options.push(attribute.name)
        })
        this.model.units = [
          {
            unit: product.shipping_unit.unit,
            numerator: 1,
            denumerator: 1,
            package: {
              ...product.shipping_unit,
            },
            collapseOpen: false,
            is_must_insurance: product.must_insurance,
            is_free_return: product.free_return,
            logistic_info: product.logistic_info,
          },
        ]
        this.model.variant_count = +product.variant_count > 1
        this.model.inventories = product.inventory?.map(item => {
          const temp = {
            business_id: item.business_id,
            id: item.warehouse_id,
            is_main: true,
            catalogs: product.catalogs.map(v => {
              const data = {
                id: v.master_catalog_id === item.catalog_id ? v.catalog_id : null,
                availability: v.master_catalog_id === item.catalog_id ? item.availability : 0,
                allocated: v.master_catalog_id === item.catalog_id ? item.allocate_value : 0,
                on_hand: 0,
                pre_order_status: false,
                sellable: 0,
                uom: v.master_catalog_id === item.catalog_id ? item.uom : null,
                warehouse_id: item.warehouse_id,
              }
              return data
            }),
          }
          return temp
        })
      } else {
        attributes = product.attributes
        options = product.options
      }

      this.model.catalogs = {
        attributes,
        options,
        items: catalogItems.length ? catalogItems : [...DEFAULT_MODEL().catalogs.items],
        bundling: {
          type: product.bundling?.bundling_type || null,
          bundles: product.bundling?.bundles || [],
        },
      }
      this.detailModel = {
        title: product.title,
        long_description: product.long_description,
      }
      this.catalogsModel = product.catalogs.map((item) => (this.$route.name === 'product-channel.product-synchronize-edit' ? {
          id: item.catalog_id,
          factory_sku: null,
          images: this.setCatalogImages(item.images),
          option: {
            [item.attribute_details?.[0]?.name]: item.attribute_details?.[0]?.value,
          },
          price: {
            currency: item?.price?.[0].currency,
            value: item?.price?.[0].original_price,
            min_qty: 1,
            price_qty: 1,
            uom: product.base_unit,
            price_id: null,
            formula_id: null,
            product_variables: [],
        },
        sku: item.sku,
        status: item.status === 'CONFIRMED' ? true : false,
        status2: item.status,
        title: item.title,
        ext_catalog_id: null,
        attributes: item.attribute_details,
        } : {
        ...item,
        price: DEFAULT_MODEL().catalogs.items[0].price,
      }))

      this.model.inventories = [{
        is_main: true,
        catalogs: catalogItems,
        id: '',
      }]

      if (this.$route.query.edit) {
        this.itemCod = Number(product.is_cod)
        this.fetchVariantTiktok(this.model.detail.category_tiktok_id)
      }

    },
    async fetchVariantTiktok(values) {
      this.loadingVariantTiktok = true
      if (!(values.length > 0)) {
        this.rawAttributeLists = []
        this.loadingVariantTiktok = false
        return
      }
      await getChannelAttributes({
        business_id: this.$route.query.business_id,
        channel_code: 'tiktok',
        channel_id: this.$route.params.id,
        channel_type: 'attributes',
        params: {
          shop_id: 'c9ee7774-8494-45e3-8398-bf3e528be375',
          category_id: values[values.length - 1],
        },
      })
      .then(({ data: response }) => {
        let arrMandatory = []
        let arrNotMandatory = []
        response?.data?.attributes?.forEach(attribute => {
          if(attribute.attribute_type == 3) {
            if(attribute.input_type.is_mandatory) {
              arrMandatory.push(attribute)
            } else {
              arrNotMandatory.push(attribute)
            }
          }
        })
        this.attribute_lists = []
        this.rawAttributeLists = arrMandatory.concat(arrNotMandatory)
        this.loadingVariantTiktok = false
      })
      .catch((err) => {
        console.error(err)
        this.loadingVariantTiktok = false
      })
    },
    setCatalogImages(images) {
      const findImage = this.model.images?.find(item => item.url === images?.[0]?.url_list?.[0])
      
      const result = images.map(item => {
        return {
          refId: (findImage?.ref_id ? findImage.ref_id : item?.channel_image_id) || 0,
          imagePath: item?.url_list?.[0],
          thumbnailUrl: this.$route.query.edit && this.$route.query.id
            ? item?.thumb_url_list?.[0]
            : findImage?.thumb_url_list?.[0],
          id: item?.channel_image_id || findImage?.id,
        }
      })
      return result
    },
    async saveDetail(ctx, showMessage = true) {
      if (this.saving.detail) return
      this.saving.detail = true

      this.saveOrUpdateDetailProduct(showMessage)

      this.saving.detail = false
    },
    async saveOrUpdateDetailProduct(showMessage = true) {
      // eslint-disable-next-line no-unused-vars
      const { detail, ...model } = this.model
      try {
        const { data: { data } } = await crudProduct({
          business_id: this.business.business_id,
          id: model.id || undefined,
          ...this.model.detail,
          is_new: this.isNewProduct,
        })

        this.model.id = data.id
        // this.model.status = data.status
        if (showMessage) {
          this.$message.success(this.model.id ? 'Perubahan berhasil disimpan' : 'Draft produk berhasil disimpan', 5)
        }
        // this.draftPayload = {
        //   ...this.draftPayload,
        //   detail: {
        //       title: this.model.detail.title,
        //       long_description: this.model.detail.long_description,
        //       category: {
        //           id: this.model.detail.category_tiktok_id[2] || this.model.detail.category_tiktok_id[1],
        //       },
        //       images: [],
        //   },
        // }
        // if (!this.productIdDraft) { 
        //   this.createDraft()
        // } else {
        //   this.updateDraft()
        // }
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    async saveImages(images, showMessage = true) {
      try {
        const product_id = this.model.id
        const undeletedImages = images.filter(image => !image.deleted)
        // Hit service if product_id available
        if (product_id && !this.$route.query.edit) {
          await saveProductImages({
            product_id,
            business_id: this.business.business_id,
            images: undeletedImages.map(({ id, name, url, ref_id, order, tags }) => ({
              id,
              name,
              url,
              ref_id,
              order,
              tags,
            })),
            images_delete: images.filter(image => image.deleted).map(({ ref_id }) => ref_id),
            status: this.model.status,
          })
        }

        this.model.images = undeletedImages
        if (showMessage && product_id && !this.$route.query.edit) this.$message.success('Gambar produk berhasil disimpan', 5)
      } catch (err) {
        this.$message.error('Oops, gambar produk gagal disimpan', 5)
        throw err
      }
    },
    async saveCatalogues(ctx, showMessage = true) {
      if (!this.productId) {
        // we can save the pending state, once product id is available we can save the catalogues
        return
      }

      try {
        const isCatalogIdFilled = this.model.catalogs.items.every((item) => !!item.id || (!item.id && !item.status)) 
        const { units, catalogs: { bundling } } = this.model
        const saveCatalogItems = async () => {
          const { data: { data: { catalogs } } } = await updateCatalogsProduct({
            attributes: this.model.catalogs.attributes,
            options: this.model.catalogs.options,
            business_id: this.business.business_id,
            product_id: this.model.id,
            catalogs: this.model.catalogs.items.map(item => ({
              ...item,
              price: undefined,
              title: item.title || [
                this.model.detail.title,
                (Object.values(item.option || {}) || []).join(', '),
              ].filter(Boolean).join(' - '),
            })),
            bundling: bundling && bundling.type && bundling.bundles.length ? {
              bundling_type: bundling.type,
              bundles: bundling.bundles.map(item => ({
                catalog_id: item.catalog_id,
                quantity: item.quantity,
                uom: item.uom || units[0].unit || null,
              })),
              product_id: this.productId,
            } : null,
            // bundling: {
            //   bundles: [],
            //   type: 'SOFT',
            //   product_id: this.model.id,
            // },
          })

          this.model.catalogs.items.forEach((item) => {
            const catalog = catalogs 
              // ? catalogs[index] 
              ? catalogs.find(c => item.id ? c.id === item.id : c.sku === item.sku)
              : null

            if (catalog) {
              item.id = catalog.id
            }
          })
        }

        // const saveCatalogPromise = saveCatalogItems()
        // if catalog id is not filled, then wait to save catalog items first, otherwise we can call savePrice parallelly
        // if (!isCatalogIdFilled) await saveCatalogPromise

        if (!isCatalogIdFilled) await saveCatalogItems()

        this.$nextTick(async () => await this.savePrice())
      } catch (err) {
        if (showMessage) this.$message.error('Oops, catalog produk gagal disimpan', 5)
        throw err
      }
    },
    async savePrice() {
      const { data: response } = await saveProductPrice({
        business_id: this.business.business_id,
        product_id: this.model.id,
        catalog_price: this.model.catalogs.items
          .filter(i => i.id)
          .map(item => ({
            catalog_id: item.id,
            price: [item.price],
          })),
      })

      const catalogPrices = response?.data?.catalog_price || []

      catalogPrices.forEach(catalogPrice => {
        const { price, catalog_id } = catalogPrice
        const catalog = this.model.catalogs.items.find(c => c.id === catalog_id)

        const mainPrice = price[0]

        catalog.price.price_id = mainPrice.price_id
      })
    },
    async saveWeightAndShipping(ctx, showMessage = true) {
      try {
        await Promise.all([
          this.saveOrUpdateDetailProduct(showMessage),
          saveProductShipping({
            business_id: this.business.business_id,
            product_id: this.model.id,
            product_units: this.model.units.map((unit) => ({ unit: unit.unit, ...unit.package })),
            product_units_delete: [],
            status: this.model.status,
          }),
          saveProductInventory({
            business_id: this.business.business_id,
            product_id: this.model.id,
            units: this.model.units.map(({ unit, denumerator, numerator }) => ({ unit, numerator, denumerator })),
            units_delete: [],
          }),
        ])
      } catch (err) {
        if (showMessage) this.$message.error('Oops, weight dan shipping produk gagal disimpan', 5)
        throw err
      }
    },
    async saveWarehousesAndInventories(ctx, showMessage = true, warehouses) {
      try {
        await addWarehouse({
          business_id: this.businessId,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            warehouses: warehouses || this.model.inventories,
          },
        })
      } catch (err) {
        if (showMessage) this.$message.error('Oops, inventory produk gagal disimpan', 5)
        throw err
      }
    },
    async saveChannels(showMessage = true) {
      const channels = this.model.channels.filter((c) => !c.deleted).map(({id}) => id)
      const deletedChannels = this.model.channels.filter((c) => c.deleted).map(({id}) => id)

      if (!channels.length && !deletedChannels.length) return 

      try { 
        await addProductChannel({
          business_id: this.businessId,
          product_id: this.model.id,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            channels,
            channels_delete: deletedChannels,
          },
        })
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    generateUUID() {
      let d = Date.now();
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
      });
      return uuid.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5');
    },
    async createAllocation() {
      if (this.$route.query.edit) this.submitting = true
      try { 
        const id = this.$route.query.edit ? this.productIdDraft : this.model.id
        const response = await addAllocationStock({
          request_id: this.generateUUID(),
          business_id : this.businessId,    
          product_id : this.$route.query.edit ? this.model.catalogs.items[0].master_product_id : id,
          base_uom : this.model.units?.[0]?.unit,
          datas : this.model.inventories.map(v => {
            const data = {
              warehouse_id: this.warehouses.filter(item => {return item.warehouse_master_id === v.id})?.[0]?.warehouse_master_id,
              items: v.catalogs.map(item => {
                const temp = {
                  id: '',
                  catalog_id: this.$route.query.edit ? item.master_catalog_id : item.id,
                  channel_id : this.$route.params.id,
                  channel_code : this.$route.query.channel_code,
                  allocate_value : item.allocated,
                  availability: Number(((item.stock_master * item.allocated)/100)?.toFixed(0)) || 0,
                  // availability: item.availability,
                }
                return temp
              }),
            }
            return data
          }),
        })
        if (response.data?.status === 200) {
          this.$message.success(response.data.message, 5)
        }
        if (this.$route.query.edit) this.submitting = false
      } catch (err) {
        if (err) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        this.submitting = false
        throw err
      }
    },
    getFirstError() {
      const errors = this.$refs.validationObserver.errors
      for (const key in errors) {
        if (errors[key].length) return errors[key][0]
      }
      return null
    },
    async saveEdit(payload) {
      this.submitting = true
      try {
        const response = await updateProductChannel({
          business_id: this.businessId,
          channel_code: 'tiktok',
          channel_id: this.$route.params.id,
          user_id: this.$store.state.user.id,
          product_id: this.model.id,
          data: payload,
        })
        if (response.data.message?.toLowerCase() === 'success') {
          this.request_id_temp = response.data.data.request_id
          this.getEventProcess(response.data.data.request_id)
        }
      } catch(e) {
        console.error({e})
        this.submitting = false
        const { message } = e?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
      }
    },
    async saveProducts() {
      this.submitting = true
      const valid = await this.$refs.validationObserver.validate()

      if (!valid) {
        this.submitting = false
        const firstErrorElement = document.querySelector('.ant-form-explain')
        if (firstErrorElement) {
          window.scrollTo({
            behavior: 'smooth',
            top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
          })
        }
        return this.$message.error("Beberapa bidang tidak valid, silahkan isi terlebih dahulu") // Beberapa bidang masih kosong
      }

      // await this.createAllocation()

      try {
        const images = this.model.images.map(image => ({ id: image.ref_id }))
        let product_attributes = []
        this.attribute_lists.forEach(attr => {
          product_attributes.push({
            attribute_id: attr.attribute_id,
            attribute_values: attr.attribute_value_list.map(val => ({
              value_id: val.value_id,
              value_name: val.original_value_name,
            })),
          })
        })

        let skus = []
        this.model.catalogs.items.map(catalog => {
          let i = 0
          let sales_attributes = []
          for(const variant in (catalog.option || {})) {
            let findImage
            if(i == 0) findImage = this.model.images.find(image => image.ref_id === catalog.images[0].refId)
            sales_attributes.push({
              attribute_name: variant,
              custom_value: catalog.option[variant],
              sku_img: i == 0
                ? { id: findImage.ref_id }
                : undefined,
            })
            i++
          }

          skus.push({
            original_price: catalog.price.value,
            seller_sku: catalog.sku,
            sales_attributes: sales_attributes.length
              ? sales_attributes
              : undefined,
            stock_infos: [{
              warehouse_id: this.warehouses[0].warehouse_channel_id,
              available_stock: 0,
            }],
          })
        })
        
        const weight = ['g', 'gr'].includes(this.model.units?.[0]?.package?.weight_unit?.toLowerCase())
          ? this.model.units?.[0]?.package?.weight / 1000
          : this.model.units?.[0]?.package?.weight

        const data = {
          user_id: this.$store.state.user.id,
          request_body: {
            product_name: this.model.detail.title,
            description: this.model.detail.long_description.replace( /(<([^>]+)>)/ig, ''),
            category_id: this.model.detail.category_tiktok_id[3]
              || this.model.detail.category_tiktok_id[2]
              || this.model.detail.category_tiktok_id[1]
              || this.model.detail.category_tiktok_id[0],
            images,
            package_height: this.model.units?.[0]?.package?.height,
            package_length: this.model.units?.[0]?.package?.length,
            package_weight: weight,
            package_width: this.model.units?.[0]?.package?.width,
            size_chart: {
              img_id: images[0].id,
            },
            skus,
            product_attributes: product_attributes.length
              ? product_attributes
              : undefined,
            is_cod_open: !!this.itemCod,
          },
        }
        const response = await addChannelProduct({
          method: this.$route.name !== 'product-channel.product-synchronize-edit' ? 'post' : 'put',
          business_id: this.businessId,
          channel_code: 'tiktok',
          channel_id: this.$route.params.id,
          user_id: this.$store.state.user.id,
          product_id: '',
          data,
        })
        if (response.data.message === 'Success') {
          this.request_id_temp = response.data.data.request_id
          this.getEventProcess(response.data.data.request_id)
        }
      } catch (err) {
        this.submitting = false
        console.error({err})
        const { message } = err?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        throw err
      }
    },
    separateObject(obj) {
      const res = [];
      const keys = Object.keys(obj);
      keys.forEach(key => {
          res.push({
            [key]: obj[key],
          });
      });
      return res;
    },
    beforeRouteLeave(to, from, next) {
      const isDraftProduct = this.model.status === 'DRAFT'
      const dirty = this.$refs.validationObserver.flags.dirty

      if (!this.permission.includes('WRITE')) return next()

      if ((!dirty && !(this.model.id && isDraftProduct) && !this.productIdDraft)) return next()

      if (this.request_id_temp) return next()

      this.$confirm({
        title: 'Data Belum Tersimpan',
        icon: () => null,
        content: isDraftProduct 
          ? 'Apakah Anda yakin akan menutup halaman ini, Data yang sudah diisi tidak akan disimpan' 
          : 'Perubahan akan hilang saat anda keluar dari halaman ini',
        okText: isDraftProduct ? 'Ya, Keluar dari halaman ini' : 'Ya, Keluar dari halaman ini',
        class: 'leaving-product-confirm',
        onOk: () => {
          // this.deleteDraftProduct()
          next()
        },
      })

      next(false)
    },
    async deleteDraftProduct() {
      const isDraft = this.model.status === 'DRAFT'
      if (isDraft && this.model.id || this.productIdDraft) {
        await deleteProduct(this.model.id)
        await deleteChannelDraft({
          channel_code: this.$route.query.channel_code,
          channel_id: this.$route.params.id, 
          user_id: this.$store.state.user.id, 
          product_id: this.productIdDraft, 
          business_id: this.businessId,
        })
      }
    },
    onCancel() {
      const redirectUrl = this.$route.query.redirect_url

      if (redirectUrl) {
        return this.$router.push(redirectUrl)
      }

      return this.$router.push({
        name: 'product-channel.product-list',
        query: {
          workspace_id: this.$route.query.workspace_id,
          business_id: this.$route.query.business_id,
        },
      })
    },
    onChangeChannelAttribute(value) {
      const isExist = this.attribute_lists.findIndex((obj) => obj.attribute_id === value.attribute_id)
      if (isExist >= 0) {
        this.attribute_lists[isExist] = value
      } else {
        this.attribute_lists.push(value)
      }
    },
    changeCodProduct(value) {
      this.itemCod = value
    },
  },
})
</script>

<style scoped lang="scss">
.footer {
  bottom: 0;
  background-color: #F8F9FA;
  // border-top: 1px solid #efefef;
  z-index: 3;
}

ol {
  list-style: none;
  top: 130px;

  > li {
    position: relative;
    padding: .5rem 2rem;

    a {
      color: #999;
    }

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 20rem;
      background: #999;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      content: '';
      width: 1px;
      background: #999;
      top: 50%;
      left: 4.5px;
      transform: translateY(-50%);
      height: 100%;
      z-index: -1;
    }

    &:first-child::after {
      height: 50%;
      transform: none;
    }

    &:last-child::after {
      height: 50%;
      transform: none;
      top: 0;
    }

    &.active {
      a {
        color: var(--color-black);
        font-weight: 500;
      }

      &::before {
        background: var(--color-accent);
      }
    }
  }
}
</style>

<style lang="scss">
[data-kit-theme="default"] {
  .leaving-product-confirm {
    text-align: center;

    .ant-modal-confirm-title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .ant-modal-confirm-content {
      color: #999;
    }

    .ant-modal-confirm-btns {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      button {
        width: 100%;
        margin: 15px 0 0 0 !important;
        height: 45px;
        border: none;
      }
    }
  }
}
</style>
