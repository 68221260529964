<template>
  <a-modal
    :width="950"
    :footer="null"
    :mask-closable="false"
    :dialog-style="{ top: '20px' }"
    :visible="visible"
    :title="$t('store_list_page.product_catalog_mapping')"
    @cancel="onCancel"
  >
    <h6>
      {{ $t('store_list_page.channel_products_catalog') }}
    </h6>
    <div class="d-flex flex-wrap align-items-center mt-3">
      <div class="product-img mr-3">
        <img
          width="45"
          height="45"
          :src="getImageSrc(data)"
          :alt="data.title"
          class="of-contain"
        />
      </div>
      <div>
        <h6 class="mb-1">
          {{ data.title }}
        </h6>
        {{ `${$t('store_list_page.variant_count')}: ${data.variant_count}` }}
      </div>
    </div>

    <!-- <a-collapse v-model="activeKey" :bordered="false" class="w-100">
      <a-collapse-panel key="1" :show-arrow="false" class="p-0">
        <template slot="header">
          <div @click="onStop">
            <div class="d-flex flex-wrap align-items-center">
              <div class="product-img mr-3">
                <img
                  width="45"
                  height="45"
                  :src="`${data.showimg_url || $defaultImage + '?'}tr=w-45,h-45,cm-pad_resize,bg-FFF`"
                  :alt="data.title"
                  class="product-image"
                >
              </div>
              <div class="product-desc">
                <h6>{{ data.title }}</h6>
                <p class="m-0">
                  {{ `${$t('store_list_page.variant_count')}: ${data.variant_count}` }}
                </p>
              </div>
            </div>
          </div>
        </template>
        <template slot="extra">
          <span class="mr-2 text-blue-2 cpoin" @click="onStop">Ubah</span>
          <span class="text-gray-4">|</span> 
          <span class="text-blue-2 cpoin">
            {{ $t('store_list_page.view_detail') }} 
            <a-icon type="right" :rotate="activeKey.length ? 90 : 0" />
          </span>
        </template>
        <div class="collapse-overflow">
          <div
            v-for="catalog in data.catalogs"
            :key="catalog.id"
            class="row m-0"
          >
            <div class="col-md-6 py-1 pl-0 border-b border-gray-2">
              <span class="text-gray-7">{{ catalog.title }}</span>
            </div>
            <div class="col-md-6 py-1 border-b border-gray-2">
              <span class="text-gray-7">SKU: {{ catalog.sku }}</span>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse> -->

    <div class="w-100 mt-3">
      <a-table
        :data-source="data.catalogs || []"
        :row-key="record => record.catalog_id"
        :columns="columsChannelProductsCatalog"
        :loading="isLoading || loadingMaster"
        :pagination="false"
        :scroll="scroll"
        size="small"
      >
        <template slot="sku" slot-scope="text">
          {{ text || '-' }}
        </template>
        <template slot="catalog_master" slot-scope="record">
          <span v-if="record.status === 'CONFIRMED'">
            {{ record.catalog_title || '-' }}
          </span>
          <span v-else class="text-muted">(Belum termapping)</span>
        </template>
        <template slot="sku_master" slot-scope="record">
          <span v-if="record.status === 'CONFIRMED'">
            {{ record.catalog_sku || '-' }}
          </span>
          <span v-else class="text-muted">(Belum termapping)</span>
        </template>
        <template slot="action" slot-scope="record">
          <a-button
            size="small"
            type="primary"
            :ghost="detailCatalogProductChannel.catalog_id !== record.catalog_id"
            :disabled="detailCatalogProductChannel.catalog_id === record.catalog_id"
            @click="clickCatalogProductChannelItem(record)"
          >
            <!-- <a-icon v-if="detailCatalogProductChannel.catalog_id === record.catalog_id" type="check" class="mr-n1" /> -->
            {{ record.status === 'CONFIRMED' ? 'Hapus Mapping' : 'Mapping Produk' }}
          </a-button>
          <!-- <a-dropdown placement="bottomRight">
            <a @click.prevent="">
              <a-icon type="more" />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item :key="record.id" class="py-2">
                  {{ $t('store_list_page.select_product') }}
                </a-menu-item>
                <a-menu-item key="2" class="py-2">
                  {{ $t('store_list_page.change_product') }}
                </a-menu-item>
                <a-menu-item key="3" class="py-2">
                  {{ $t('store_list_page.deactivate_product') }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown> -->
        </template>
      </a-table>
    </div>

    <template v-if="detailCatalogProductChannel.status === 'UNCONFIRMED'">
      <hr class="my-4" />
      <h6>
        Katalog Produk Master
      </h6>

      <div class="d-flex w-100 mt-2">
        <div class="col-md-7 pl-0">
          <a-input-search
            v-model.trim="params.q"
            :placeholder="$t('store_list_page.search_products')"
            :loading="isLoading"
            :disabled="isLoading"
            allow-clear
            @search="onSearch"
          >
            <a-button slot="enterButton" type="primary">
              <a-icon type="search" />
            </a-button>
          </a-input-search>
        </div>
        <div class="col-md-5 pr-0">
          <a-button
            type="primary"
            icon="plus"
            class="w-100"
            :disabled="isLoading"
            @click="onAddProduct"
          >
            {{ $t('product.addProduct') }}
          </a-button>
        </div>
      </div>

      <a-alert
        :message="$t('store_list_page.modal_info')"
        type="info"
        class="alert-info mt-3"
        show-icon
      />
      <!-- @NOTE: Table Katalog Produk Master here  -->
      <div class="w-100 mt-3">
        <a-table
          :data-source="catalogProductMaster"
          :row-key="record => record.id"
          :columns="columsProductsCatalogMaster"
          :loading="isLoading || !!detailCatalogProductMaster"
          :pagination="false"
          :scroll="scroll"
          size="small"
        >
          <template slot="sku" slot-scope="text">
            {{ text || '-' }}
          </template>
          <template slot="catalog_channel" slot-scope="record">
            <!-- v-if="record.status === 'CONFIRMED'" -->
            <span v-if="record.statusMapping === 'CONFIRMED'">
              {{ record.catalogChannelName || '-' }}
            </span>
            <span v-else class="text-muted">(Belum termapping)</span>
          </template>
          <template slot="action" slot-scope="record">
            <a-button
              ghost
              size="small"
              type="primary"
              :loading="detailCatalogProductMaster === record.id"
              @click="clickCatalogProductMasterItem(record)"
            >
              Pilih Produk
            </a-button>
          </template>
        </a-table>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { getProductCatalog } from '@/api/product'
import { getCatalogByMaster, mappingProductChannel } from '@/api/channels/index'
import { columsChannelProductsCatalog, columsProductsCatalogMaster } from '@/data/columns' // columnsMaster

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loadingMaster: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    channel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // columns: columnsMaster,
      columsChannelProductsCatalog,
      columsProductsCatalogMaster,
      activeKey: [],
      scroll: {
        y: 245,
      },
      params: {
        q: '',
        page: 1,
        limit: 20,
      },
      isLoading: false,
      products: [],
      catalogProductMaster: [],
      detailCatalogProductChannel: {},
      detailCatalogProductMaster: false,
      controllerCatalog: null,
      controllerCatalogMaster: null,
    }
  },
  // computed: {
  //   isLoading() {
  //     return this.$store.state.products.loading
  //   },
  //   products() {
  //     return this.$store.state.products.products
  //   },
  // },
  watch: {
    visible(newValue) {
      // if (newValue) {
      //   this.onSearch()
      // }
      if (!newValue) {
        this.catalogProductMaster = []
        this.detailCatalogProductChannel = {}
      }
    },
  },
  beforeDestroy() {
    this.abortRequest() // cancel the request
  },
  methods: {
    abortRequest() {
      // cancel the request
      if (this.controllerCatalogMaster) {
        this.controllerCatalogMaster.abort()
        this.controllerCatalogMaster = null
      }
      if (this.controllerCatalog) {
        this.controllerCatalog.abort()
        this.controllerCatalog = null
      }
    },
    async getProductMaster() {
      await this.fetchProductCatalogs()
      const { channel_code: channelCode } = this.$route.query
      // const ids = (this.data?.catalogs || []).filter(f => f.master_catalog_id) // .map(v => v.master_catalog_id)
      const ids = (this.catalogProductMaster || []).filter(f => f.id)
      if (ids.length && !['tiktok', 'tiktok_id'].includes(channelCode)) {
        const { query, params } = this.$route
        this.controllerCatalogMaster = new AbortController()
        await getCatalogByMaster({
          signal: this.controllerCatalogMaster.signal,
          business_id: query.business_id,
          channel_code: this.channel,
          channel_id: params.id,
          params: { master_catalog_id: ids.map(v => v.id).join(',') },
        })
        .then(res => {
          const data = res?.data?.data?.catalogs || []
          if (data.length) {
            this.catalogProductMaster = this.catalogProductMaster.map(item => {
              const catalogItem = data.find(f => f.master_product_id === item.product_id)
              if (catalogItem) { //  && catalog.master_product_id === item.product_id
                return {
                  ...item,
                  catalogChannelName: catalogItem.title,
                  statusMapping: catalogItem.status,
                }
              }
              return item
            })
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.controllerCatalogMaster = null
        })
      }
    },
    async clickCatalogProductChannelItem(catalog) {
      this.detailCatalogProductChannel = catalog
      if (catalog.status === 'CONFIRMED') {
        this.$modal.confirm({
          title: 'Yakin ingin menghapus mapping produk ini?',
          centered: true,
          keyboard: false,
          okText: this.$t('utils.delete'),
          cancelText: this.$t('utils.cancel'),
          onOk: async () => {
            return await this.mappingProduct({ product_id: catalog.master_catalog_id, id: catalog.master_catalog_id })
          },

          onCancel: () => {
            this.detailCatalogProductChannel = {}
          },
        })
        this.catalogProductMaster = []
        return
      }
      
      this.getProductMaster()
    },
    clickCatalogProductMasterItem(catalog) {
      this.mappingProduct(catalog)
    },
    async mappingProduct(catalog) {
      const { catalog_id, status } = this.detailCatalogProductChannel // , master_product_id, master_catalog_id
      const isConfirmed = status === 'CONFIRMED'
      const catalogs = [
        {
          catalog_id,
          master_product_id: catalog.product_id,
          master_catalog_id: catalog.id,
          master_sku: this.channel.includes('blibli')
            ? catalog.sku
            : undefined,
          master_catalog_title: this.channel.includes('blibli')
            ? catalog.title
            : undefined,
        },
      ]
      
      if(this.channel.includes('tiktok')) catalogs[0].master_sku = catalog.sku

      this.detailCatalogProductMaster = catalog.id
      await mappingProductChannel({
        business_id: this.$route.query.business_id,
        channel_code: this.channel.includes('blibli')
          ? 'blibli'
          : this.channel,
        channel_id: this.$route.params.id,
        data: {
          // shop_id: 'c9ee7774-8494-45e3-8398-bf3e528be375', // @NOTE: Dev static shop_id
          channel_id: this.channel.includes('blibli')
            ? +this.$route.params.id
            : undefined,
          product_id: this.data.product_id,
          catalogs,
          // @NOTE: Check status
          status: isConfirmed
            ? 'UNCONFIRMED'
            : 'CONFIRMED',
        },
      })
      .then((res) => {
        const { status } = res
        if (this.$route.query?.channel_code === 'blibli_id' || (res?.data?.message || '').toLowerCase() === 'success') {
          this.$emit('setLoadingMaster', true)
          this.$emit('successMapping', this.data.title, this.data.product_id) // Reload product list & this.data props
          !isConfirmed && this.getProductMaster() // Reload product master list
          this.$notification.success({ message: isConfirmed ? 'Mapping produk berhasil dihapus' : 'Produk berhasil dimapping' })
        } else if (['tiktok', 'tiktok_id'].includes(this.$route.query?.channel_code) && status === 200) {
          this.$emit('setLoadingMaster', true)
          this.$emit('successMapping', this.data.title, this.data.product_id) // Reload product list & this.data props
          !isConfirmed && this.getProductMaster() // Reload product master list
          this.$notification.success({ message: isConfirmed ? 'Mapping produk berhasil dihapus' : 'Produk berhasil dimapping' })
        }
      })
      .catch(() => {
        this.$notification.error({ message: 'Gagal Mapping Produk' })
      })
      
      this.detailCatalogProductMaster = false
      this.detailCatalogProductChannel = {}
    },
    async onSearch() {
      await this.getProductMaster()
    },
    async fetchProductCatalogs() {
      const bodyValue = {
        type: 'catalogs',
        params: {
          ...this.params,
          business_id: this.$route.query.business_id,
          q: !this.params.q ? undefined : this.params.q,
        },
      }
      this.isLoading = true
      await getProductCatalog(bodyValue)
      .then(res => {
        const data = res?.data?.data
        this.catalogProductMaster = Array.isArray(data) ? data : []
      })
      .catch(() => {
        this.catalogProductMaster = []
        this.detailCatalogProductChannel = {}
      })

      this.isLoading = false
    },
    onAddProduct() {
      this.$emit('add', this.data.id)
    },
    onStop(e) {
      e.stopPropagation()
    },
    onCancel() {
      this.abortRequest() // cancel the request
      this.activeKey = []
      this.params.q = ''
      this.$emit('cancel')
    },
    getImageSrc(data) {
      if(data.showimg_url || data?.images?.[0]?.url_list?.[0] || data?.images?.[0]?.thumb_url_list?.[0]) {
        return `${data.showimg_url || data?.images?.[0]?.url_list?.[0] || data?.images?.[0]?.thumb_url_list?.[0]}`
      }
      return `${this.$defaultImage + '?'}tr=w-45,h-45,cm-pad_resize,bg-FFF`
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-12 {
  font-size: 12px;
}
.cursor-auto {
  cursor: auto;
}

.collapse-overflow {
  max-height: 100px;
  overflow-y: scroll;

  .row:last-child > .border-b {
    border-bottom: 0;
  }
}
.border-b {
  border-bottom: 1px solid;
}
.title {
  align-self: flex-start;

  h5 {
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.ant-table-row {
  background-color: #fff !important;
}
.alert-info {
  width: 100%;
  font-size: 12px !important;
  background-color: #EBF8FF !important;
  border-color: #2196F3 !important;

  span {
    color: #2196F3 !important;
  }
}
.ant-collapse {
  > .ant-collapse-item.p-0 {
    > .ant-collapse-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      cursor: auto;
      padding: 0 !important;

      h6 {
        font-size: 14px;
        font-weight: bold;
      }

      > div {
        flex: 2 0;
        padding: 12px;
      }

      > .ant-collapse-extra {
        flex: 1 1;
        margin-left: auto;
        text-align: right;
      }
    }
  }
}
</style>
