import apiClient from '@/services/axios'

export const getSales = ({ signal, params }) => {
  return apiClient({
    signal,
    method: 'get',
    url: '/sales/api/v1/sales-orders',
    params,
  })
}

export const downloadManualOrder = (business_id) => {
  return apiClient({
    method: 'get',
    url: '/sales-manual/download',
    headers: {
      'Business-Id': business_id,
    },
    responseType: 'blob',
  })
}

export const createManualOrder = async ({ business_id, channel_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/sales-manual/${channel_id}/order`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const verifiedBulkOrder = async ({ business_id, channel_id, file }) => {
  const form = new FormData()
  form.append('file', file)

  return apiClient({
    method: 'post',
    url: '/sales-manual/verified',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Business-Id': business_id,
    },
    data: form,
  })
}

export const bulkManualOrder = async ({ business_id, channel_id, file }) => {
  const form = new FormData()
  form.append('file', file)

  return apiClient({
    method: 'post',
    url: `/sales-manual/${channel_id}/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Business-Id': business_id,
    },
    data: form,
  })
}

export const getStoreId = ({ business_id }) => {
  return apiClient({
    method: 'get',
    url: `/logapi/vendors/business/${business_id}`,
  })
}