<template>
  <div>
    <h1 class="h3 mb-4">
      {{ titleDetailStockMovement }}
    </h1>

    <a-tabs
      v-model="params.status"
      size="large"
      class="pca-tab-store mt-4"
      @change="onChangeStatus"
    >
      <a-tab-pane
        v-for="tab in tabs"
        :key="tab.key"
        :tab="`${tab.title}`"
        :disabled="isLoading"
        style="background-color: var(--white);"
      >
        <div class="p-3">
          <div class="d-flex mb-4 justify-content-between" style="gap: 10px;">
            <div class="d-flex" style="gap: 10px;">
              <a-select 
                v-model="params.movement_type" 
                allow-clear
                :max-tag-count="1"
                style="min-width: 200px; max-width: 100%"
                show-search
                mode="multiple"
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="Pilih Movement Type"
                :disabled="isLoading"
                @select="onSelectStateMovement"
                @deselect="onDeselectStateMovement"
              >
                <a-select-option v-for="(item, index) in movementStatusList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-input
                v-model="params.reff_text"
                style="width: 200px;"
                allow-clear
                placeholder="Masukan Reff"
              />
              <a-range-picker
                :ranges="ranges"
                :format="dateFormat"
                :value="[startDate, endDate]"
                :placeholder="[
                  $t('dashboardContent.start_date'),
                  $t('dashboardContent.end_date'),
                ]"
                :disabled-date="disabledDate"
                :disabled="isLoading"
                allow-clear
                @change="onChangeDate"
              >
                <DateIcon slot="suffixIcon" style="color: #999999" />
              </a-range-picker>
              <a-button
                type="primary"
                @click.prevent="() => filterDetailStockMovement()"
              >
                <a-icon type="search" />
                {{ $t('utils.filter') }}
              </a-button>
            </div>
            <a-button
              type="primary"
              @click.prevent="showModalExportExcel = true"
            >
              {{ $t('warehouse.export_data') }}
            </a-button>
          </div>
          <a-table
            class="inventory-list"
            :data-source="listStockMovement"
            :row-key="record => record.id"
            :columns="columns"
            :loading="isLoading"
            :pagination="false"
            :scroll="scroll"
            @change="(val, filter, sorter) => onChangeTable(val, filter, sorter)"
          >
            <template slot="title_availability">
              <div class="d-flex align-items-center">
                <div class="mr-1">
                  Dapat Dijual
                </div>
                <a-tooltip title="Stok yang dapat dijual dan muncul di channel penjualan">
                  <a-icon type="info-circle" style="font-size: 14px;" />
                </a-tooltip>
              </div>
            </template>
            <template slot="title_on_order">
              <div class="d-flex align-items-center">
                <div class="mr-1">
                  {{ $t('productAvailability.inorder') }}
                </div>
                <a-tooltip title="Stok pesanan dalam proses, sebelum dilakukan good issued">
                  <a-icon type="info-circle" style="font-size: 14px;" />
                </a-tooltip>
              </div>
            </template>
            <template slot="title_on_hand">
              <div class="d-flex align-items-center">
                <div class="mr-1">
                  {{ $t('productAvailability.warehouseStock') }}
                </div>
                <a-tooltip placement="topRight" title="Stok yang berstatus available (termasuk stok yang dapat dijual maupun on order)">
                  <a-icon type="info-circle" style="font-size: 14px;" />
                </a-tooltip>
              </div>
            </template>
            <template slot="movement_date" slot-scope="text">
              {{ $moment(text).format('DD MMM YYYY, HH:mm') }}
            </template>
            <template slot="movement_type" slot-scope="text, record">
              <div>{{ getMovementTypeLabel(text) }}</div>
              <div v-if="record.reff_text" style="color: var(--gray)">
                {{ record.reff_text }}
              </div>
            </template>
            <template slot="movement_quantity" slot-scope="text">
              {{ Math.abs(text) }}
            </template>
            <template slot="availability" slot-scope="text, record">
              {{ text }}
              <a-icon v-if="movementStatusMapper[record.movement_type]" :type="movementStatusMapper[record.movement_type].availability" :style="{ color: checkColorArrow(movementStatusMapper[record.movement_type].availability) }" />
            </template>
            <template slot="on_order" slot-scope="text, record">
              {{ text }}
              <a-icon v-if="movementStatusMapper[record.movement_type]" :type="movementStatusMapper[record.movement_type].on_order" :style="{ color: checkColorArrow(movementStatusMapper[record.movement_type].on_order) }" />
            </template>
            <template slot="on_hand" slot-scope="text, record">
              {{ text === null ? 0 : text }}
              <a-icon v-if="movementStatusMapper[record.movement_type]" :type="movementStatusMapper[record.movement_type].on_hand" :style="{ color: checkColorArrow(movementStatusMapper[record.movement_type].on_hand) }" />
            </template>
          </a-table>
          <div class="mt-4 text-right">
            <a-pagination
              v-model="params.page"
              :page-size="params.size"
              :page-size-options="sizeOptions"
              :total="totalRow"
              :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
              :disabled="isLoading"
              show-size-changer
              @change="onPageChange"
              @showSizeChange="onShowSizeChange"
            />
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <ModalExportExcel
      :movement-status="movementStatusList"
      :catalog-id="detailCatalog.id"
      :visible="showModalExportExcel"
      :on-close="onCloseModalExportExcel"
      :is-loading="loadingExport"
      @handleSubmitModal="exportDataTable"
    />
  </div>
</template>

<script>
import { columnDetailStockMovement } from '@/data/columns'
import { getDetailStockMovementBySKU, getCatalogListBySKU } from '@/api/inventory'
import { getWarehouseById } from '@/api/warehouse'
import ModalExportExcel from '@/views/inventory/inventory-v2/detail-stock-movement/ModalExportExcel.vue'
import XLSX from 'xlsx';
import DateIcon from '@/components/Icons/DateV2.vue'

const MOVEMENT_STATUS_MAPPER = {
  INBOUND: {
    availability: 'arrow-up',
    on_order: '=',
    on_hand: 'arrow-up',
  },
  BOOKING: {
    availability: 'arrow-down',
    on_order: 'arrow-up',
    on_hand: '=',
  },
  BOOKING_RELEASE: {
    availability: 'arrow-up',
    on_order: 'arrow-down',
    on_hand: '=',
  },
  INBOUND_CANCEL: {
    availability: 'arrow-down',
    on_order: '=',
    on_hand: 'arrow-down',
  },
  OUTBOUND: {
    availability: '=',
    on_order: 'arrow-down',
    on_hand: 'arrow-down',
  },
  OUTBOUND_RELEASE: {
    availability: '=',
    on_order: 'arrow-up',
    on_hand: 'arrow-up',
  },
  ADJIN: {
    availability: 'arrow-up',
    on_order: '=',
    on_hand: 'arrow-up',
  },
  ADJOUT: {
    availability: 'arrow-down',
    on_order: '=',
    on_hand: 'arrow-down',
  },
  ADJ_BOOKING: {
    availability: '=',
    on_order: '=',
    on_hand: '=',
  },
  INIT: {
    availability: 'arrow-down',
    on_order: '=',
    on_hand: 'arrow-down',
  },
  TRANSFERIN: {
    availability: 'arrow-down',
    on_order: '=',
    on_hand: 'arrow-down',
  },
  TRANSFEROUT: {
    availability: 'arrow-down',
    on_order: '=',
    on_hand: 'arrow-down',
  },
  FGINBOUND: {
    availability: 'arrow-down',
    on_order: '=',
    on_hand: 'arrow-down',
  },
  FGCOMPOUTBOUND: {
    availability: 'arrow-down',
    on_order: '=',
    on_hand: 'arrow-down',
  },
}

const LIST_STATUS_MOVEMENT = [
  {
    "label": "Semua Status",
    "value": "ALL",
  },
  {
    "label": "Good Receive",
    "value": "INBOUND",
  },
  {
    "label": "Cancel Good Receive",
    "value": "INBOUND_CANCEL",
  },
  {
    "label": "Good Issued",
    "value": "OUTBOUND",
  },
  {
    "label": "Cancel Good Issued",
    "value": "OUTBOUND_RELEASE",
  },
  {
    "label": "Adjustment In",
    "value": "ADJIN",
  },
  {
    "label": "Adjustment Out",
    "value": "ADJOUT",
  },
  {
    "label": "Adjustment Booking",
    "value": "ADJ_BOOKING",
  },
  {
    "label": "Booking Order",
    "value": "BOOKING",
  },
  {
    "label": "Release Booking",
    "value": "BOOKING_RELEASE",
  },
  {
    "label": "Stock Transfer Warehouse In",
    "value": "TRANSFERIN",
  },
  {
    "label": "Stock Transfer Warehouse Out",
    "value": "TRANSFEROUT",
  },
  {
    "label": "Stock Type Transfer In",
    "value": "TSTIN",
  },
  {
    "label": "Stock Type Transfer Out",
    "value": "TSTOUT",
  },
  {
    "label": "Product to Product Transfer In",
    "value": "PTPIN",
  },
  {
    "label": "Product to Product Transfer Out",
    "value": "PTPOUT",
  },
  {
    "label": "VAS Inbound",
    "value": "FGINBOUND",
  },
  {
    "label": "VAS Outbound",
    "value": "FGCOMPOUTBOUND",
  },
  {
    "label": "Return",
    "value": "RETURN",
  },
  {
    "label": "Cancel Return",
    "value": "RETURN_RELEASE",
  },
]


export default {
  components: {
    ModalExportExcel,
    DateIcon,
  },
  data() {
    return {
      columns: columnDetailStockMovement,
      isLoading: false,
      totalRow: 10,
      statusCount: {
        ALL: 0,
        IN_STOCK: 0,
        OUT_OF_STOCK: 0,
        MINUS_OF_STOCK: 0,
      },
      params: {
        reff_text: '',
        movement_type: [],
        sort: 'movementDate,Desc',
        page: 1,
        size: 10,
      },
      sortBy: {
        sort: 'updatedAt',
        order: 'Desc',
      },
      sorts: [],
      sizeOptions: ['10', '20', '30', '40', '50'],
      colors: {
        IN_STOCK: 'blue',
        OUT_OF_STOCK: 'orange',
        MINUS_OF_STOCK: 'red',
      },
      tabs: [
        { title: 'Stock Movement', key: undefined },
      ],
      inventories: [],
      showModalExportExcel: false,
      loadingExport: false,
      detailWarehouse: null,
      detailCatalog: null,
      listStockMovement: [],
      ranges: {
        'Hari ini': [this.$moment(), this.$moment()],
        'Bulan ini': [this.$moment().startOf('month'), this.$moment().endOf('month')],
        'Tahun ini': [this.$moment().startOf('year'), this.$moment().endOf('year')],
        '7 Hari Terakhir': [this.$moment().subtract(7, 'days'), this.$moment()],
        '30 Hari Terakhir': [this.$moment().subtract(1, 'month'), this.$moment()],
        '1 Tahun Terakhir': [this.$moment().subtract(1, 'year'), this.$moment()],
      },
      dateFormat: 'DD MMMM YYYY',
      startDate: this.$moment().startOf('day').format(),
      endDate: this.$moment().endOf('day').format(),
      movementStatusMapper: MOVEMENT_STATUS_MAPPER,
      movementStatusList: LIST_STATUS_MOVEMENT,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    restriction() {
      return this.$store.state.user.restriction_base
    },
    screenHeight() {
      return ((screen.height - 900) * 0.4) + (screen.height * 0.4)
    },
    scroll() { 
      return { y: this.screenHeight > 360 ? this.screenHeight : 360, x: 1000 }
    },
    tHeight() {
      return !this.inventories.length ? '0px' : this.screenHeight > 360 
        ? `${this.screenHeight}px` : '360px'
    },
    pHeight() {
      return this.inventories && this.inventories.length ? '0px' : this.screenHeight > 360 
        ? `${this.screenHeight}px` : '360px'
    },
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
    titleDetailStockMovement() {
      let baseTitle = 'Detail'
      const catalogName = this.detailCatalog
      if (catalogName?.title) {
        baseTitle = baseTitle.concat(`#${catalogName.title}`)
      }
      if (catalogName?.sku) {
        baseTitle = baseTitle.concat(`#${catalogName.sku}`)
      }
      const warehouseName = this.detailWarehouse?.name
      if (warehouseName) {
        baseTitle = baseTitle.concat(`#${warehouseName}`)
      }
      return baseTitle
    },
  },
  watch: {
  },
  mounted() {
    this.fetchDetailCatalog()
    this.fetchDetailWarehouseInternal()
  },
  methods: {
    filterDetailStockMovement() {
      this.params.page = 1
      this.params.size = 10
      this.fetchDetailStockMovement()
    },
    onSelectStateMovement(record) {
      if (record === 'ALL') {
        this.params.movement_type = this.movementStatusList.map((obj) => obj.value)
      }
    },
    async onDeselectStateMovement(record) {
      if (record === 'ALL') {
        this.params.movement_type = await []
      } else if (record !== 'ALL' && this.params.movement_type.includes('ALL')) {
        const deselectAll = await this.params.movement_type
        this.params.movement_type = deselectAll.filter((obj) => !['ALL', record].includes(obj))
      }
    },
    onChangeTable(val, filter, sorter) {
      const sortValue = sorter.order === 'ascend' ? 'Asc' : 'Desc'
      this.params.sort = `movementDate,${sortValue}`
      this.fetchDetailStockMovement()
    },
    checkColorArrow(record) {
      return record === 'arrow-up' ? '#07BEB8' : '#FF0A54'
    },
    getMovementTypeLabel(movementType) {
      const selectedMovementType = this.movementStatusList.find((obj) => obj.value === movementType)
      return selectedMovementType?.label || movementType
    },
    disabledDate(current) {
      return current && current > this.$moment().endOf('day')
    },
    onChangeDate(date) {
      this.startDate = date[0]
        ? this.$moment(date[0]).format(this.formatDate)
        : ''
      this.endDate = date[1]
        ? this.$moment(date[1]).format(this.formatDate)
        : ''
    },
    fetchDetailStockMovement() {
      this.isLoading = true
      let filteredParams = {
        ...this.params,
      }
      let movementType = ''
      if (filteredParams.movement_type?.length > 0 && filteredParams.movement_type?.includes('ALL')) {
        movementType = filteredParams.movement_type.filter((obj) => obj !== 'ALL').join(',')
      } else {
        movementType = filteredParams.movement_type.join(',')
      }
      const payload = {
        warehouse_id: this.$route.query.warehouse_id,
        catalog_id: this.detailCatalog.id,
        ...filteredParams,
        movement_type: movementType,
      }
      if (this.startDate && this.endDate) {
        payload.periode_awal = this.$moment(this.startDate).startOf('day').format()
        payload.periode_akhir = this.$moment(this.endDate).endOf('day').format()
      }
      getDetailStockMovementBySKU(payload)
        .then(({ data }) => {
          this.listStockMovement = data.data
          this.totalRow = data.total_row
          this.isLoading = false
        })
        .catch((err) => {
          this.listStockMovement = []
          this.$notification.error({
            message: err?.response?.data?.message || 'detail movement not found',
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchDetailWarehouseInternal() {
      const warehouseId = this.$route.query.warehouse_id
      await getWarehouseById(warehouseId)
        .then(({ data: { data: response } }) => this.detailWarehouse = response)
        .catch((err) => {
          this.$notification.error({
            message: err?.response?.data?.message || 'id not found',
          })
        })
    },
    fetchDetailCatalog() {
      const payload = {
        sku: this.$route.params.id,
        business_id: this.businessId,
        warehouse_id: this.$route.query.warehouse_id,
      }
      getCatalogListBySKU(payload)
      .then(({ data }) => {
        if (data?.total_row === 1) {
          this.detailCatalog = data?.data.at(0)
          this.fetchDetailStockMovement()
        } else {
          this.$notification.error({
            message: 'Spesifik product untuk SKU tersebut tidak ditemukan, harap cek kembali produk anda.',
          })
        }
      })
      .catch((err) => {
        this.$notification.error({
          message: err?.response?.data?.message || 'sku not found',
        })
      })
    },
    onCloseModalExportExcel() {
      this.showModalExportExcel = false
    },
    exportDataTable(data) {
      this.loadingExport = true
      const payload = {
        warehouse_id: this.$route.query.warehouse_id,
        catalog_id: this.detailCatalog.id,
        page: 1,
        size: data.totalData,
        periode_awal: this.$moment(data.startDate).startOf('day').format(),
        periode_akhir: this.$moment(data.endDate).endOf('day').format(),
        movement_type: data.selectedMovementType,
        sort: 'movementDate,Desc',
      }
      getDetailStockMovementBySKU(payload)
      .then(({ data }) => {
        const flattenData = data.data.map((obj) => ({
          data: this.$moment(obj.movement_date).format('DD MMM YYYY, HH:mm'),
          movement_type: obj.movement_type,
          movement_status: this.getMovementTypeLabel(obj.movement_type),
          reff: obj.reff_text,
          movement_qty: Math.abs(obj.movement_quantity),
          availability: obj.availability,
          on_order: obj.on_order,
          on_hand: obj.on_hand,
        }))
        const rowData = [
          "Tanggal",
          "Movement Type",
          "Movement Status",
          "Reff",
          "Jumlah Pergerakan",
          "Dapat Dijual",
          "On Order",
          "On Hand",
        ]
        const worksheet = XLSX.utils.json_to_sheet(flattenData);
        const workbook = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
        XLSX.writeFile(workbook,`${this.titleDetailStockMovement}.xlsx`)
      })
      .catch(err => {
        this.loadingExport = false
        console.error(err)
      })
      .finally(() => {
        this.loadingExport = false
        this.showModalExportExcel = false
      })
    },
    toCamelCase(str) {
      return str.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase().replace('_', '')
      })
    },
    ellipsisText(text) {
      if(!text) return '-'
      return text.length > 49 ? `${text.substring(0, 46)}...` : text
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    resetFilter() {
      this.params = { 
        page: 1,
        size: 10,
      }
      this.fetchDetailStockMovement()
    },
    onSearchList() {
      this.params.page = 1
      this.fetchDetailStockMovement()
    },
    onChangeStatus(tab) {
      this.params.page = 1
      this.fetchDetailStockMovement()
    },
    onPageChange(pageNumber) {
      this.params.page = pageNumber
      this.fetchDetailStockMovement()
    },
    onShowSizeChange(current, pageSize) {
      this.params.page = current
      this.params.size = pageSize
      this.fetchDetailStockMovement()
    },
    async fetchInventory() {
    },
  },
}
</script>

<style lang="scss">
.inventory-list {
  div {
    .ant-table-body {
      min-height: v-bind(tHeight);
    }
    .ant-table-placeholder {
      min-height: v-bind(pHeight);
    }
  }
  .list-title {
    font-weight: bold;
  }
  .list-info {
    font-size: 12px;
  }
}
.minus {
  color: #ff0a54;
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>
