import { reactive, toRefs, onMounted, watch, computed } from 'vue'
import { notification } from 'ant-design-vue'
import store from '@/store'
import route from '@/router'
import debounce from 'lodash/debounce'
import { getSeller, getPaymentHistory } from '@/api/channels/distributor'
import { getInvoiceList, getInvoiceDetail, getListTagihan } from '@/api/invoice'
import { getWarehouseList } from '@/api/warehouse'
import {
  getDistributor,
} from '@/api/channels/distributor'

const paymentName = [
  {
    label: 'Cash Before Delivery',
    value: 'CBD',
  },
  {
    label: 'Cash On Delivery',
    value: 'COD',
  },
  {
    label: 'Cash After Delivery',
    value: 'CAD',
  },
]

export default () => {
  const routeName = route.app._route.name
  const state_ = reactive({
    dataPayment: [],
    modalCreatePayment: false,
    modalOrderPaid: false,
    warehouseData: [],
    selectedWarehouse: '',
    businessId: '',
    pagination: {
      size: 20,
      total_items: 0,
    },
    initialData: '',
    loading: false,
    listTagihan: [],
    paginationTagihan: {
      size: 10,
      total_items: 0,
    },
    pages: 1,
    sellerId: undefined,
    distributorSellerList: [],
    fetchingDistributorSeller: false,
  })

  onMounted(() => {
    getBusinessId()
    getWarehouse()
    getListHistoryPayment()
    fetchDistributorSeller()
  })

  watch(
    () => route.app._route.query,
    () => {
      if (route.app._route.name == routeName) {
        getListHistoryPayment()
      }
    },
  )
  watch(
    () => state_.selectedWarehouse,
    () => {
      getListHistoryPayment()
    },
  )

  const page = computed(() => {
    return +route.app._route.query.page || 1
  })

  const size = computed(() => {
    return +route.app._route.query.limit || 10
  })

  const isRoleSuperAdmin = computed(() => {
    return store.state.user?.role_id === '73ed8e78-bb8f-4d90-8547-d5fb54d44b82'
  })

  const handleCreatePayment = param => {
    state_.modalCreatePayment = param
  }

  const handleOrderPaid = param => {
    if (param === false) {
      const temp = { ...route.app._route.query }
      delete temp.seller_id
      route.push({
        query: temp,
      })
      state_.listTagihan = []
      state_.paginationTagihan.page = 1
    }
    state_.modalOrderPaid = param
  }

  const getBusinessId = async () => {
    state_.businessId = store.state.user.businessList?.[0]?.business_id
    return await store.state.user.businessList?.[0]?.business_id
  }


const fetchDistributorSeller = async () => {
  state_.fetchingDistributorSeller = true
  const businessId = route.app._route.query.business_id || (await getBusinessId())
  getDistributor({
    headers: {
      'Business-Id': businessId,
    },
    params: {
      page: 1,
      size: 999,
    },
  })
  .then(({ data: { data: res } }) => {
    if (res?.records?.length > 0) {
      state_.distributorSellerList = res.records.map(f => ({ label: f.name, value: f.id }))
    } else {
      state_.distributorSellerList = []
      notification.error({
        message: 'Terjadi kesalahan',
        description: 'Gagal memuat data distributor seller',
      })
    }
    state_.fetchingDistributorSeller = false
  })
  .catch(err => {
    state_.distributorSellerList = []
    notification.error({
      message: 'Terjadi kesalahan',
      description: 'Gagal memuat data distributor seller',
    })
    console.error(err)
    state_.fetchingDistributorSeller = false
  })
}

  const getListHistoryPayment = debounce(async ({ page }) => {
    state_.loading = true
    if (!state_.sellerId) {
      state_.loading = false
      return
    }
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    const warehouseId = route.app._route.query.warehouse_id || state_.selectedWarehouse
    if (!warehouseId) {
      state_.loading = false
      return
    }
    getPaymentHistory({
      business_id: businessId,
      id: route.app._route.params.id,
      seller_id: state_.sellerId,
      params: {
        limit: size.value,
        page: page || page.value,
        paid_at_sort: route.app._route.query.paid_at_sort,
        search_number: route.app._route.query.search_number || undefined,
      },
    })
    .then(({ data: { data: response } }) => {
      state_.dataPayment = response.contents
      state_.pagination.total_items = response.total_elements
    })
    .catch(err => {
      state_.dataPayment = []
      console.error(err)
    })
    .finally(() => state_.loading = false)

    getDistributor({
      headers: {
        'Business-Id': businessId,
      },
      params: {
        page: 1,
        size: 999,
      },
    })
    .then(async ({ data: { data: response } }) => {
    })
  }, 500)

  const getInvoiceNumber = async (param, index) => {
    state_.loading = true

    await getInvoiceDetail({
      business_id: state_.businessId,
      id: param?.orders[0].order_id,
    })
    .then(async ({ data: { data: response } }) => {
      let total_cad_amount = 0
      if (response.payment?.term === 'CAD' &&  param.is_paid_first === false) {
        const { data: { data: listTagihan } } = await getListTagihan({
          business_id: state_.businessId,
          payment_id: param.payment_id,
          channel_id: route.app._route.params.id,
          params: {
            page: 1,
            size: 10,
          },
        })
        if (listTagihan[0]?.orders) {
          total_cad_amount = listTagihan[0].orders.reduce(function (acc, obj) { return acc + obj.order_outstanding_amount }, 0)
        }
      } else {
        total_cad_amount = undefined
      }
      state_.initialData = response.invoice_number
      const getPaymentName = paymentName.find((obj) => obj.value === response?.payment?.term)
      const x = {
        ...state_.dataPayment[index],
        invoice_number: response.invoice_number,
        payment: response.payment,
        payment_name_tag: getPaymentName.label,
        total_cad_amount,
      }
      state_.dataPayment.splice(index, 1, x)
      state_.loading = false
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
      state_.loading = false
    })
  }

  const fetchListTagihan = async (param, page) => {
    await getListTagihan({
      business_id: state_.businessId,
      payment_id: param.payment_id ? param.payment_id : param,
      channel_id: route.app._route.params.id,
      params: {
        page: 1,
        size: 999,
      },
    })
    .then(async ({ data: { data: response } }) => {
      state_.paginationTagihan.total_items = response.total_elements
      const orderId = response[0].orders.map(item => item.order_id).join(',')

      await getInvoiceList({
        business_id: state_.businessId,
        params: { order_ids: orderId },
      })
      .then(({ data: res }) => {
        if (res.data) {
          state_.listTagihan = res.data
            .map((val, index) => {
              const { invoice_number, order_id } = val
              const listTemp = response[0].orders.find(f => f.order_id === order_id)
              if (listTemp) {
                return {
                  ...listTemp,
                  invoice_number,
                  calc_outstanding_amount: listTemp?.order_outstanding_amount - listTemp?.payment_amount,
                }
              }
              return null
            })
            .filter(Boolean)
        }
      })
      .catch(err => {
        console.error(err)
      })
    })
    .catch(err => {
      console.error(err)
      state_.listTagihan = []
    })
  }

  const getWarehouse = async () => {
    if (store.state.user.restriction_base.toLowerCase() == 'business') {
      await getWarehouseList({ business_id: route.app._route.query.business_id })
      .then(({ data }) => state_.warehouseData = data.data)
      .catch(err => console.error(err))
    }
  }

  const handleWarehouse = e => {
    state_.selectedWarehouse = e
  }

  const changePage = (page, pageSize) => {
    route.push({
      query: {
        ...route.app._route.query,
        page: page,
        limit: pageSize,
        search: undefined,
        sort: undefined,
      },
    })
  }
  const onSelectSeller = (value) => {
    getListHistoryPayment({
      page: 1,
    })
  }
  const changePageTagihan = value => {
    state_.pages = value
    fetchListTagihan(route.app._route.query.seller_id, value)
  }
  const filterOption = (input, option) => {
    return (
      option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    )
  }

  return {
    ...toRefs(state_),
    changePage,
    page,
    size,
    handleCreatePayment,
    handleOrderPaid,
    handleWarehouse,
    getListHistoryPayment,
    getInvoiceNumber,
    fetchListTagihan,
    changePageTagihan,
    isRoleSuperAdmin,
    filterOption,
    onSelectSeller,
  }
}
