<template>
  <div>
    <h1 class="h3 mb-4">
      {{ $t('finance.list_transfer') }}
    </h1>
    <template v-if="isRoleSuperAdmin">
      <a-row :gutter="24" class="">
        <a-col :span="12">
          <a-select 
            v-model="sellerId" 
            allow-clear
            show-search
            size="large"
            class="w-100 mt-2 select-antd-default" 
            option-filter-prop="children"
            :filter-option="filterOption"
            placeholder="Pilih seller"
            :options="distributorSellerList"
            :disabled="fetchingDistributorSeller"
            @change="onSelectSeller"
          />
        </a-col>
      </a-row>
      <a-row :gutter="24" class="">
        <a-col :span="12">
          <a-alert
            v-if="!sellerId"
            class="mt-2"
            message="Pilih seller terlebih dahulu"
            type="warning"
            show-icon
          />
        </a-col>
      </a-row>
    </template>
    <a-row :gutter="24" class="mt-4 mb-1">
      <a-col :span="12">
        <a-input-search
          v-model="params.criteria"
          size="large"
          :placeholder="$t('finance.search')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" class="bg-primary-blue text-white">
            {{ $t('utils.search') }} <a-icon type="search" />
          </a-button>
        </a-input-search>
      </a-col>
      <a-col :span="5" :offset="7">
        <a-select
          v-model="params.status"
          size="large"
          class="w-100 select-antd-default"
          :placeholder="$t('finance.payment_status')"
          :options="options"
          @change="onSearch"
        />
      </a-col>
    </a-row>
    <a-table
      class="payment-list mt-4"
      :data-source="payments"
      :row-key="record => record.id"
      :columns="columns"
      :loading="isLoading"
      :pagination="false"
      :scroll="scroll"
    >
      <template slot="payment_number" slot-scope="text, record">
        <div
          class="text-blue-2 fw-bold pointer"
          @click="
            onOpen(
              record.payment_id,
              record.cad_settlement,
              record.full_name,
              true,
            )
          "
        >
          {{ text }}
        </div>
      </template>
      <template slot="total_amount" slot-scope="text">
        {{ format(text, 'IDR') }}
      </template>
      <template slot="orders" slot-scope="text">
        {{ parseDate(text[0].created_at) }}
      </template>
      <div slot="expandedRowRender" slot-scope="record">
        <div class="payment-status">
          {{ $t('finance.payment_status') }}
          <a-button
            v-if="record.need_confirmation"
            type="primary"
            class="ml-3"
            ghost
            @click="
              onOpen(
                record.payment_id,
                record.cad_settlement,
                record.full_name,
                false,
              )
            "
          >
            {{ $t('finance.need_confirmation') }}
          </a-button>
          <a-tag v-else :color="colors[record.confirmation_status]">
            {{ $t(`finance.${record.confirmation_status.toLowerCase()}`) }}
          </a-tag>
        </div>
        <div v-if="record.cad_settlement">
          <a-row>
            <a-col :span="4">
              <label>Order</label>
            </a-col>
            <a-col :span="20">
              : {{ record.orders_total }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">
              <label>Payment Term</label>
            </a-col>
            <a-col :span="20">
              : CAD BT
            </a-col>
          </a-row>
        </div>
        <div v-else>
          <a-row>
            <a-col :span="4">
              <label>{{ $t('finance.invoice_number') }}</label>
            </a-col>
            <a-col :span="20">
              : {{ record.invoice_number }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">
              <label>{{ $t('finance.customer_name') }}</label>
            </a-col>
            <a-col :span="20">
              : {{ record.full_name }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">
              <label>{{ $t('finance.order_number') }}</label>
            </a-col>
            <a-col :span="20">
              : {{ record.orders[0].order_number }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">
              <label>Payment Term</label>
            </a-col>
            <a-col :span="20">
              : CBD BT
            </a-col>
          </a-row>
        </div>
      </div>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="totals"
        :show-total="
          (total, range) =>
            $t('utils.pagination_show_total', {
              perpage: `${range[0]} - ${range[1]}`,
              total,
            })
        "
        :disabled="isLoading || !payments.length"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>

    <!-- Modal CBD -->
    <a-modal
      center
      :width="480"
      :footer="null"
      :mask-closable="false"
      :visible="visible"
      :title="title"
      @cancel="onCancel"
    >
      <a-skeleton :loading="isLoading">
        <a-row v-if="detailProof" class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.payment_status') }}
          </a-col>
          <a-col :span="10">
            <a-tag :color="colors[proof.colors]">
              {{ $t(`finance.${proof.status}`) }}
            </a-tag>
          </a-col>
        </a-row>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.customer_name') }}
          </a-col>
          <a-col :span="10">
            {{ proof.full_name }}
          </a-col>
        </a-row>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.order_id') }}
          </a-col>
          <a-col :span="10">
            {{ proof.order_number }}
          </a-col>
        </a-row>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.transaction_date') }}
          </a-col>
          <a-col :span="10">
            {{ parseDate(proof.created_at) }}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="14">
            {{ $t('finance.rest_of_bill') }}
          </a-col>
          <a-col :span="10">
            <b>{{ format(proof.total_amount, 'IDR') }}</b>
          </a-col>
        </a-row>
        <hr />
        <!-- <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.payment_date') }}
          </a-col>
          <a-col :span="10">
            -
          </a-col>
        </a-row> -->
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.payment_type') }}
          </a-col>
          <a-col :span="10">
            Bank Transfer
          </a-col>
        </a-row>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.bank_name') }}
          </a-col>
          <a-col :span="10">
            {{ proof.bank_name }}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="14">
            {{ $t('finance.account_number') }}
          </a-col>
          <a-col :span="10">
            {{ proof.account_number }}
          </a-col>
        </a-row>
        <hr />
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.transfer_evidence') }}
          </a-col>
          <a-col :span="10">
            <img
              :src="`${
                proof.image_url || $defaultImage
              }?tr=w-30,h-30,cm-pad_resize,bg-FFF`"
              alt="Bukti Transfer"
              class="image-thumbnail"
              style="cursor: pointer"
              @click="onOpenImage"
            />
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="14">
            {{ $t('finance.input_payment') }}
          </a-col>
          <a-col :span="10">
            <InputNumber
              v-if="!detailProof"
              v-model="nominal"
              @blur="checkAmount"
            />
            <span v-else>
              <b>{{ format(proof.paid_amount, 'IDR') }}</b>
            </span>
          </a-col>
        </a-row>
      </a-skeleton>
      <div v-if="!detailProof" class="mt-4 text-center">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          :disabled="isLoading"
          @click="onReject"
        >
          {{ $t('finance.payment_reject') }}
        </a-button>
        <a-button
          type="primary"
          size="large"
          :disabled="isLoading"
          @click="onConfirm"
        >
          {{ $t('finance.payment_confirm') }}
        </a-button>
      </div>
    </a-modal>

    <!-- Modal CAD -->
    <a-modal
      center
      :width="480"
      :footer="null"
      :mask-closable="false"
      :visible="visibleBill"
      :title="title"
      @cancel="onCancelBill"
    >
      <a-skeleton :loading="isLoading">
        <a-row v-if="detailProof" class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.payment_status') }}
          </a-col>
          <a-col :span="10">
            <a-tag :color="colors[proof.colors]">
              {{ $t(`finance.${proof.status}`) }}
            </a-tag>
          </a-col>
        </a-row>
        <a-row class="mb-4" :gutter="24">
          <a-col :span="14">
            Distributor
          </a-col>
          <a-col :span="10">
            {{ $store.state.user.full_name }}
          </a-col>
        </a-row>
        <a-table
          class="cad-table"
          :data-source="proof.orders"
          :row-key="record => record.order_id"
          :columns="columnsBill"
          :pagination="false"
        >
          <template slot="amount" slot-scope="text">
            {{ format(text, 'IDR') }}
          </template>
          <template slot="expired_at" slot-scope="text">
            {{ parseDate(text) }}
          </template>
        </a-table>
        <div class="text-right mt-2 mb-4">
          {{ `${$t('finance.total_bill')}:` }}
          <b>{{ format(proof.total_amount, 'IDR') }}</b>
        </div>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.payment_date') }}
          </a-col>
          <a-col :span="10">
            -
          </a-col>
        </a-row>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.payment_type') }}
          </a-col>
          <a-col :span="10">
            Bank Transfer
          </a-col>
        </a-row>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.bank_name') }}
          </a-col>
          <a-col :span="10">
            {{ proof.bank_name }}
          </a-col>
        </a-row>
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.account_number') }}
          </a-col>
          <a-col :span="10">
            {{ proof.account_number }}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="14">
            {{ $t('finance.payment_amount') }}
          </a-col>
          <a-col :span="10">
            <b>{{ format(proof.total_amount, 'IDR') }}</b>
          </a-col>
        </a-row>
        <hr />
        <a-row class="mb-2" :gutter="24">
          <a-col :span="14">
            {{ $t('finance.transfer_evidence') }}
          </a-col>
          <a-col :span="10">
            <img
              :src="`${
                proof.image_url || $defaultImage
              }?tr=w-30,h-30,cm-pad_resize,bg-FFF`"
              alt="Bukti Transfer"
              class="image-thumbnail"
              style="cursor: pointer"
              @click="onOpenImage"
            />
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="14">
            {{ $t('finance.input_payment') }}
          </a-col>
          <a-col :span="10">
            <InputNumber
              v-if="!detailProof"
              v-model="nominal"
              @blur="checkAmount"
            />
            <span v-else>
              <b>{{ format(proof.paid_amount, 'IDR') }}</b>
            </span>
          </a-col>
        </a-row>
      </a-skeleton>
      <div v-if="!detailProof" class="mt-4 text-center">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          :disabled="isLoading"
          @click="onReject"
        >
          {{ $t('finance.payment_reject') }}
        </a-button>
        <a-button
          type="primary"
          size="large"
          :disabled="isLoading"
          @click="onConfirm"
        >
          {{ $t('finance.payment_confirm') }}
        </a-button>
      </div>
    </a-modal>

    <!-- Modal Image -->
    <a-modal
      center
      :footer="null"
      :visible="visibleImage"
      @cancel="onCancelImage"
    >
      <img
        :src="`${
          proof.image_url || $defaultImage
        }?tr=w-500,h-500,cm-pad_resize,bg-FFF`"
        alt="Bukti Transfer"
        class="image-default"
      />
    </a-modal>

    <!-- Modal Reject -->
    <a-modal
      center
      :width="420"
      :footer="null"
      :mask-closable="false"
      :visible="visibleMistake"
      :title="$t('finance.mistake')"
      @cancel="onCancelMistake"
    >
      <a-select
        v-model="mistake"
        size="large"
        class="w-100 select-antd-default"
        :placeholder="$t('finance.select_mistake')"
        :options="optionsMistake"
      />
      <div class="mt-4 text-center">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          @click="onCancelMistake"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button type="primary" size="large" @click="onConfirmMistake">
          OK
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'PaymentChannel',
}
</script>
<script setup>
import debounce from 'lodash/debounce'
import route from '@/router'
import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue'
import { getInvoiceDetail } from '@/api/invoice'
import { getSeller, getTransferList, getTransferProof, addTransfer, rejectTransferProof } from '@/api/channels/distributor'
import { columnsPaymentChannel, columnsBill } from '@/data/columns'
import { useCurrency } from '@/composable/useCurrency'
import { useInputNumber } from '@/composable/useInputNumber'
import { useDateTime } from '@/composable/useDateTime'
import InputNumber from '@/components/Input/InputNumber.vue'
import {
  getDistributor,
} from '@/api/channels/distributor'

const { $i18n, $message, $notification, $route, $store } =
  getCurrentInstance().proxy.$root
const { format } = useCurrency()
const { toNumber } = useInputNumber()
const { parseDate } = useDateTime()

// Data
const businessId = ref(
  Object.keys($store.getters['user/businessListObjectByKey'])[0],
)
const sellerId = ref(undefined)
const totals = ref(0)
const screenHeight = ref(((screen.height - 900) * 0.5) + (screen.height * 0.5))
const nominal = ref(null)
const mistake = ref(undefined)
const isLoading = ref(false)
const fetchingDistributorSeller = ref(false)
const distributorSellerList = ref([])
const visible = ref(false)
const visibleBill = ref(false)
const visibleImage = ref(false)
const visibleMistake = ref(false)
const detailProof = ref(false)
const columns = ref(columnsPaymentChannel)
const payments = ref([])
const sizeOptions = ref(['10', '20', '30', '40', '50'])
const options = ref([
  { label: 'Semua', value: 'WAITING,CONFIRMED,CANCELLED,REJECTED,OUTSTANDING' },
  { label: 'Perlu Konfirmasi', value: 'WAITING' },
  { label: 'Sudah di Konfirmasi', value: 'CONFIRMED' },
  { label: 'Dibatalkan', value: 'CANCELLED' },
  { label: 'Ditolak', value: 'REJECTED' },
])
const optionsMistake = ref([
  { label: 'Pembayaran sudah masuk, bukti salah', value: '1' },
  { label: 'Pembayaran belum masuk, bukti benar', value: '2' },
  { label: 'Pembayaran belum masuk, bukti salah', value: '3' },
])
const colors = reactive({
  CONFIRMED: 'blue',
  WAITING: 'orange',
  CANCELLED: 'red',
  REJECTED: 'red',
  OUTSTANDING: 'gray',
})
const params = reactive({
  criteria: '',
  status: 'WAITING,CONFIRMED,CANCELLED,REJECTED,OUTSTANDING',
  page: 1,
  limit: 10,
})
let proof = reactive({})
// eslint-disable-next-line no-unused-vars
let invoice = reactive({})

// Computed
const business = computed(
  () => Object.keys($store.getters['user/businessListObjectByKey'])[0],
)
const scroll = computed(() => ({
  y: screenHeight.value > 450 ? screenHeight.value : 450,
}))
const tHeight = computed(() => {
  return !payments.value.length
    ? '0px'
    : screenHeight.value > 450
    ? `${screenHeight.value}px`
    : '450px'
})
const title = computed(() => {
  return detailProof.value
    ? $i18n.t('finance.transaction_detail')
    : $i18n.t('finance.confirm_transfer')
})
const isRoleSuperAdmin = computed(() => {
  return $store.state.user?.role_id === '73ed8e78-bb8f-4d90-8547-d5fb54d44b82'
})
// Method
const fetchSeller = debounce(async () => {
  isLoading.value = true
  const bodyValue = {
    businessId: businessId.value,
    params: {
      warehouse_id: route.app._route.query.warehouse_id,
    },
  }
  await getSeller(bodyValue)
    .then(({ data: { data: response } }) => {
      sellerId.value = response.length ? response[0].seller_id : undefined
      fetchPayments()
    })
    .catch(err => {
      sellerId.value = undefined
      payments.value = []
      totals.value = 0
      invoice = {}
      isLoading.value = false
      console.error(err)
    })
}, 500)

const fetchDistributorSeller = () => {
  fetchingDistributorSeller.value = true
  getDistributor({
    headers: {
      'Business-Id': businessId.value,
    },
    params: {
      page: 1,
      size: 999,
    },
  })
  .then(({ data: { data: res } }) => {
    if (res?.records?.length > 0) {
      distributorSellerList.value = res.records.map(f => ({ label: f.name, value: f.id }))
    } else {
      distributorSellerList.value = []
      $notification.error({
        message: 'Terjadi kesalahan',
        description: 'Gagal memuat data distributor seller',
      })
    }
    fetchingDistributorSeller.value = false
  })
  .catch(err => {
    distributorSellerList.value = []
    $notification.error({
      message: 'Terjadi kesalahan',
      description: 'Gagal memuat data distributor seller',
    })
    console.error(err)
    fetchingDistributorSeller.value = false
  })
}

const filterOption = (input, option) => {
  return (
    option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
  )
}
const fetchPayments = async () => {
  isLoading.value = true
  if (!sellerId.value) {
    isLoading.value = false
    return
  }
  payments.value = []
  const promises = []
  let allPaymentList = []
  const bodyValue = {
    businessId: businessId.value,
    sellerId: sellerId.value,
    id: route.app._route.params.id,
    params: { ...params, page: params.page },
  }
  if (!params.criteria) delete bodyValue.params.criteria
  getTransferList(bodyValue)
    .then(({ data: { data: response } }) => {
      allPaymentList = response.contents
      response.contents.forEach(async item => {
        const bodyValue = {
          business_id: businessId.value,
          id: item.orders[0].order_id,
        }
        promises.push(getInvoiceDetail(bodyValue))
      })
      totals.value = response.total_elements
      isLoading.value = false
    })
    .catch(err => {
      payments.value = []
      totals.value = 0
      isLoading.value = false
      console.error(err)
    })
    .finally(() => {
      Promise.all(promises)
        .then((res) => {
          const allInvoiceList = res.map((obj) => obj.data.data)
          payments.value = allPaymentList.map((obj) => {
            const findInvoice = allInvoiceList.find((obj2) => obj2.order_id === obj.orders[0].order_id) 
            return {
              invoice_number: findInvoice?.invoice_number,
              full_name: findInvoice?.full_name,
              ...obj,
            }
          })
        })
        .catch(err => {
          console.error(err)
        })
    })
}

const fetchProof = async (paymentId, name) => {
  isLoading.value = true
  const bodyValue = {
    businessId: businessId.value,
    id: route.app._route.params.id,
    paymentId,
  }
  await getTransferProof(bodyValue)
    .then(({ data: { data: response } }) => {
      const {
        payment_id,
        orders,
        created_at,
        total_amount,
        bank_name,
        account_number,
        image_url,
        paid_amount,
        status,
      } = response
      proof.payment_id = payment_id
      proof.order_number = orders.length ? orders[0]?.order_number : '-'
      proof.created_at = created_at
      proof.total_amount = total_amount
      proof.bank_name = bank_name
      proof.account_number = account_number
      proof.image_url = image_url
      proof.orders = orders
      proof.paid_amount = paid_amount
      proof.status = status.toLowerCase()
      proof.colors = status
      proof.full_name = name
    })
    .catch(err => {
      proof = {}
      console.error(err)
    })
    .finally(() => (isLoading.value = false))
}

const onConfirm = async () => {
  if (!nominal.value) {
    $message.error('Nominal tidak boleh kosong')
    return false
  } else if (toNumber(nominal.value) - proof.total_amount !== 0) {
    $message.error('Nominal tidak boleh kurang atau lebih dari total tagihan')
    return false
  }
  isLoading.value = true
  const bodyValue = {
    businessId: businessId.value,
    id: route.app._route.params.id,
    paymentId: proof.payment_id,
    data: {
      amount: toNumber(nominal.value),
      bank_valid: true,
      image_valid: true,
      currency: 'IDR',
      payment_type: 'BANK_TRANSFER',
    },
  }
  await addTransfer(bodyValue)
    .then(res => {
      onCancel()
      onCancelBill()
      $notification.success({
        message: 'Berhasil',
        description: 'Bukti Transfer Berhasil dikonfirmasi',
      })
      fetchSeller()
    })
    .catch(err => {
      $notification.error({
        message: 'Gagal',
        description: 'Bukti Transfer Gagal dikonfirmasi',
      })
      console.error(err)
    })
    .finally(() => (isLoading.value = false))
}

const onSearch = () => {
  params.page = 1
  fetchPayments()
}

const onSelectSeller = () => {
  params.page = 1
  fetchPayments()
}

const onPageChange = pageNumber => {
  params.page = pageNumber
  fetchPayments()
}

const onShowSizeChange = (current, pageSize) => {
  params.page = current
  params.limit = pageSize
  fetchPayments()
}

const onOpen = (paymentId, cad, name, detail) => {
  nominal.value = null
  detailProof.value = detail
  if (cad) {
    visibleBill.value = true
  } else {
    visible.value = true
  }
  fetchProof(paymentId, name)
}

const onOpenImage = () => {
  visibleImage.value = true
}

const onCancel = () => {
  visible.value = false
}

const onCancelBill = () => {
  visibleBill.value = false
}

const onCancelImage = () => {
  visibleImage.value = false
}

const onReject = async () => {
  isLoading.value = true
  const bodyValue = {
    businessId: businessId.value,
    id: route.app._route.params.id,
    paymentId: proof.payment_id,
  }
  await rejectTransferProof(bodyValue)
    .then(({ data: response }) => {
      if (response.message.toLowerCase() === 'success') {
        onCancel()
        onCancelBill()
        $notification.success({
          message: 'Berhasil',
          description: 'Bukti Transfer Berhasil ditolak',
        })
        fetchSeller()
      } else {
        $notification.error({
          message: 'Gagal',
          description: 'Bukti Transfer Gagal ditolak',
        })
      }
    })
    .catch(err => {
      $notification.error({
        message: 'Gagal',
        description: 'Bukti Transfer Gagal ditolak',
      })
      console.error(err)
    })
    .finally(() => (isLoading.value = false))
}

const onConfirmMistake = () => {
  visibleMistake.value = false
}

const onCancelMistake = () => {
  visibleMistake.value = false
  visible.value = true
}

const checkAmount = debounce(value => {
  if (value - proof.total_amount !== 0) {
    $message.error('Nominal tidak boleh kurang atau lebih dari total tagihan')
  }
}, 300)

// Watch
watch(
  () => business.value,
  newValue => {
    businessId.value = newValue
    fetchSeller()
  },
)

watch(
  () => route.app._route,
  newValue => {
    if (newValue.name === $route.name && !!newValue.query.warehouse_id) {
      fetchSeller()
    }
  },
)

watch(() => $route.query.business_id, (value) => {
  if (value) {
    businessId.value = value
  }
}, { deep: true, immediate: true })

onMounted(() => {
  fetchSeller()
  fetchDistributorSeller()
})

</script>

<style lang="scss">
.fs-14 {
  font-size: 14px !important;
}
.fw-bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.payment-status {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
}
.image-thumbnail {
  width: 30px;
  height: auto;
}
.image-default {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.payment-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-col-14 {
  font-weight: 500;
  color: #6c757d;
}
.cad-table {
  overflow-x: scroll;
}
.ant-table-row-expand-icon {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
  line-height: 17px !important;
}
tr.ant-table-expanded-row {
  position: relative;
  background: #f4f4f4 !important;

  label {
    font-weight: 500;
  }
}
.button-confirm {
  background-color: #ff0a54 !important;
  color: #fff !important;
}
</style>
