<template>
  <a-row
    type="flex"
    :gutter="16"
  >
    <a-col :xs="{ span: 24 }" :lg="{ span: 5 }">
      <ol
        v-scroll-spy-link
        v-scroll-spy-active="{ class: 'active' }"
        class="position-sticky p-0 m-0 mt-4"
      >
        <li
          v-for="(label, key) in sections"
          :key="key"
        >
          <a
            class=""
            @click.prevent
          >{{ label }}</a>
        </li>
      </ol>
    </a-col>

    <a-col :xs="{ span: 24 }" :lg="{ span: 19 }">
      <ValidationObserver ref="validationObserver" slim>
        <a-form novalidate @submit.prevent="saveProducts">
          <fieldset
            v-scroll-spy="{ data: 'activeSection', offset: 125 }"
            class="main"
            :disabled="submitting || loadingDetail"
          >
            <DetailProduct
              v-model="model"
              :categories="categories"
              :brands="brands"
              :business-id="businessId"
              :loading="submitting || loadingDetail"
              :rendering="loading"
              :permission="permission"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveDetail)"
            />

            <PhotoAndVideo
              v-model="model"
              class="mb-5"
              :permission="permission"
              :loading="loading"
              :submitting="submitting || loadingDetail"
              @change="debouncedSaveImages"
            />

            <TypeAndPrice
              v-model="model"
              :business="business"
              class="mb-5"
              :loading="loading"
              :catalogs="catalogsModel"
              :submitting="submitting || loadingDetail"
              :permission="permission"
              @offscreen="runOnDraft(debouncedSaveCatalogues)"
              @changeuom="onChangeUom"
              @exist="val => existSKU = val"
            />

            <WeightAndShipping
              v-model="model.units"
              :product="model"
              class="mb-5"
              :loading="loading"
              :permission="permission"
              @input:physical="(value) => model.detail.physical = value"
              @cancelBaseUomChange="(value) => {
                model.catalogs.items[0].price.uom = value
              }"
              @offscreen="runOnDraft(debouncedSaveWeightAndShipping)"
              @changeUkuranPaket="changeUkuranPaket"
            />

            <!-- <WarehouseAndInventories
              v-model="model"
              :warehouses="warehouses"
              :inventory-list="inventoryList"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveWarehousesAndInventories)"
            />

            <ChannelPublication 
              v-if="$route.meta.title !== 'Powerbiz - Add Product Redeem' && $route.meta.title !== 'Powerbiz - Edit Product Redeem'"
              v-model="model.channels"
              :channels="channels"
              :product="model"
              :channel-categories="channelCategories"
              @add="addChannelAttribute"
            /> -->

            <div class="mt-4 mb-5 py-3 border-top text-right footer">
              <!-- <div>
                <a-button
                  size="large"
                  type="primary"
                  ghost
                  class="px-5 mr-3 ml-auto"
                  @click="onCancel"
                >
                  {{ $t('utils.cancel') }}
                </a-button>

                :disabled="submitting || !$can('group1.product.product-master.WRITE')"
                <a-button
                  size="large"
                  type="primary"
                  class="px-5"
                  html-type="submit"
                  :disabled="submitting || !$can('group1.product.product-master.WRITE') || loadingDetail"
                >
                  <a-icon v-if="submitting" type="loading" class="mr-2" />
                  {{ $t('utils.save') }}
                </a-button>
              </div> -->

              <a-button
                size="large"
                type="primary"
                ghost
                class="px-5 mr-3 ml-auto"
                :disabled="submitting"
                @click="onCancel"
              >
                {{ $t('utils.cancel') }}
              </a-button>

              <a-button
                v-if="permission.includes('WRITE') && !$route.query.edit"
                size="large"
                type="primary"
                class="px-5"
                html-type="submit"
                :loading="submitting"
                :disabled="submitting || loadingDetail"
              >
                {{ $t('utils.save') }}
              </a-button>
            </div>
          </fieldset>
        </a-form>
      </ValidationObserver>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue'
import DetailProduct from './DetailProduct.vue'
import { ValidationObserver } from 'vee-validate'
import PhotoAndVideo from './PhotoAndVideo.vue'
import debounce from 'lodash/debounce'
import TypeAndPrice from './TypeAndPrice/index.vue'
import { useFormula } from './TypeAndPrice/Price/useFormula'
import WeightAndShipping from './WeightAndShipping/index.vue'
// import WarehouseAndInventories from './WarehouseAndInventories/index.vue'
// import ChannelPublication from './Channel/ChannelPublication.vue'
import { CREATEPRODUCTREDEEM } from '@/api/channels/distributor'
import {
  getCategoryList,
  getMappingByCategoryId,
  addProductChannel,
  productPublish,
  saveProductImages,
  crudProduct,
  updateCatalogsProduct,
  deleteProduct,
} from '@/api/product'
import { getPriceRange, saveProductPrice } from '@/api/price'
import { getInventoryListV2, getUom, addWarehouse, saveProductInventory } from '@/api/inventory'
import { getShippingUnit, saveProductShipping } from '@/api/shipping'
import { 
  getChannelWarehouse, 
  channelProductDraft, 
  channelEventProcess, 
  channelImageDraft, 
  uploadChannelImage, 
  deleteChannelDraft, 
  addChannelProduct, 
} from '@/api/channels/index'
import { shopeeImageAttribute } from '@/api/channels/shopee'
import { getBusinessChannel } from '@/api/business'
import { getWarehouses } from '@/api/warehouse'

const { fetchPriceFormula } = useFormula()

const DEFAULT_MODEL = () => ({
  id: null,
  status: 'DRAFT',
  detail: {
    brand_id: null,
    category_id: null,
    category_name: '',
    category_blibli_id: null,
    category_blibli_name: '',
    condition: 'NEW',
    imported: false,
    long_description: '',
    title: '',
    physical: true,
    vat_type: 'INCLUDE',
  },
  images: [],
  catalogs: {
    attributes: null,
    options: null,
    items: [
      {
        id: null,
        factory_sku: null,
        images: [],
        option: null,
        price: {
          currency: 'IDR',
          value: 0,
          min_qty: 1,
          price_qty: 1,
          uom: 'PCS',
          price_id: null,
          formula_id: null,
          product_variables: [],
        },
        sku: null,
        status: true,
        title: null,
        ext_catalog_id: null,
        loading: false,
        msg: '',
      },
    ],
    bundling: {
      type: null,
      bundles: [],
    },
  },
  units: [
    {
      unit: 'PCS',
      numerator: 1,
      denumerator: 1,
      package: {
        length: null,
        length_unit: 'cm',
        width: null,
        width_unit: 'cm',
        height: null,
        height_unit: 'cm',
        weight: null,
        weight_unit: 'g',
      },
      collapseOpen: false,
    },
  ],
  inventories: [],
  channels: [],
})

const parseErrorMessage = msg => {
  let result = 'Coba lagi beberapa saat'
  if (msg && msg.includes('Sku Already in Business')) {
    result = `Kode SKU ${msg.split(' ').pop()} telah terdaftar pada produk lain`
  }
  return 'Oops, produk gagal disimpan! ' + result
}

export default defineComponent({
  name: 'CreateProductForm',
  components: {
    DetailProduct,
    ValidationObserver,
    PhotoAndVideo,
    TypeAndPrice,
    WeightAndShipping,
    // WarehouseAndInventories,
    // ChannelPublication,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      default: () => {},
    },
    permission: {
      type: [Object, Array],
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeSection: 0,
      saving: {
        detail: false,
      },
      /**
       * @type {import('@/types/product').ProductFormModel}
       */
      model: DEFAULT_MODEL(),
      /** @type {import('@/types/warehouse').Warehouse[]} */
      warehouses: [],
      channels: [],
      shippingUnits: [],
      categories: [],
      submitting: false,
      loadingDetail: false,
      brands: [],
      inventoryList: [],
      existSKU: [],
      tempChannels: [],
      existingChannels: [],
      channelCode: '',
      channelId: '',
      channelProductId: '',
      channelWarehouseId: '',
      channelImages: [],
      shopeeImages: [],
      channelAttribute: [],
      channelCategories: {},
      channelBody: {},
      channelList: ['shopee', 'shopee_id', 'tiktok', 'tiktok_id', 'tokopedia', 'tokopedia_id', 'blibli', 'blibli_id'],
      catalogsModel: {},
    }
  },
  computed: {
    sections() {
      const section = {
        detail: this.$t('product.product_details'),
        gallery: this.$t('product.photo_video'),
        price: this.$t('product.product_type_and_price'),
        shipping: this.$t('product.weight_product_shipping'),
        inventory: this.$t('product.inventory'),
      }
      const returnSection = this.$route.meta.title === 'Powerbiz - Add Product Redeem' || this.$route.meta.title === 'Powerbiz - Edit Product Redeem'
        ? section : { ...section, channel: this.$t('product.channel') }
      return returnSection
    },
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.business?.business_id || (this.$route.query.business_id
        ? String(this.$route.query.business_id)
        : null)
    },
    productId() {
      return this.model.id
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId,
      }
    },
    httpParams() {
      return {
        business_id: this.businessId,
      }
    },
    isNewProduct() {
      return !this.model.id
    },
    listChannels() {
      const channel = this.$store.state.business.listChannels.find(item => item.sales_channel.code === 'distributor_redeem')
      return channel?.id || 0
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler() {
        this.createProductModel()
      },
    },
    productId: {
      immediate: true,
      handler(value) {
        if (value && this.product) {
          this.fetchShippingAndInventoryUnits()
          this.fetchCatalogPrices()
          this.fetchInventory()
        }
      },
    },
    'businessId': {
      immediate: true,
      handler() {
        this.loadingDetail = true
        Promise.all([
          this.fetchCategories(),
          this.fetchWarehouse(),
          this.fetchChannels(),
          this.fetchFormula(),
          this.getBrands(),
          this.fetchInventory(),
        ])
        .finally(() => {
          this.loadingDetail = false
        })
      },
    },
    'model.detail.category_id'(ids) {
      const id = Number(ids[ids.length - 1])
      this.model.detail.category = id
        ? this.findCategory(this.categories, id)
        : {}
      this.fetchMappingByCategoryId(id)
    },
    listChannels() {
      this.initChannel()
    },
  },
  created() {
    this.debouncedSaveDetail = debounce(this.saveDetail, 50)
    this.debouncedSaveImages = debounce(this.saveImages, 500)
    this.debouncedSaveCatalogues = debounce(this.saveCatalogues, 500)
    this.debouncedSaveWeightAndShipping = debounce(this.saveWeightAndShipping, 500)
    this.debouncedSaveWarehousesAndInventories = debounce(this.saveWarehousesAndInventories, 500)
  },
  mounted() {
    this.initChannel()
  },
  methods: {
    async getBrands() {
      const data = await this.$store.dispatch('products/GETBRANDS', { business_id: this.business.business_id })
      this.brands = data
    },
    initChannel() {
      if (this.$route.meta.title === 'Powerbiz - Add Product Redeem' || this.$route.meta.title === 'Powerbiz - Edit Product Redeem') {
        this.model.channels = [{
          business_int_id: 182,
          code: 'distributor_redeem',
          deleted: false,
          id: this.listChannels,
          title: 'Kino Redeem',
        }]
      }
    },
    fetchFormula: debounce(async function(value) {
      fetchPriceFormula({ businessId: value })
    }, 500),
    changeUkuranPaket(val, index) {
      this.model.units.forEach((item, idx) => {
        if (idx === index) {
          item.package = {
            ...item.package,
            length_unit: val,
            width_unit: val,
            height_unit: val,
            // weight_unit: val,
          }
        }
      })
    },
    onChangeUom(uom) {
      this.model.catalogs.items.forEach(item => {
        item.price = { ...item.price, uom }
      })
    },
    runOnDraft(fn) {
      if (this.model.status === 'DRAFT') {
        return fn()
      }
    },
    async fetchMappingByCategoryId(category_id) {
      if(category_id) {
        await getMappingByCategoryId({
          business_id: this.businessId,
          category_id,
        })
        .then(({ data }) => this.channelCategories = data)
        .catch(() => this.channelCategories = {})
      } else {
        this.channelCategories = {}
      }
    },
    fetchCategories: debounce(async function() {
      const { data } = await getCategoryList({ params: { ...this.httpParams } })
      this.categories = data?.master || []
      const parent = this.product?.detail?.category?.id ? this.findParent(data?.master, this.product.detail.category.id) : []
      if (parent.length) this.model.detail.category_id = parent
    }, 500),
    async fetchInventory() {
      this.loadingDetail = true

      let bodyValue = {
        business_id: this.$route.query.business_id,
        workspace_id: this.$route.query.workspace_id,
        product_id: this.model.id,
      }
      
      await getInventoryListV2(bodyValue)
        .then(({ data }) => {
          let warehouses = []
          let inventories = []
          if(data?.length) {
            data.forEach((inventory, index) => {
              if(!inventories.includes(inventory.warehouse_id)) {
                warehouses.push({
                  business_id: inventory.business_id,
                  id: inventory.warehouse_id,
                  is_main: index == 0 ? true : false,
                  catalogs: [{
                    id: inventory.catalog_id,
                    uom: inventory.uom,
                    pre_order_status: inventory?.pre_order_status || inventory?.pre_order || false,
                    sellable: 0,
                    allocated: 0,
                    avail: inventory.availability,
                    on_hand: inventory.on_hand,
                    warehouse_id: inventory.warehouse_id,
                  }],
                })
                inventories.push(inventory.warehouse_id)
              } else {
                const idx = warehouses.findIndex(warehouse => warehouse.id === inventory.warehouse_id)
                if(idx > -1) {
                  warehouses[idx].catalogs.push({
                    id: inventory.catalog_id,
                    uom: inventory.uom,
                    pre_order_status: inventory?.pre_order_status || inventory?.pre_order || false,
                    sellable: 0,
                    allocated: 0,
                    avail: inventory.availability,
                    on_hand: inventory.on_hand,
                    warehouse_id: inventory.warehouse_id,
                  })
                }
              }
            })
          }
          this.model.inventories = warehouses
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => this.loadingDetail = false)
    },
    fetchWarehouse: debounce(async function() {
      const { data: { data } } = await getWarehouses({ business_id: this.businessId })
      this.warehouses = data?.data || []
    }, 500),
    fetchChannels: debounce(async function() {
      this.channels = []
      const { data: { data } } = await getBusinessChannel(this.businessId)
      data?.channels?.forEach(channel => {
        if(channel.code !== 'dropship') {
          const findChannel = this.$store.state.business.listChannels.find(item => item.id == channel.id && item.sales_channel.code === channel.code)
          this.channels.push({
            ...channel,
            sales: findChannel?.sales_channel?.id || '',
          })
        }
      })
    }, 500),
    async fetchShippingAndInventoryUnits() {
      const fetchShippingUnitPromise = getShippingUnit({
        product_id: this.productId,
        business_id: this.businessId,
      })

      const fetchInventoryUnitPromise = getUom({
        id: this.productId,
        params: { business_id: this.businessId },
      })

      const [{ data: { data: shippingUnitResponse }}, { data: { data: inventoryUnitResponse }}] = await Promise.all([
        fetchShippingUnitPromise,
        fetchInventoryUnitPromise,
      ])

      const shippingUnits = shippingUnitResponse?.product_units || []
      const inventoryUnits = inventoryUnitResponse?.units || []

      this.model.units = shippingUnits.length
        ? shippingUnits.map(({ unit, ...unitPackage }) => ({
          unit,
          package: unitPackage,
          collapseOpen: false,
          ...(inventoryUnits.find(u => u.unit === unit) || {}),
        }))
        : inventoryUnits.map(({ unit, numerator, denumerator }) => {
          // eslint-disable-next-line no-unused-vars
          const { unit: _, ...unitPackage } = shippingUnits.find(u => u.unit === unit) || {}
          return {
            unit,
            numerator,
            denumerator,
            collapseOpen: false,
            package: unitPackage,
          }
        })
    },
    async fetchCatalogPrices() {
      const { data: { data } } = await getPriceRange({
        business_id: this.businessId,
        product_id: this.productId,
      })

      this.model.catalogs.items.forEach((item) => {
        const priceData = data.find(p => p.catalog_id === item.id)
        const price = priceData?.price[0]

        if (price) {
          item.price = {
            ...item.price,
            ...price,
          }
        }
      })
    },
    async imageUrlToFile(imageUrl, fileName, mimeType) {
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      return new File([blob], fileName, { type: mimeType || blob.type })
    },
    async createProductModel() {
      const product = this.product
      
      if (!product?.product_id) {
        return
      }

      this.model.id = product.product_id
      this.model.status = product.blibli_state
      this.model.specification = product.specification || []
      this.model.detail = {
        ...this.model.detail,
        brand_id: product.brand.name,
        long_description: product.description,
        title: product.title,
        id: product.product_id,
        status: product.blibli_state,
        category: {
          id: product.category.id,
          name: product.category.name,
        },
        category_id: [
          Number(product.category?.id),
        ].filter(Boolean),
        category_name: product.category.name,
        category_blibli_id: product.category.blibli_code,
        category_blibli_name: product.category.blibli_name,
      }
      this.model.channels = (product.channels || []).map(c => ({
        id: c.id,
        title: c.channel_title,
        code: c.channel_code, 
        deleted: false,
        exist: true,
      }))
      this.tempChannels = [].concat(this.model.channels)

      let attributes = {}
      let options = []
      // let productImages = []
      let order = 0
      const catalogLength = product.catalog_list.length
      const attrName = 'Variasi'
      const catalogItems = product.catalog_list.map((item, index) => {
        let option = {}
        
        if(catalogLength > 1 && (!item?.variant_attributes || !item?.variant_attributes?.length)) {
          const attrValue = `${attrName} ${index + 1}`
          option[attrName] = attrValue

          if(!attributes[attrName]) {
            attributes[attrName] = [attrValue]
          } else {
            if(!attributes[attrName].includes(attrValue)) {
              attributes[attrName].push(attrValue)
            }
          }

          if(!options.includes(attrName)) {
            options.push(attrName)
          }
        } else {
          item?.variant_attributes?.forEach((attribute) => {
            option[attribute.name] = attribute.value

            if(!attributes[attribute.name]) {
              attributes[attribute.name] = [attribute.value]
            } else {
              if(!attributes[attribute.name].includes(attribute.value)) {
                attributes[attribute.name].push(attribute.value)
              }
            }

            if(!options.includes(attribute.name)) {
              options.push(attribute.name)
            }
          })
        }

        let catalogImages = []
        item?.images?.forEach((image, index) => {
          const refId = index + 1
          catalogImages.push({
            refId,
            imagePath: image.path,
            thumbnailUrl: image.path,
            id: refId,
          })

          // const findDuplicate = productImages.find(img => img.url === image.path)
          // if(!findDuplicate?.id) {
          //   const findImage = product.images.find(img => img.path === image.path)
          //   productImages.push({
          //     id: refId,
          //     url: image.path,
          //     name: '',
          //     slug: null,
          //     order: !findImage?.path && order == 0
          //       ? order + 1
          //       : findImage?.path
          //       ? 0
          //       : order,
          //     tags: findImage?.path ? ["SHOW", "THUMB"] : ["SHOW"],
          //     ref_id: refId,
          //     uploading: false,
          //     deleted: false,
          //     file: null,
          //   })
          //   order++
          // }
        })

        return {
          ...item,
          id: item.id,
          factory_sku: item.seller_sku,
          images: catalogImages,
          option,
          sku: item.seller_sku,
          sku_blibli: item.blibli_sku,
          status: true,
          price: {
            ...DEFAULT_MODEL().catalogs.items[0].price,
            value: item?.regular_price || item?.pickup_point_prices?.[0]?.regularPrice,
          },
          loading: false,
        }
      })

      let orderNum = 0
      this.model.images = product.images.map((img, idx) => {
        orderNum++
        const refId = idx + 1
        return {
          id: refId,
          url: img.path,
          name: null,
          slug: null,
          order: img.main
            ? 0
            : orderNum,
          tags: img.main
            ? ['SHOW', 'THUMB']
            : ['SHOW'],
          ref_id: refId,
          image_id: refId,
        }
      })

      this.model.catalogs = {
        attributes,
        options,
        items: catalogItems.length ? catalogItems : [...DEFAULT_MODEL().catalogs.items],
        bundling: {
          type: null,
          bundles: [],
        },
      }
      
      if(this.$route.name === 'product-channel.product-synchronize-edit-blibli') {
        const { height, width, length, dimension_unit } = product.shipping_data.dimension
        const divider = dimension_unit.toLowerCase() === 'mm' ? 100 : 1
        const multiplier = ['g', 'gr', 'G', 'GR'].includes(product.shipping_data.weight_unit) ? 1000 : 1
        this.model.units = [
          {
            unit: 'PCS',
            numerator: 1,
            denumerator: 1,
            package: {
              length: +length / divider,
              length_unit: 'cm',
              width: +width / divider,
              width_unit: 'cm',
              height: +height / divider,
              height_unit: 'cm',
              weight: product.shipping_data.weight,
              weight_unit: ['g', 'gr', 'G', 'GR'].includes(product.shipping_data.weight_unit)
                ? 'g'
                : 'kg',
              shipping_weight: +product.shipping_data.shipping_weight * multiplier,
            },
            collapseOpen: false,
          },
        ]
        this.model.variant_count = product.variant_count
      }

      this.catalogsModel = product.catalog_list.map((item) => {
        let opt = {}
        item?.variant_attributes?.forEach((attribute) => {
          opt[attribute.name] = attribute.value
        })
        const temp = this.$route.name === 'product-channel.product-synchronize-edit-blibli'
          ? {
              id: item.id,
              factory_sku: null,
              images: item.images.map((image, index) => ({
                refId: index + 1,
                imagePath: image.path,
                thumbnailUrl: image.path,
                id: index + 1,
              })),
              option: opt,
              price: {
                currency: 'IDR',
                value: item?.regular_price,
                min_qty: 1,
                price_qty: 1,
                uom: 'PCS',
                price_id: null,
                formula_id: null,
                product_variables: [],
              },
              sku: item.seller_sku,
              sku_blibli: item.blibli_sku,
              status: true,
              title: item.title,
              ext_catalog_id: null,
              attributes: null,
            } : {
              ...item,
              price: DEFAULT_MODEL().catalogs.items[0].price,
            }
        return temp
      })
    },
    async saveDetail(ctx, showMessage = true) {
      if (this.saving.detail) return
      this.saving.detail = true

      this.saveOrUpdateDetailProduct(showMessage)

      this.saving.detail = false
    },
    async saveOrUpdateDetailProduct(showMessage = true) {
      // eslint-disable-next-line no-unused-vars
      const { detail, ...model } = this.model
      try {
        const { data: { data } } = await crudProduct({
          business_id: this.business.business_id,
          id: model.id || undefined,
          ...this.model.detail,
          is_new: this.isNewProduct,
        })

        this.model.id = data.id
        // this.model.status = data.status
        if (showMessage) {
          this.$message.success(this.model.id ? 'Perubahan berhasil disimpan' : 'Draft produk berhasil disimpan', 5)
        }
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    async saveImages(images, showMessage = true) {
      try {
        const undeletedImages = images.filter(image => !image.deleted)
        const product_id = this.model.id
        // Hit service if product_id available
        if (product_id) {
          await saveProductImages({
            product_id,
            business_id: this.business.business_id,
            images: undeletedImages.map(({ id, name, url, ref_id, order, tags }) => ({
              id,
              name,
              url,
              ref_id,
              order,
              tags,
            })),
            images_delete: images.filter(image => image.deleted).map(({ ref_id }) => ref_id),
            status: this.model.status,
          })
        }

        this.model.images = undeletedImages
        if (showMessage && product_id) this.$message.success('Gambar produk berhasil disimpan', 5)
      } catch (err) {
        this.$message.error('Oops, gambar produk gagal disimpan', 5)
        throw err
      }
    },
    async saveCatalogues(ctx, showMessage = true) {
      if (!this.productId) {
        // we can save the pending state, once product id is available we can save the catalogues
        return
      }

      try {
        // const isCatalogIdFilled = this.model.catalogs.items.every((item) => !!item.id || (!item.id && !item.status)) 
        const { units, catalogs: { bundling } } = this.model
        const saveCatalogItems = async () => {
          const { data: { data: { catalogs } } } = await updateCatalogsProduct({
            attributes: this.model.catalogs.attributes,
            options: this.model.catalogs.options,
            business_id: this.business.business_id,
            product_id: this.model.id,
            catalogs: this.model.catalogs.items.map(item => ({
              ...item,
              sku: item.sku?.toUpperCase(),
              factory_sku: item.factory_sku?.toUpperCase(),
              price: undefined,
              msg: undefined,
              title: item.title || [
                this.model.detail.title,
                (Object.values(item.option || {}) || []).join(', '),
              ].filter(Boolean).join(' - '),
            })),
            bundling: bundling && bundling.type && bundling.bundles.length ? {
              bundling_type: bundling.type,
              bundles: bundling.bundles.map(item => ({
                catalog_id: item.catalog_id,
                quantity: item.quantity,
                uom: item.uom || units[0].unit || null,
              })),
              product_id: this.productId,
            } : null,
            // bundling: {
            //   bundles: [],
            //   type: 'SOFT',
            //   product_id: this.model.id,
            // },
          })

          this.model.catalogs.items.forEach((item) => {
            const catalog = catalogs 
              // ? catalogs[index] 
              ? catalogs.find(c => item.id ? c.id === item.id : c.sku === item.sku?.toUpperCase())
              : null

            if (catalog) {
              item.id = catalog.id
            }
          })
        }

        // const saveCatalogPromise = saveCatalogItems()
        // if catalog id is not filled, then wait to save catalog items first, otherwise we can call savePrice parallelly
        // if (!isCatalogIdFilled) await saveCatalogPromise

        // if (!isCatalogIdFilled) await saveCatalogItems()

        this.$nextTick(async () => {
          await saveCatalogItems()
          await this.savePrice()
        })
      } catch (err) {
        if (showMessage) {
          this.$message.error('Oops, catalog produk gagal disimpan', 5)
          if(err?.response?.data?.message) this.$message.error(err?.response?.data?.message, 5)
        }
        throw err
      }
    },
    async savePrice() {
      const { data: response } = await saveProductPrice({
        business_id: this.business.business_id,
        product_id: this.model.id,
        catalog_price: this.model.catalogs.items
          .filter(i => i.id)
          .map(item => ({
            catalog_id: item.id,
            price: [item.price],
          })),
      })

      const catalogPrices = response?.data?.catalog_price || []

      catalogPrices.forEach(catalogPrice => {
        const { price, catalog_id } = catalogPrice
        const catalog = this.model.catalogs.items.find(c => c.id === catalog_id)

        const mainPrice = price[0]

        catalog.price.price_id = mainPrice.price_id
      })
    },
    async saveWeightAndShipping(ctx, showMessage = true) {
      try {
        await Promise.all([
          this.saveOrUpdateDetailProduct(showMessage),
          saveProductShipping({
            business_id: this.business.business_id,
            product_id: this.model.id,
            product_units: this.model.units.map((unit) => ({ unit: unit.unit, ...unit.package })),
            product_units_delete: [],
            status: this.model.status,
          }),
          saveProductInventory({
            business_id: this.business.business_id,
            product_id: this.model.id,
            units: this.model.units.map(({ unit, denumerator, numerator }) => ({ unit, numerator, denumerator })),
            units_delete: [],
          }),
        ])
      } catch (err) {
        if (showMessage) this.$message.error('Oops, weight dan shipping produk gagal disimpan', 5)
        throw err
      }
    },
    async saveWarehousesAndInventories(ctx, showMessage = true, warehouses) {
      try {
        await addWarehouse({
          business_id: this.businessId,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            warehouses: warehouses || this.model.inventories,
          },
        })
      } catch (err) {
        if (showMessage) this.$message.error('Oops, inventory produk gagal disimpan', 5)
        throw err
      }
    },
    async saveChannels(showMessage = true) {
      let channels = []
      let channels_delete = []
      let count = 0
      this.model.channels.forEach(channel => {
        if(this.$route.name === 'product.create') {
          if(!channel.deleted && !this.channelList.includes(channel.code)) {
            channels.push(channel.id)
          } else {
            channels_delete.push(channel.id)
            if(this.channelList.includes(channel.code)) count++
          }
        } else {
          const findChannel = this.tempChannels.length
            ? this.tempChannels.find(temp => temp?.id == channel.id) || {}
            : {}
          if(!findChannel.id && !channel.deleted && !this.channelList.includes(channel.code)) {
            channels.push(channel.id)
          } else if(findChannel.id && !channel.deleted && !this.channelList.includes(channel.code)) {
            channels.push(channel.id)
          } else if(findChannel.id && !channel.deleted && this.channelList.includes(channel.code)) {
            channels.push(channel.id)
          } else {
            channels_delete.push(channel.id)
            if(this.channelList.includes(channel.code)) count++
          }
          this.existingChannels = channels
        }
      })

      this.$store.commit('marketplace/SET_EXISTING_CHANNELS', channels)
      this.$store.commit('marketplace/SET_COUNT', count)

      if (!channels.length && !channels_delete.length) return 

      try {
        await addProductChannel({
          business_id: this.businessId,
          product_id: this.model.id,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            channels,
            channels_delete,
          },
        })
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    getFirstError() {
      const errors = this.$refs.validationObserver.errors
      for (const key in errors) {
        if (errors[key].length) return errors[key][0]
      }
      return null
    },
    async saveProducts() {
      this.submitting = true
      const valid = await this.$refs.validationObserver.validate()

      if (!valid) {
        this.submitting = false
        const firstErrorElement = document.querySelector('.ant-form-explain')
        if (firstErrorElement) {
          window.scrollTo({
            behavior: 'smooth',
            top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
          })
        }
        return this.$message.error("Beberapa bidang tidak valid, silahkan isi terlebih dahulu") // Beberapa bidang masih kosong
      } else {
        const existingSKU = this.existSKU.filter(sku => sku)
        if(existingSKU.length) {
          this.submitting = false
          return this.$message.error("Beberapa SKU sudah terdaftar, silahkan ubah terlebih dahulu")
        }
      }

      if(this.$route.name === 'product.edit' && this.tempChannels.length) {
        const channels = this.tempChannels.map(channel => channel.title).join(', ')
        this.$notification.info({
          message: 'Informasi',
          description: `Perubahan data pada produk utama tidak akan terefleksi pada produk channel ${channels}`,
        })
      }

      /** @NOTES : MN-231 */
      const isEdit = this.$route.name === 'product.edit'
      const parseWarehouses = [...this.model.inventories].map(item => ({
        ...item,
        catalogs: item.catalogs.map(cat => ({ ...cat, id: ('' + cat.id).startsWith('TEMP_CATALOG_ID_') ? null : cat.id }) ),
      }))

      try {
        if (this.$route.meta.title === 'Powerbiz - Add Product Redeem' || this.$route.meta.title === 'Powerbiz - Edit Product Redeem') {
          const today = new Date(this.$moment().format("YYYY-MM-DD")).getTime();
          const from = new Date(this.$moment(this.model.detail.start_period).format("YYYY-MM-DD")).getTime();
          const to = new Date(this.$moment(this.model.detail.end_period).format("YYYY-MM-DD")).getTime();
          let inventories = []

          this.model.inventories.forEach(inventory => {
            inventory.catalogs.forEach(catalog => {
              inventories.push({
                catalog_id: catalog.id,
                business_id: this.businessId,
                product_id: this.model.id,
                warehouse_id: inventory.id,
                channel_id: this.listChannels,
                uom: catalog.uom,
                pre_order_status: catalog.pre_order_status,
              })
            })
          })

          const data = {
            unit: {
              bussiness_id: this.businessId,
              product_id: this.model.id,
              units: this.model.units.map(item => {
                return {
                  unit: item.unit,
                  numerator: item.numerator,
                  denumerator: item.denumerator,
                }
              }),
            },
            inventory: inventories,
            price: {
              channel_id: this.listChannels,
              workspace_id: this.$route.query.workspace_id,
              product_id: this.model.id,
              business_id: this.businessId,
              catalog_price: this.model.catalogs.items?.map(item => {
                const { price_qty, min_qty, value, uom } = item.price
                const temp = {
                  catalog_id: item.id,
                  price: [{
                    currency: 'Koin',
                    price_qty,
                    min_qty,
                    value,
                    uom,
                    price_area_id: '0',
                    customer_category_id: '',
                    effective_date: this.$moment(this.model.detail.start_period),
                  }],
                }
                return temp
              }) || null,
            },
            product: {
              title : this.model.detail.title,
              category : this.model.detail.category_id[0],
              channel_id: this.listChannels,
              business_id: this.businessId,
              short_description : this.model.detail.short_description,
              long_description : this.model.detail.long_description,
              base_unit : this.model.units[0]?.unit,
              is_bundling : this.model.catalogs.bundling?.bundles.length > 0 ? 1 : 0,
              bundlingType : this.model.catalogs.bundling?.type,
              options : this.model.catalogs.options || null,
              attributes : this.model.catalogs.attributes || [],
              status : today >= from && today <= to ? "ACTIVE" : "NOT_ACTIVE",
              is_physical : this.model.detail.physical ? 1 : 0,
              is_new : this.model.detail.condition?.toLowerCase() === 'new' ? 1 : 0,
              is_imported : this.model.detail.imported ? 1 : 0,
              master_product_id : this.model.id,
              video_url : "",
              brand_id : this.model.detail.brand_id,
              start_period : this.$moment(this.model.detail.start_period),
              end_period : this.$moment(this.model.detail.end_period),
              catalogs : this.model.catalogs.items?.map(item => {
                const temp = {
                      sku : item.sku?.toUpperCase(),
                      brand_sku : "",
                      title : item.title,
                      options : item.option, // ? this.separateObject(item.option) : [],
                      status : today >= from && today <= to ? "ACTIVE" : "NOT_ACTIVE",
                      is_active : today >= from && today <= to ? true : false,
                      short_description : "",
                      long_description : "",
                      master_catalog_id : item.id,
                      images: item.images || [],
                  }
                  return temp
              }) || null,
              images: this.model.images.map(item => {
                const temp = {
                  url: item.url,
                  name: item.name,
                  slug: item.slug,
                  order: item.order,
                  tags: item.tags,
                  ref_id: item.ref_id,
                }
                return temp
              }) || [],
              warehouse_ids: this.model.inventories.map(item => { return item.id }),
            },
            shippment: {
              business_id: this.businessId,
              product_id: this.model.id,
              shipping_units: this.model.units.map(item => {
                return {
                  unit: item.unit,
                  ...item.package,
                }
              }),
            },
          }
          const response = await CREATEPRODUCTREDEEM(
            { 
              // id: this.$route.params.id,
              business_id: this.$route.query.business_id || this.$store.state.user.businessList[0].business_id,
              data,
            },
          )

          if (this.$route.meta.title === 'Powerbiz - Edit Product Redeem') {
            if (response.data.status === 'SUCCESS') {
              this.$message.success('Produk berhasil di Simpan!', 5)
              this.$refs.validationObserver.reset()

              if (this.model.status === 'DRAFT') {
                this.model.status = response.data.data.status
                await deleteProduct(this.model.id)
              }
      
              this.$nextTick(() => setTimeout(() => {
                this.submitting = false
                const { workspace_id, business_id, redirect_url } = this.$route.query
                this.$router.push({ path: redirect_url, query: { workspace_id, business_id } })
              }, 150))
            }
          }
        }
        
        if (this.$route.meta.title !== 'Powerbiz - Edit Product Redeem') {
          await Promise.all([
            // this.saveOrUpdateDetailProduct(false),
            this.saveCatalogues(null, false),
            this.saveWeightAndShipping(null, false),
          ])

          await this.saveWarehousesAndInventories(null, false, parseWarehouses)

          if (this.model.status === 'DRAFT') {
            await productPublish({
              id: this.model.id,
              business_id: this.businessId,
            })
            this.model.status = 'ACTIVE'
          }

          this.$message.success('Produk berhasil di Simpan!', 5)
          this.$refs.validationObserver.reset()

          this.$nextTick(() => setTimeout(() => {
            this.submitting = false
            // this.businessId
            const { workspace_id, business_id, redirect_url } = this.$route.query
            this.$router.push({ path: redirect_url || '/product', query: { workspace_id, business_id } })
              .then(async() => {
                if(+this.$store.state.marketplace.count > 0) {
                  const brand_id = (
                    this.brands.length && this.model.detail.brand_id
                      ? this.brands.find(brand => brand.id === this.model.detail.brand_id)?.title
                      : this.model.detail.brand_id
                  ) || ''

                  if(isEdit) {
                    this.$store.commit('marketplace/SET_ADD_CHANNEL', true)
                    this.model.images = await Promise.all(this.model.images.map(async (image, index) => {
                      if(!image.file) {
                        await this.imageUrlToFile(image.url, `image-${this.model.id}-${index}.jpg`, 'image/jpeg')
                          .then(imageFile => image.file = imageFile)
                          .catch(() => image.file = null)
                      }
                      return image
                    }))
                    this.model.channels = this.model.channels.filter(channel => !this.existingChannels.includes(channel.id))
                  }

                  await this.$store.commit('marketplace/SET_MODEL', {
                    ...this.model,
                    brand_id,
                    business_id: this.businessId,
                    user_id: this.$store.state.user.id,
                    attributes: this.channelAttribute,
                  })
                }
              })
          }, 150))
        }
      } catch (err) {
        this.submitting = false
        const { message } = err?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        throw err
      }
    },

    separateObject(obj) {
      const res = [];
      const keys = Object.keys(obj);
      keys.forEach(key => {
          res.push({
            [key]: obj[key],
          });
      });
      return res;
    },

    beforeRouteLeave(to, from, next) {
      const isDraftProduct = this.model.status === 'DRAFT'
      const dirty = this.$refs.validationObserver.flags.dirty

      if (!this.permission.includes('WRITE')) return next()

      if (!dirty && !(this.model.id && isDraftProduct)) return next()

      this.$confirm({
        title: 'Data Belum Tersimpan',
        icon: () => null,
        content: isDraftProduct 
          ? 'Apakah Anda yakin akan menutup halaman ini, Data yang sudah diisi tidak akan disimpan' 
          : 'Perubahan akan hilang saat anda keluar dari halaman ini',
        okText: isDraftProduct ? 'Ya, Keluar dari halaman ini' : 'Ya, Keluar dari halaman ini',
        class: 'leaving-product-confirm',
        onOk: () => {
          this.deleteDraftProduct()
          next()
        },
      })

      next(false)
    },
    deleteDraftProduct() {
      const isDraft = this.model.status === 'DRAFT'
    },
    onCancel() {
      const redirectUrl = this.$route.query.redirect_url

      if (redirectUrl) {
        return this.$router.push(redirectUrl)
      }

      return this.$router.push({
        name: 'product.master',
        query: {
          workspace_id: this.$route.query.workspace_id,
          business_id: this.$route.query.business_id,
        },
      })
    },
    findChildren(item, matched) {
      if (item.id === matched) {
        return [item.id]
      } else if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
          const result = this.findChildren(item.children[i], matched)
          if (result) {
            return [item.id, ...result]
          }
        }
      }
      return null;
    },
    findParent(array, matched) {
      for (let i = 0; i < array.length; i++) {
        const result = this.findChildren(array[i], matched)
        if (result) {
          return result
        }
      }
      return [];
    },
    findCategory(lists, matched) {
      let length = lists.length
      let result = null
      for (let i = 0; i < length; i++) {
        if (lists[i].id == matched) {
          const { id, name } = lists[i]
          return { id, name }
        } else if (lists[i].children) {
          result = this.findCategory(lists[i].children, matched)
          if (result.id) return result
        }
      }
      return {}
    },
    addChannelAttribute(value) {
      this.channelAttribute = value
    },
    async fetchChannelWarehouse() {
      const bodyVal = {
        business_id: this.businessId,
        channel_code: this.channelCode,
        channel_id: this.channelId,
        connected: false,
      }
      await getChannelWarehouse(bodyVal)
        .then(({ data }) => {
          let count = 0
          const response = this.channelCode.includes('tiktok')
            ? data.data.data
            : data
          this.model.inventories.forEach(item => {
            const findWarehouse = response.find(el => el.warehouse_master_id == item.id)
            if(findWarehouse?.warehouse_channel_id) {
              this.channelWarehouseId = findWarehouse.warehouse_channel_id
              if(this.channelCode.includes('shopee')) {
                this.fetchChannelImageDraft()
              } else {
                this.fetchProductDraft()
              }
              count++
            } else {
              this.$message.error(`Warehouse ${item.id} belum terintegrasi dengan tiktok`, 5)
            }
          })
          if(!count) this.saveProductMaster()
        })
        .catch(err => {
          if(err?.response?.data?.message) {
            this.$message.error(err?.response?.data?.message, 5)
            this.saveProductMaster()
          }
        })
    },
    async fetchProductDraft() {
      const bodyVal = {
        business_id: this.businessId,
        channel_code: this.channelCode,
        channel_id: this.channelId,
        user_id: this.$store.state.user.id,
        data: {
          shop_id: this.channelCode.includes('tiktok')
            ? 'c9ee7774-8494-45e3-8398-bf3e528be375'
            : undefined,
        },
      }
      await channelProductDraft(bodyVal)
        .then(response => {
          this.channelProductId = response.data.data.product_id
          this.fetchChannelImageDraft()
        })
        .catch(async (err) => {
          if(err.response.data.message.includes('exists')) {
            const id = err.response.data.message.replace('Draft ', '').replace(' exists', '')
            await deleteChannelDraft({
              business_id: this.businessId,
              channel_code: this.channelCode,
              channel_id: this.channelId, 
              user_id: this.$store.state.user.id, 
              product_id: id, 
            })
            .then(() => this.fetchProductDraft())
          }
        })
    },
    async fetchChannelImageDraft() {
      let requests = []
      this.model.images.forEach(item => {
        const bodyVal = {
          ...this.channelBody,
          product_id: this.channelBody.channel_code.includes('shopee')
            ? ''
            : this.channelProductId,
          file: this.channelBody.channel_code.includes('tiktok')
            ? item.thumbnail
            : item.file,
          image_type: 1,
        }
        requests.push(uploadChannelImage(bodyVal))
      })

      await Promise.all(requests)
        .then(async (response) => {
          await this.getShopeeImageEventProcess(response)
          // const request_id = this.channelBody.channel_code.includes('shopee')
          //   ? data.request_id
          //   : data.data.request_id
          // if(this.channelBody.channel_code.includes('shopee')) {
          //   this.getShopeeImageEventProcess(request_id)
          // } else {
          //   this.getImageEventProcess(request_id)
          // }
        })
        .catch(err => {
          this.$store.commit('products/SET_REQUEST', false)
          if(err?.response?.data?.message) {
            this.$message.error(err?.response?.data?.message, 5)
            // this.saveProductMaster()
          }
        })
    },
    getImageEventProcess: debounce(async function(request_id) {
      const bodyVal = {
        business_id: this.businessId,
        channel_code: this.channelCode,
        channel_id: this.channelId,
        request_id,
      }
      await channelEventProcess(bodyVal)
        .then(({ data }) => {
          const status = this.channelCode.includes('tiktok')
            ? data.status
            : data.data.status
          if (status === "FAILED") {
            this.$message.error('Produk Channel gagal disimpan', 5)
            this.saveProductMaster()
          } else if (status === "ON_PROGRESS") {
            this.getImageEventProcess(request_id)
          } else if (status === "FINISHED") {
            this.getChannelDraftImage(request_id)
          }
        })
        .catch(err => {
          if(err?.response?.data?.message) {
            this.$message.error(err?.response?.data?.message, 5)
            this.saveProductMaster()
          }
        })
    }, 2000),
    getShopeeImageEventProcess: debounce(async function(response) {
      let requests = []
      let request_ids = []
      response.forEach(res => {
        const request_id = this.channelBody.channel_code.includes('shopee')
          ? res.data.request_id
          : res.data.data.request_id
        request_ids.push(request_id)
        requests.push(channelEventProcess({
          ...this.channelBody,
          user_id: undefined,
          request_id,
          // params: {
          //   request_id,
          //   user_id: this.channelBody.user_id,
          // },
        }))
      })

      await Promise.all(requests)
        .then(async (response) => {
          if(response.every(res => res.data.status === 'FAILED')) {
            this.$message.error('Produk Channel gagal disimpan', 5)
            this.$store.commit('products/SET_REQUEST', false)
          } else if(response.every(res => res.data.status === 'ON_PROGRESS')) {
            await this.getShopeeImageEventProcess(response)
          } else if(response.every(res => res.data.status === 'FINISHED')) {
            await this.getShopeeDraftImage(request_ids)
          }
        })
        .catch(err => {
          this.$store.commit('products/SET_REQUEST', false)
          if(err?.response?.data?.message) {
            this.$message.error(err?.response?.data?.message, 5)
            // this.saveProductMaster()
          }
        })
    }, 2000),
    async getChannelDraftImage(request_id) {
      const bodyVal = {
        business_id: this.businessId,
        channel_code: this.channelCode,
        channel_id: this.channelId,
        user_id: this.$store.state.user.id,
        product_id: this.channelProductId,
        params: {
          request_id,
        },
      }
      await channelImageDraft(bodyVal)
        .finally(() => {
          this.saveChannelProduct()
        })
    },
    async getShopeeDraftImage(request_ids) {
      let requests = []
      request_ids.forEach(request_id => {
        requests.push(shopeeImageAttribute({
          ...this.channelBody,
          user_id: undefined,
          params: {
            request_id,
            user_id: this.channelBody.user_id,
          },
        }))
      })

      await Promise.all(requests)
        .then(async (response) => {
          const images = response.map(res => res.data[0].channel_image_id)
          await this.saveProductChannel(images)
          // const images = response.map(res => res.data[0].channel_image_id)
          // dispatch('SAVE_PRODUCT_CHANNEL', {
          //   images,
          //   data: payload.data,
          //   model: payload.model,
          // })
        })
        .catch(err => {
          this.$store.commit('products/SET_REQUEST', false)
          if(err?.response?.data?.message) {
            this.$message.error(err?.response?.data?.message, 5)
            // this.saveProductMaster()
          }
        })
    },
    async saveProductChannel(images) {
      let tier_variation = []
      let model = []

      this.model.catalogs.items.forEach(catalog => {
        let i = 0
        let tier_index = []
        const imgIndex = this.model.images.findIndex(img => img.ref_id === catalog.images[0].refId)
        for(const attribute in (catalog.option || {})) {
          if(i < 2) {
            const attrIndex = this.model.catalogs.options.findIndex(attr => attr === attribute)
            const varIndex = this.model.catalogs.attributes[attribute].findIndex(option => option === catalog.option[attribute])
            tier_index.push(varIndex)
            tier_variation[attrIndex] = {
              name: attribute,
              option_list: tier_variation[attrIndex]?.option_list || [],
            }
            tier_variation[attrIndex].option_list[varIndex] = {
              ...tier_variation[attrIndex].option_list[varIndex],
              option: catalog.option[attribute],
              image: attrIndex == 0
                ? { image_id: images[imgIndex] }
                : undefined,
            }
          }
          
          i++
        }

        model.push({
          tier_index,
          normal_stock: 1,
          original_price: catalog.price.value,
          model_sku: catalog.sku?.toUpperCase(),
          seller_stock: [{
            stock: 0,
          }],
        })
      })
      
      const attribute = this.channelAttribute.find(attr => {
        return attr.id == this.channelBody.channel_id && attr.code == this.channelBody.channel_code
      })
      const weight = ['g', 'gr'].includes(this.model.units?.[0]?.package?.weight_unit?.toLowerCase())
        ? this.model.units?.[0]?.package?.weight / 1000
        : this.model.units?.[0]?.package?.weight

      const data = {
        detail: {
          title: this.model.detail.title,
          description: this.model.detail.long_description,
          item_status: 'NORMAL',
          original_price: this.model.catalogs.items[0].price.value,
          weight,
          image: {
            image_id_list: images,
          },
          sku: this.model.catalogs.items[0].sku?.toUpperCase(),
          condition: this.model.detail.condition,
          dimension: {
            package_height: this.model.units?.[0]?.package?.height,
            package_length: this.model.units?.[0]?.package?.length,
            package_width: this.model.units?.[0]?.package?.width,
          },
          pre_order: {
            is_pre_order: false,
            days_to_ship: 3,
          },
          logistic_info: [{
            logistic_id: 8003,
            enabled: true,
            is_free: false,
          }],
          category_id: attribute.category[3]
            || attribute.category[2]
            || attribute.category[1]
            || attribute.category[0],
          seller_stock: [{
            stock: 0,
          }],
          attribute_lists: attribute.attribute,
          catalog: {
            tier_variation,
            model,
          },
        },
      }
      
      const bodyVal = {
        ...this.channelBody,
        product_id: this.channelProductId,
        data,
      }
      await addChannelProduct(bodyVal)
        .then(async (response) => {
          await this.getChannelSaveProcess(response.data.data.request_id)
        })
        .catch(err => {
          this.$store.commit('products/SET_REQUEST', false)
          if(err?.response?.data?.message) {
            this.$message.error(err?.response?.data?.message, 5)
            // this.saveProductMaster()
          }
        })
    },
    getChannelSaveProcess: debounce(async function(request_id) {
      const bodyVal = {
        ...this.channelBody,
        user_id: undefined,
        request_id,
      }
      await channelEventProcess(bodyVal)
        .then(async ({ data }) => {
          const status = this.channelCode.includes('tokopedia')
            ? data.data.status
            : data.status
          if (status === "FAILED") {
            this.$message.error('Produk Channel gagal disimpan', 5)
            this.$store.commit('products/SET_REQUEST', false)
            // this.saveProductMaster()
          } else if (status === "ON_PROGRESS") {
            await this.getChannelSaveProcess(request_id)
          } else if (status === "FINISHED") {
            // this.saveProductMaster()
            this.$store.commit('products/SET_REQUEST', false)
          }
        })
        .catch(err => {
          this.$store.commit('products/SET_REQUEST', false)
          if(err?.response?.data?.message) {
            this.$message.error(err?.response?.data?.message, 5)
            // this.saveProductMaster()
          }
        })
    }, 2000),
  },
})
</script>

<style scoped lang="scss">
.footer {
  bottom: 0;
  background-color: #F8F9FA;
  // border-top: 1px solid #efefef;
  z-index: 3;
}

ol {
  list-style: none;
  top: 130px;

  > li {
    position: relative;
    padding: .5rem 2rem;

    a {
      color: #999;
    }

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 20rem;
      background: #999;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      content: '';
      width: 1px;
      background: #999;
      top: 50%;
      left: 4.5px;
      transform: translateY(-50%);
      height: 100%;
      z-index: -1;
    }

    &:first-child::after {
      height: 50%;
      transform: none;
    }

    &:last-child::after {
      height: 50%;
      transform: none;
      top: 0;
    }

    &.active {
      a {
        color: var(--color-black);
        font-weight: 500;
      }

      &::before {
        background: var(--color-accent);
      }
    }
  }
}
</style>

<style lang="scss">
[data-kit-theme="default"] {
  .leaving-product-confirm {
    text-align: center;

    .ant-modal-confirm-title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .ant-modal-confirm-content {
      color: #999;
    }

    .ant-modal-confirm-btns {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      button {
        width: 100%;
        margin: 15px 0 0 0 !important;
        height: 45px;
        border: none;
      }
    }
  }
}
</style>
