import { render, staticRenderFns } from "./DetailCatalogMultiVariant.vue?vue&type=template&id=725d8891&scoped=true&"
import script from "./DetailCatalogMultiVariant.vue?vue&type=script&lang=js&"
export * from "./DetailCatalogMultiVariant.vue?vue&type=script&lang=js&"
import style0 from "./DetailCatalogMultiVariant.vue?vue&type=style&index=0&id=725d8891&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725d8891",
  null
  
)

export default component.exports