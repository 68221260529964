import apiClient from '@/services/axios'
import { types as MutationTypes } from './mutations'

export const GETPRODUCT = async (
  { commit },
  { business_id, channel, channelId, params, signal, isExport },
) => {
  let data = [],
      props = { all: 0, total_row: 0 }

  try {
    commit(MutationTypes.SET_PRODUCT_CHANNEL, {
      loading: true,
    })

    const headers = {
      'Business-Id': business_id,
    }
    
    const isDistributor = channel === 'distributor'
    const isBlibli = channel.includes('blibli')
    const { data: response } = await apiClient({
      method: 'get',
      url: `/channel/${channel}/product/query/${isDistributor ? 'powerbiz/': ''}${channelId}`,
      params,
      headers,
      signal,
    })

    const { data: res, ...obj } = response || {}
    obj.all = obj.active + obj.notActive // ???
    if (isDistributor && res?.length && !isExport) {
      await apiClient({
        method: 'get',
        url: `/channel/distributor/price/query/v2/powerbiz/${channelId || ''}`,
        params: {
          product_id: res.map(f => f.id || f.product_id).join(','),
          effective_date: new Date().toISOString(),
        },
        headers,
      })
      .then(result => {
        const resData = result?.data?.data
        data = resData.length ? res.map(item => {
          const range_price = resData.find(f => f.product_id === item.id || f.product_id === item.product_id)?.range_price
          if (range_price) {
            return {
              ...item,
              range_price,
            }
          }
          return item
        }) : res
        props = obj
      })
      .catch(() => {
        data = res || []
        props = obj || {}
      })
    } else if (['tiktok', 'tiktok_id'].includes(channel)) {
      data = res?.map(item => {
        return {
          ...item,
          catalogs: item.catalogs?.map(catalog => {
            const tempChannelCatalogId = catalog.catalog_id
            const tempMasterCatalogId = catalog.channel_catalog_id
            return {
              ...catalog,
              catalog_id: tempMasterCatalogId,
              channel_catalog_id: tempChannelCatalogId,
            }
          }),
        }
      }) || []
      props = obj || {}
    } else {
      data = isBlibli
        ? res.map(item => ({
            product_id: item.product_id,
            channel_product_id: item.product_id,
            title: item.product_title,
            status: item.mapping_state,
            product_status: item.state,
            channel_product_status: 'ARCHIVE',
            catalogs: item.catalogs.map(catalog => ({
              catalog_id: catalog.id,
              channel_catalog_id: catalog.id,
              channel_product_id: item.product_id,
              master_product_id: catalog.master_product_id,
              master_catalog_id: catalog.master_catalog_id,
              status: catalog.status,
              title: catalog.title,
              sku: catalog.seller_sku,
              factory_sku: null,
              attributes: {},
              attribute_details: null,
              price: null,
              images: catalog.image_path,
              selections: null,
              created_at: null,
              warehouse_list: null,
            })),
            price: {
              currency: item.price.normal.min.currency,
              min: item.price.normal.min.value,
              max: item.price.normal.max.value,
            },
            category: {
              name: item.category.blibli_name,
            },
            variant_count: item.variant_count,
            images: [{
              url_list: [item.image_path],
              thumb_url_list: [item.image_path],
            }],
            import_status: null,
            reason_import_status: null,
          }))
        : res || []
        props = obj || {}
    }
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_PRODUCT_CHANNEL, {
      loading: false,
    })
    if (isExport) return Promise.resolve(data)
    commit(MutationTypes.SET_PRODUCT_CHANNEL, {
      loading: false,
      products: data,
      props,
    })
  }
}

export const GETPRODUCTNOTCOMMITSTATE = async (
  { commit, state },
  { business_id, channel, channelId, params, signal, productId },
) => {
  let data = [],
      props = { all: 0, total_row: 0 }

  try {
    commit(MutationTypes.SET_PRODUCT_CHANNEL, {
      loading: true,
    })

    const headers = {
      'Business-Id': business_id,
    }
    
    const isDistributor = channel === 'distributor'
    const isBlibli = channel.includes('blibli')
    const { data: response } = await apiClient({
      method: 'get',
      url: `/channel/${channel}/product/query/${isDistributor ? 'powerbiz/': ''}${channelId}`,
      params,
      headers,
      signal,
    })

    const { data: res, ...obj } = response || {}
    obj.all = obj.active + obj.notActive // ???

    if (isDistributor && res?.length && !isExport) {
      await apiClient({
        method: 'get',
        url: `/channel/distributor/price/query/v2/powerbiz/${channelId || ''}`,
        params: {
          product_id: res.map(f => f.id || f.product_id).join(','),
          effective_date: new Date().toISOString(),
        },
        headers,
      })
      .then(result => {
        const resData = result?.data?.data
        data = resData.length ? res.map(item => {
          const range_price = resData.find(f => f.product_id === item.id || f.product_id === item.product_id)?.range_price
          if (range_price) {
            return {
              ...item,
              range_price,
            }
          }
          return item
        }) : res
        props = obj
      })
      .catch(() => {
        data = res || []
        props = obj || {}
      })
    } else if (['tiktok', 'tiktok_id'].includes(channel)) {
      data = res?.map(item => {
        return {
          ...item,
          catalogs: item.catalogs?.map(catalog => {
            const tempChannelCatalogId = catalog.catalog_id
            const tempMasterCatalogId = catalog.channel_catalog_id
            return {
              ...catalog,
              catalog_id: tempMasterCatalogId,
              channel_catalog_id: tempChannelCatalogId,
            }
          }),
        }
      }) || []
      props = obj || {}
    } else {
      data = isBlibli
        ? res.map(item => ({
            product_id: item.product_id,
            channel_product_id: item.product_id,
            title: item.product_title,
            status: item.mapping_state,
            product_status: item.state,
            channel_product_status: 'ARCHIVE',
            catalogs: item.catalogs.map(catalog => ({
              ...catalog,
              catalog_id: catalog.id,
              channel_catalog_id: catalog.id,
              channel_product_id: item.product_id,
              master_product_id: catalog.master_product_id,
              master_catalog_id: catalog.master_catalog_id,
              status: catalog.status,
              title: catalog.title,
              sku: catalog.seller_sku,
              factory_sku: null,
              attributes: {},
              attribute_details: null,
              price: null,
              images: catalog.image_path,
              selections: null,
              created_at: null,
              warehouse_list: null,
            })),
            price: {
              currency: item.price.normal.min.currency,
              min: item.price.normal.min.value,
              max: item.price.normal.max.value,
            },
            category: {
              name: item.category.blibli_name,
            },
            variant_count: item.variant_count,
            images: [{
              url_list: [item.image_path],
              thumb_url_list: [item.image_path],
            }],
            import_status: null,
            reason_import_status: null,
          }))
        : res || []
        props = obj || {}
    }
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  } finally {

    const findProductOnResData = data.find(obj => obj.product_id === productId)
    const findProductIndexOnStateData = state.products.findIndex(obj => obj.product_id === productId)
    const newData = state.products
    newData[findProductIndexOnStateData] = findProductOnResData
    commit(MutationTypes.SET_PRODUCT_CHANNEL, {
      loading: false,
      products: newData,
    })
  }
}
