<script>
import { computed, defineComponent, ref, watch } from 'vue'
import FormItem from '@/components/Input/FormItem.vue'
import Price from './Price/index.vue'
import BundleProductInput from '../BundleProductInput/index.vue'

export default defineComponent({
  components: {
    FormItem,
    Price,
    BundleProductInput,
},
  props: {
    business: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value)
    watch(() => props.value, (value) => {
      model.value = value
    }, { immediate: true })
    const item = computed({
      get() {
        return model.value.catalogs.items[0] || {}
      },
      set(value) {
        model.value.catalogs.items[0] = value
      },
    })

    const checkSKU = event => {
      emit('checksku', { sku: event.target.value.toUpperCase(), id: 0 })
    }

    return {
      model,
      item,
      checkSKU,
    }
  },
})
</script>

<template>
  <div>
    <div>
      <div>
        <a-row type="flex" :gutter="16">
          <a-col :lg="{ span: 8 }">
            <FormItem rules="required|no_space" label="SKU" :class="{ existing: !!item.msg }">
              <a-spin :spinning="item.loading">
                <a-input 
                  v-model="item.sku"
                  :disabled="!!$route.query.id || item.loading"
                  class="h-48px"
                  style="text-transform: uppercase"
                  @keyup="e => e.target.value = e.target.value.toUpperCase()"
                  @change="() => item.msg = ''"
                  @blur="checkSKU"
                />
              </a-spin>
              <span v-if="item.msg" style="color: red">{{ item.msg }}</span>
            </FormItem>
          </a-col>
          <a-col :lg="{ span: 8 }">
            <FormItem rules="" :label="$t('product.factory_sku')">
              <a-input 
                v-model="item.factory_sku"
                class="h-48px"
                style="text-transform: uppercase"
                @keyup="e => e.target.value = e.target.value.toUpperCase()"
              />
            </FormItem>
          </a-col>
          <a-col :lg="{ span: 8 }">
            <FormItem rules="" :label="$t('product.barcode')">
              <a-input 
                v-model="item.barcode"
                class="h-48px"
                style="text-transform: uppercase"
                @keyup="e => e.target.value = e.target.value.toUpperCase()"
              />
            </FormItem>
          </a-col>
        </a-row>
      </div>

      <Price
        v-model="item.price"
        :business="business"
        :units="model.units"
        :vat-type="model.detail.vat_type" 
        class="mb-5"
      />
    </div>

    <BundleProductInput
      v-model="model.catalogs.bundling"
      :product="value"
      :business="business"
      class="mb-3"
    />
  </div>
</template>
