<template>
  <div class="w-100">
    <a-spin :spinning="loadingPrint" tip="Loading...">
      <div class="header-print">
        <div class="d-flex mx-1 my-2 justify-content-center" style="gap: 10px;">
          <div class="d-flex">
            <a-checkbox
              :checked="isCheckedAllActionCheckbox('print')"
              :indeterminate="isIntermediateActionCheckbox('print')"
              @change="onCheckAllActionCheckbox($event, 'print')"
            >
              {{ $t('fulfillment.print_all_details') }}
            </a-checkbox>
            <a-checkbox
              :checked="isCheckedAllActionCheckbox('showUnboxingVideoLabel')"
              :indeterminate="isIntermediateActionCheckbox('showUnboxingVideoLabel')"
              @change="onCheckAllActionCheckbox($event, 'showUnboxingVideoLabel')"
            >
              {{ $t('fulfillment.print_unboxing_video_label') }}
            </a-checkbox>
            <a-checkbox
              :checked="isCheckedAllActionCheckbox('showBarcodeAWB')"
              :indeterminate="isIntermediateActionCheckbox('showBarcodeAWB')"
              @change="onCheckAllActionCheckbox($event, 'showBarcodeAWB')"
            >
              {{ $t('fulfillment.print_all_barcode_awb') }}
            </a-checkbox>
            <a-checkbox
              :checked="isCheckedAllActionCheckbox('showBarcodeOrderNumber')"
              :indeterminate="isIntermediateActionCheckbox('showBarcodeOrderNumber')"
              @change="onCheckAllActionCheckbox($event, 'showBarcodeOrderNumber')"
            >
              {{ $t('fulfillment.print_all_barcode_no_order') }}
            </a-checkbox>
          </div>
          <!-- <div class="d-flex">
            <a-checkbox :default-checked="true" @change="setPrintFulfillmentId">
              Cetak dengan ID Pengiriman
            </a-checkbox>
          </div> -->
        </div>
        <div class="print-button" @click="onPrintAll">
          <svg
            style="margin-right: 1rem"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 36 36"
          >
            <path
              fill="currentColor"
              d="M29 9h-2V5H9v4H7a4 4 0 0 0-4 4v11h3.92v-1.91H5V13a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v9h-1.92v2H33V13a4 4 0 0 0-4-4Zm-4 0H11V7h14Z"
              class="clr-i-outline clr-i-outline-path-1"
            />
            <path
              fill="currentColor"
              d="M28 18H8a1 1 0 0 0 0 2h1v12h18V20h1a1 1 0 0 0 0-2Zm-3 12H11V20h14Z"
              class="clr-i-outline clr-i-outline-path-2"
            />
            <path
              fill="currentColor"
              d="M27 13.04h2v2h-2z"
              class="clr-i-outline clr-i-outline-path-3"
            />
            <path fill="none" d="M0 0h36v36H0z" />
          </svg>
          Print Slip
        </div>
      </div>
      <div class="page">
        <div ref="captureArea" class="capture-area">
          <table width="100%">
            <div v-for="(fulfillment, index) in listFulfillmentIds" :key="fulfillment.id">
              <List
                :ref="fulfillment.id"
                :fulfillment-id="fulfillment.id"
                :print-order="fulfillment.print"
                :is-show-unboxing-video-label="fulfillment.showUnboxingVideoLabel"
                :is-show-barcode-a-w-b="fulfillment.showBarcodeAWB"
                :is-show-barcode-order-number="fulfillment.showBarcodeOrderNumber"
              />
              <div class="px-2 mt-2 checkbox-detail-per-page">
                <a-checkbox
                  :checked="fulfillment.print"
                  @change="onCheckActionCheckboxByIndex($event, index, 'print')"
                >
                  {{ $t('fulfillment.print_details') }}
                </a-checkbox>
                <a-checkbox
                  class="ml-0 mt-2"
                  :checked="fulfillment.showUnboxingVideoLabel"
                  @change="onCheckActionCheckboxByIndex($event, index, 'showUnboxingVideoLabel')"
                >
                  {{ $t('fulfillment.print_unboxing_video_label') }}
                </a-checkbox>
                <a-checkbox
                  class="ml-0 mt-2"
                  :checked="fulfillment.showBarcodeAWB"
                  @change="onCheckActionCheckboxByIndex($event, index, 'showBarcodeAWB')"
                >
                  {{ $t('fulfillment.print_barcode_awb') }}
                </a-checkbox>
                <a-checkbox
                  class="ml-0 mt-2"
                  :checked="fulfillment.showBarcodeOrderNumber"
                  @change="onCheckActionCheckboxByIndex($event, index, 'showBarcodeOrderNumber')"
                >
                  {{ $t('fulfillment.print_barcode_no_order') }}
                </a-checkbox>
                <a-divider class="mt-1" />
              </div>
            </div>
          </table>
        </div>
        <div class="canvas-output">
          <div v-for="(image, index) in canvasLabelImage" :key="index">
            <img width="100%" style="object-fit: contain;" :src="image" :alt="'Captured Canvas ' + (index + 1)" />
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getFulfillmentDetail } from '@/api/fulfillment'
import { getWarehouseById } from '@/api/warehouse'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import VueBarcode from '@chenfengyuan/vue-barcode'
import getQueryParam from '@/utils/getQueryParam'
import QrcodeVue from 'qrcode.vue'
import List from '@/components/Fulfillment/BulkPrintLabel/list.vue'
import html2canvas from 'html2canvas';

export default {
  name: 'FulfillmentLabel',
  components: {
    ArrowIcon,
    VueBarcode,
    QrcodeVue,
    List,
  },
  mixins: [getQueryParam],
  data: function () {
    return {
      tokopedia: require('@/assets/integration/channel/tokopedia.png'),
      shopee: require('@/assets/integration/channel/Shopee.png'),
      sicepat: require('@/assets/courier/sicepat.png'),
      anteraja: require('@/assets/courier/anteraja.png'),
      jne: require('@/assets/courier/jne.png'),
      sap: require('@/assets/courier/sap.png'),
      paxel: require('@/assets/courier/paxel.png'),
      gosend: require('@/assets/courier/gosend.png'),
      grabx: require('@/assets/courier/grabx.png'),
      jntCargo: require('@/assets/courier/jnt-cargo.jpg'),
      jntExpress: require('@/assets/courier/jnt-express.png'),
      lex: require('@/assets/courier/lex.jpeg'),
      ninjax: require('@/assets/courier/ninjax.png'),
      spx: require('@/assets/courier/spx.png'),
      detail: [],
      product: [],
      destination: [],
      shipping: {},
      warehouseId: '',
      size: '',
      printDetailProduk: this.$route.params.label_product,
      printFulfillmentId: true,
      shipment: {},
      order: {},
      sender: {},
      canvasLabelImage: [],
      loadingPrint: false,
      listFulfillmentIds: [],
    }
  },
  computed: {
    barcodeSmall() {
      return {
        height: 40,
        width: 2,
        displayValue: false,
      }
    },
    barcode() {
      return {
        height: 100,
        width: 1,
        displayValue: false,
      }
    },
    topBarcode() {
      return this.shipping.awb !== null
        ? this.shipping.awb
        : this.shipping.booking_no
    },
    isExist() {
      return this.detail[0] && this.detail[0].fulfillment_number
    },
    isAwbExist() {
      return Boolean(this.shipping?.awb)
    },
  },
  watch: {
    '$route.query.fulfillment_id': {
      deep: true,
      immediate: true,
      handler(value) {
        const filterredFulfillment =  this.$route.query.fulfillment_id.split(',')
        this.listFulfillmentIds = filterredFulfillment.map((item) => {
          return  {
            id: item,
            print: true,
            showUnboxingVideoLabel: true,
            showBarcodeAWB: true,
            showBarcodeOrderNumber: true,
          }
        })
      },
    },
  },
  async mounted() {
  },
  methods: {
    isCheckedAllActionCheckbox(field) {
      if (this.listFulfillmentIds.length === this.listFulfillmentIds.filter(item => item[field]).length) {
        return true
      }
      return false
    },
    isIntermediateActionCheckbox(field) {
      if (this.listFulfillmentIds.length === this.listFulfillmentIds.filter(item => item[field]).length || this.listFulfillmentIds.filter(item => item[field]).length === 0) {
        return false
      }
      return true
    },
    onCheckAllActionCheckbox(value, field) {
      this.listFulfillmentIds = this.listFulfillmentIds.map((item) => {
        return {
          ...item,
          [field]: value.target.checked,
        }
      })
    },
    onCheckActionCheckboxByIndex(value, index, field) {
      const tempList = [...this.listFulfillmentIds]
      tempList[index] = {
        ...tempList[index],
        [field]: value.target.checked,
      }
      this.listFulfillmentIds = tempList
    },
    async onPrintAll() {
      window.print();
      // this.loadingPrint = true
      // this.captureOnMounted()
      //   .then(() => {
      //     this.loadingPrint = false
      //     setTimeout(() => {
      //       window.print();
      //     }, 500);
      //   })
      //   .catch(err => {
      //     this.loadingPrint = false
      //     this.$notification.error({
      //       message: 'Print Label Failed',
      //     })
      //   })
    },
    async captureOnMounted() {
      this.canvasLabelImage = [];
      for (const fulfillment of this.listFulfillmentIds) {
        const element = this.$refs[fulfillment.id][0].$el;
        if (element) {
          try {
            const canvas = await html2canvas(element);
            this.canvasLabelImage.push(canvas.toDataURL('image/png'));
          } catch (error) {
            console.error(`Error capturing canvas for fulfillment ${fulfillment.id}:`, error);
          }
        } else {
          console.error(`Element not found for fulfillment ${fulfillment.id}`);
        }
      }
    },
    parseISOString(dateTime) {
      if (dateTime) {
        const split = dateTime.split(/\D+/)
        const newDate = new Date(
          split[0],
          --split[1],
          split[2],
          split[3],
          split[4],
          split[5],
          split[6],
        )
        return this.$moment(newDate).format('Do MMMM YYYY')
      } else {
        return ''
      }
    },
    getCourierLogo(param) {
      if (param.toLowerCase().includes('spx')) {
        return this.spx
      } else {
        return null
      }
    },
    getMarketPlaceLogo(param) {
      switch (param.toLowerCase()) {
        case 'shopee_id':
          return this.shopee
        case 'shopee':
          return this.shopee
      }
    },
    setDetailProduk(e) {
      this.printDetailProduk = e.target.checked
    },
    setPrintFulfillmentId(e) {
      this.printFulfillmentId = e.target.checked
    },
    setPageSize(value) {
      const style = document.createElement('style')
      style.innerHTML = `@page {size: ${value}}`
      document.head.appendChild(style)
    },
    async fetchDetailFulfillmentBasedOrderId() {
      await getFulfillmentDetail({
        url: 'fulfillment',
        fulfillment_id: this.$route.query.fulfillment_id,
      })
      .then(({ data: { data: response } }) => {
        this.detail = response.fulfillments
        this.order = response.order
        this.getDataDetail()
        this.fetchWarehouseId(response.warehouse_id)
      })
      .catch(err => console.error(err))
    },
    async fetchWarehouseId(warehouseId) {
      await getWarehouseById(warehouseId)
      .then(({ data: { data: response } }) => this.shipment = response)
      .catch((err) => console.error(err))
    },
    getDataDetail() {
      const label_id = this.$route.query.fulfillment_id
      const data = this.detail.find(item => item.id === label_id)
      this.product = data.line_items
      this.destination = data.destination
      this.shipping = data.shipping
      this.sender = data.sender
    },
    back() {
      this.$router.push({
        name:
          this.$route.params.id === undefined
            ? 'fulfillment.index'
            : 'channel-fulfillment',
        query: {
          ...this.getQueryParam(this.$route.query),
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: 1.2;
}

body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  line-height: 1;
  width: 100%;
  display: block;
  margin: 0 auto;
  color: #000;
  font-size: 9pt;
  background: white;
}
img {
  -ms-interpolation-mode: bicubic;
}

table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

table.table-bordered tr td {
  border: 1px solid #000;
}

table th {
  text-align: left;
}

table td {
  vertical-align: top;
}
@page {
  size: A5;
  margin: 0;
}
.page {
  width: 100%;
  position: relative;
  /* display: inline-block; */
  vertical-align: top;
  padding: 0;
  // overflow: hidden;
  margin: auto;
  margin-top: 160px;
  height: 200mm;
  width: 140mm;
  max-width: 140mm;
}

.label-shipment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: auto;
  height: auto;
  background: #ffffff;
  border: 2px solid black;
}

.page-section {
  padding: 8pt;
  width: 50%;
  max-width: 50%;
}

.page-section:first-child {
  /* border-right: 1.5px dashed #ddd; */
}

/* Page Breaker */
.page-breaker {
  position: relative;
  text-align: center;
  page-break-before: always;
  margin-bottom: 20px;
}

.barcode {
  width: 100%;
}

.shipping-label {
  width: 43%;
  max-width: 43%;
}
.shipping-label table {
  border: 1px solid #444;
}
.shipping-label table td {
  padding: 4px 8px;
}

.label-shipment-content-border {
  border: 1px solid #000000;
}

.awb-tag-name {
  border: 1px solid #000000;
  width: 100%;
  letter-spacing: 2px;
  font-weight: bold;
}

.package-slip {
  width: 57%;
  max-width: 57%;
}
.package-slip table {
  font-size: 8pt;
}
.package-slip table td {
  border-bottom: 1px solid #e1e1e1;
  padding: 5px 0 5px;
}

.package-slip table tr:last-child td {
  border-bottom: 0;
}

tr.dashed-bottom > td {
  border-bottom: 1.5px dashed #d1d1d1;
}
tr.dashed-top > td {
  border-top: 1.5px dashed #d1d1d1;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.light-italic {
  font-style: italic;
  font-weight: 300;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.v-top {
  vertical-align: top;
}
.v-middle {
  vertical-align: middle;
}
.v-bottom {
  vertical-align: bottom;
}

.w-25 {
  width: 25%;
  min-width: 25%;
  max-width: 25%;
}
.w-50 {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.fs-6 {
  font-size: 6pt;
}
.fs-7 {
  font-size: 7pt;
}
.fs-8 {
  font-size: 8pt;
}
.fs-9 {
  font-size: 9pt;
}
.fs-10 {
  font-size: 10pt;
}
.fs-11 {
  font-size: 11pt;
}
.fs-12 {
  font-size: 12pt;
}

.fs-14 {
  font-size: 14pt;
}
.fs-16 {
  font-size: 16pt;
}

.text-muted {
  color: #404040;
}

canvas {
  width: 100%;
  height: fit-content;
}

.print-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #333;
  padding: 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 2rem;
  max-width: 290mm;
  margin: 2rem auto;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.print-button img {
  margin-right: 1rem;
}

.header-print {
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  z-index: 10;
  padding: 5px 0px;
}
.checkbox-detail-per-page {
  display: block;
}
@media only print {
  .checkbox-detail-per-page {
    display: none;
  }

  .print-button {
    display: none;
  }

  .header-print {
    display: none;
  }

  .pagebreak {
    page-break-before: always;
  }

  @page {
    size: A5;
  }
  .page {
    height: 100vh !important;
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0;
  }
}
</style>
