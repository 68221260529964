<template>
  <a-row
    type="flex"
    :gutter="16"
  >
    <a-col :xs="{ span: 24 }" :lg="{ span: 5 }">
      <ol
        v-scroll-spy-link
        v-scroll-spy-active="{ class: 'active' }"
        class="position-sticky p-0 m-0 mt-4"
      >
        <li
          v-for="(label, key) in sections"
          :key="key"
        >
          <a
            class=""
            @click.prevent
          >{{ label }}</a>
        </li>
      </ol>
    </a-col>

    <a-col :xs="{ span: 24 }" :lg="{ span: 19 }">
      <ValidationObserver ref="validationObserver" slim>
        <a-form novalidate @submit.prevent="saveDetailProduct">
          <fieldset
            v-scroll-spy="{ data: 'activeSection', offset: 125 }"
            class="main"
            :disabled="submitting || loadingDetail"
          >
            <DetailProduct
              ref="detailproduct"
              v-model="model"
              :detail="detailModel"
              :categories="categories"
              :brands="brands"
              :loading="submitting || loadingDetail"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveDetail)"
              @saveProducts="saveDetailProduct"
              @changeEditable="changeEditable"
            />

            <PhotoAndVideo
              v-model="model"
              class="mb-5"
              :channel="listChannels"
              :permission="permission"
              @change="debouncedSaveImages"
              @changeEditable="changeEditable"
            />

            <TypeAndPrice
              ref="typeprice"
              v-model="model"
              :business="business"
              :loading="submitting || loadingDetail"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveCatalogues)"
              @changeuom="onChangeUom"
              @saveProducts="savePriceProduct"
              @changeEditable="changeEditable"
            />

            <WeightAndShipping
              v-model="model.units"
              :product="model"
              class="mb-5"
              @input:physical="(value) => model.detail.physical = value"
              @cancelBaseUomChange="(value) => {
                model.catalogs.items[0].price.uom = value
              }"
              @offscreen="runOnDraft(debouncedSaveWeightAndShipping)"
              @changeUkuranPaket="changeUkuranPaket"
            />

            <WarehouseAndInventories
              v-model="model"
              :warehouses="warehouses"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveWarehousesAndInventories)"
            />

            <!-- <ChannelPublication 
              v-if="$route.meta.title !== 'Powerbiz - Add Product Redeem' && $route.meta.title !== 'Powerbiz - Edit Product Redeem'"
              v-model="model.channels"
              :channels="channels"
              :product="model"
            /> -->

            <div class="mt-4 mb-5 py-3 border-top text-right footer">
              <!-- <div>
                <a-button
                  size="large"
                  type="primary"
                  ghost
                  class="px-5 mr-3 ml-auto"
                  @click="onCancel"
                >
                  {{ $t('utils.cancel') }}
                </a-button>

                :disabled="submitting || !$can('group1.product.product-master.WRITE')"
                <a-button
                  size="large"
                  type="primary"
                  class="px-5"
                  html-type="submit"
                  :disabled="submitting || !$can('group1.product.product-master.WRITE') || loadingDetail"
                >
                  <a-icon v-if="submitting" type="loading" class="mr-2" />
                  {{ $t('utils.save') }}
                </a-button>
              </div> -->

              <a-button
                size="large"
                type="primary"
                ghost
                class="px-5 mr-3 ml-auto"
                :disabled="submitting"
                @click="onCancel"
              >
                {{ $t('utils.back') }}
              </a-button>

              <a-button
                v-if="permission.includes('WRITE') && !$route.query.edit"
                size="large"
                type="primary"
                class="px-5"
                html-type="submit"
                :loading="submitting"
                :disabled="submitting || loadingDetail"
              >
                {{ $t('utils.save') }}
              </a-button>
            </div>
          </fieldset>
        </a-form>
      </ValidationObserver>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue'
import DetailProduct from './DetailProduct.vue'
import { ValidationObserver } from 'vee-validate'
import PhotoAndVideo from './PhotoAndVideo.vue'
import debounce from 'lodash/debounce'
import TypeAndPrice from './TypeAndPrice/index.vue'
import { useFormula } from './TypeAndPrice/Price/useFormula'
import WeightAndShipping from './WeightAndShipping/index.vue'
import WarehouseAndInventories from './WarehouseAndInventories/index.vue'
// import ChannelPublication from './Channel/ChannelPublication.vue'
import { CREATEPRODUCTREDEEM } from '@/api/channels/distributor'
import { updateProduct, updatePriceArea } from '@/api/channels/distributor'
import { getInventoryListV2, getUom, addWarehouse, saveProductInventory } from '@/api/inventory'
import { getShippingUnit, saveProductShipping } from '@/api/shipping'
import {
  getProductCategories,
  addProductChannel,
  productPublish,
  saveProductImages,
  crudProduct,
  updateCatalogsProduct,
  deleteProduct,
} from '@/api/product'
import { getPriceRange, saveProductPrice } from '@/api/price'
import { getBusinessChannel } from '@/api/business'
import { getWarehouses } from '@/api/warehouse'

const { fetchPriceFormula } = useFormula()

const DEFAULT_MODEL = () => ({
  id: null,
  status: 'DRAFT',
  detail: {
    brand_id: null,
    category_id: null,
    condition: 'NEW',
    imported: false,
    long_description: '',
    title: '',
    physical: true,
    vat_type: 'INCLUDE',
  },
  images: [],
  catalogs: {
    attributes: null,
    options: null,
    items: [
      {
        id: null,
        factory_sku: null,
        images: [],
        option: null,
        price: {
          currency: 'Koin',
          value: 0,
          min_qty: 1,
          price_qty: 1,
          uom: 'PCS',
          price_id: null,
          formula_id: null,
          product_variables: [],
        },
        sku: null,
        status: true,
        title: null,
        ext_catalog_id: null,
      },
    ],
    bundling: {
      type: null,
      bundles: [],
    },
  },
  units: [
    {
      unit: 'PCS',
      numerator: 1,
      denumerator: 1,
      package: {
        length: null,
        length_unit: 'cm',
        width: null,
        width_unit: 'cm',
        height: null,
        height_unit: 'cm',
        weight: null,
        weight_unit: 'g',
      },
      collapseOpen: false,
    },
  ],
  inventories: [],
  channels: [],
})

const parseErrorMessage = msg => {
  let result = 'Coba lagi beberapa saat'
  if (msg && msg.includes('Sku Already in Business')) {
    result = `Kode SKU ${msg.split(' ').pop()} telah terdaftar pada produk lain`
  }
  return 'Oops, produk gagal disimpan! ' + result
}

export default defineComponent({
  name: 'CreateProductForm',
  components: {
    DetailProduct,
    ValidationObserver,
    PhotoAndVideo,
    TypeAndPrice,
    WeightAndShipping,
    WarehouseAndInventories,
    // ChannelPublication,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    redeem: {
      type: Object,
      default: () => {},
    },
    listChannels: {
      type: [String, Number],
      default: 0,
    },
    permission: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      activeSection: 0,
      saving: {
        detail: false,
      },
      /**
       * @type {import('@/types/product').ProductFormModel}
       */
      model: DEFAULT_MODEL(),
      /** @type {import('@/types/warehouse').Warehouse[]} */
      warehouses: [],
      channels: [],
      shippingUnits: [],
      categories: [],
      submitting: false,
      loadingDetail: false,
      detailModel: {},
      brands: [],
      editable: {
        detail: false,
        photo: false,
        price: false,
      },
    }
  },
  computed: {
    sections() {
      const section = {
        detail: this.$t('product.product_details'),
        gallery: this.$t('product.photo_video'),
        price: this.$t('product.product_type_and_price'),
        shipping: this.$t('product.weight_product_shipping'),
        inventory: this.$t('product.inventory'),
      }
      return section
    },
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.business?.business_id || (this.$route.query.business_id
        ? String(this.$route.query.business_id)
        : null)
    },
    productId() {
      return this.model.id
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId,
      }
    },
    httpParams() {
      return {
        business_id: this.businessId,
      }
    },
    isNewProduct() {
      return !this.model.id
    },
  },
  watch: {
    redeem: {
      immediate: true,
      deep: true,
      handler() {
        this.createProductModel()
      },
    },
    productId: {
      immediate: true,
      handler(value) {
        if (value && this.redeem) {
          this.fetchShippingAndInventoryUnits()
          // this.fetchCatalogPrices()
          this.fetchInventory()
        }
      },
    },
    'businessId': {
      immediate: true,
      handler() {
        this.loadingDetail = true
        Promise.all([
          this.fetchCategories(),
          this.fetchWarehouse(),
          this.fetchChannels(),
          this.fetchFormula(),
          this.getBrands(),
        ])
        .finally(() => {
          this.loadingDetail = false
        })
      },
    },
    'model.detail.category_id'(ids) {
      const id = Number(ids[ids.length - 1])
      this.model.detail.category = !id ? {} : this.findCategory(this.categories, id)
    },
    listChannels() {
      this.initChannel()
    },
  },
  created() {
    this.debouncedSaveDetail = debounce(this.saveDetail, 50)
    this.debouncedSaveImages = debounce(this.saveImages, 500)
    this.debouncedSaveCatalogues = debounce(this.saveCatalogues, 500)
    this.debouncedSaveWeightAndShipping = debounce(this.saveWeightAndShipping, 500)
    this.debouncedSaveWarehousesAndInventories = debounce(this.saveWarehousesAndInventories, 500)
  },
  mounted() {
    this.initChannel()
  },
  methods: {
    changeEditable(e) {
      const key = Object.keys(e)[0];
      this.editable[key] = e[key];
    },
    async getBrands() {
      const data = await this.$store.dispatch('products/GETBRANDS', { business_id: this.businessId })
      this.brands = data
    },
    initChannel() {
      this.model.channels = [{
        business_int_id: 182,
        code: 'distributor_redeem',
        deleted: false,
        id: this.listChannels,
        title: 'Kino Redeem',
      }]
    },
    fetchFormula: debounce(async function(value) {
      fetchPriceFormula({ businessId: value })
    }, 500),
    changeUkuranPaket(val, index) {
      this.model.units.forEach((item, idx) => {
        if (idx === index) {
          item.package = {
            ...item.package,
            length_unit: val,
            width_unit: val,
            height_unit: val,
            // weight_unit: val,
          }
        }
      })
    },
    onChangeUom(uom) {
      this.model.catalogs.items.forEach(item => {
        item.price = { ...item.price, uom }
      })
    },
    runOnDraft(fn) {
      if (this.model.status === 'DRAFT') {
        return fn()
      }
    },
    fetchCategories: debounce(async function() {
      const { data: { data } } = await getProductCategories(this.businessId)
      this.categories = data || []
      const parent = this.redeem?.product?.category ? this.findParent(data, this.redeem.product.category) : []
      if (parent.length) this.model.detail.category_id = parent
    }, 500),
    async fetchInventory() {
      this.loadingDetail = true

      let bodyValue = {
        business_id: this.$route.query.business_id,
        workspace_id: this.$route.query.workspace_id,
        product_id: this.model.id,
      }
      
      await getInventoryListV2(bodyValue)
        .then(({ data }) => {
          let warehouses = []
          let inventories = []
          if(data?.length) {
            data.forEach((inventory, index) => {
              if(!inventories.includes(inventory.warehouse_id)) {
                warehouses.push({
                  business_id: inventory.business_id,
                  id: inventory.warehouse_id,
                  is_main: index == 0 ? true : false,
                  catalogs: [{
                    id: inventory.catalog_id,
                    uom: inventory.uom,
                    pre_order_status: inventory?.pre_order_status || inventory?.pre_order || false,
                    sellable: 0,
                    allocated: 0,
                    avail: inventory.availability,
                    on_hand: inventory.on_hand,
                    warehouse_id: inventory.warehouse_id,
                  }],
                })
                inventories.push(inventory.warehouse_id)
              } else {
                const idx = warehouses.findIndex(warehouse => warehouse.id === inventory.warehouse_id)
                if(idx > -1) {
                  warehouses[idx].catalogs.push({
                    id: inventory.catalog_id,
                    uom: inventory.uom,
                    pre_order_status: inventory?.pre_order_status || inventory?.pre_order || false,
                    sellable: 0,
                    allocated: 0,
                    avail: inventory.availability,
                    on_hand: inventory.on_hand,
                    warehouse_id: inventory.warehouse_id,
                  })
                }
              }
            })
          }
          this.model.inventories = warehouses
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => this.loadingDetail = false)
    },
    fetchWarehouse: debounce(async function() {
      const { data: { data } } = await getWarehouses({ business_id: this.businessId })
      this.warehouses = data?.data || []
    }, 500),
    fetchChannels: debounce(async function() {
      const { data: { data } } = await getBusinessChannel(this.businessId)
      this.channels = data?.channels || []
    }, 500),
    async fetchShippingAndInventoryUnits() {
      const fetchShippingUnitPromise = getShippingUnit({
        product_id: this.productId,
        business_id: this.businessId,
      })

      const fetchInventoryUnitPromise = getUom({
        id: this.productId,
        params: { business_id: this.businessId },
      })

      const [{ data: { data: shippingUnitResponse }}, { data: { data: inventoryUnitResponse }}] = await Promise.all([
        fetchShippingUnitPromise,
        fetchInventoryUnitPromise,
      ])

      const shippingUnits = shippingUnitResponse?.product_units || []
      const inventoryUnits = inventoryUnitResponse?.units || []

      this.model.units = shippingUnits.length
        ? shippingUnits.map(({ unit, ...unitPackage }) => ({
          unit,
          package: unitPackage,
          collapseOpen: false,
          ...(inventoryUnits.find(u => u.unit === unit) || {}),
        }))
        : inventoryUnits.map(({ unit, numerator, denumerator }) => {
          // eslint-disable-next-line no-unused-vars
          const { unit: _, ...unitPackage } = shippingUnits.find(u => u.unit === unit) || {}
          return {
            unit,
            numerator,
            denumerator,
            collapseOpen: false,
            package: unitPackage,
          }
        })
    },
    async fetchCatalogPrices() {
      const { data: { data } } = await getPriceRange({
        business_id: this.businessId,
        product_id: this.productId,
      })

      this.model.catalogs.items.forEach((item) => {
        const priceData = data.find(p => p.catalog_id === item.id)
        const price = priceData?.price[0]

        if (price) {
          item.price = {
            ...item.price,
            ...{ ...price, currency: 'Koin' },
          }
        }
      })
    },
    createProductModel() {
      const redeem = this.redeem

      if (!redeem?.product?.id) {
        return
      }

      this.model.id = redeem.product.id
      this.model.status = redeem.product.status
      this.model.detail = {
        brand_id: redeem.product.brand_name,
        category: {
          id: redeem.product.category,
          name: redeem.product.category_name,
        },
        condition: redeem.product.is_new == 1 ? 'NEW' : 'USED',
        id: redeem.product.id,
        imported: !!redeem.product.is_imported,
        long_description: redeem.product.long_description,
        physical: !!redeem.product.is_physical,
        short_description: redeem.product.short_description,
        status: redeem.product.status,
        title: redeem.product.title,
        start_period: redeem.product.start_period ? this.$moment(redeem.product.start_period).format('YYYY-MM-DD hh:mm:ss') : '',
        end_period: redeem.product.end_period ? this.$moment(redeem.product.end_period).format('YYYY-MM-DD hh:mm:ss') : '',
        category_name: redeem.product.category_name,
        category_id: [
          Number(redeem.product.category),
        ].filter(Boolean),
      },
      this.model.images = redeem.product.images.map(image => {
        const temp = image.url.includes('https://ik.imagekit.io/') ? image.url : `https://ik.imagekit.io/${image.url}`
        const url = temp.includes(image.name) ? temp : temp + '/' + image.name
        return {
          ...image,
          url,
          uploading: false,
          deleted: false,
          file: null,
        }
      })
      this.model.channels = [{
        id: this.listChannels,
        title: 'Kino Redeem',
        code: 'distributor_redeem', 
        deleted: false,
      }]

      const catalogItems = redeem.product.catalogs?.map((item, index) => {
        const price = redeem.price.find(el => el.catalog_id == item.id)
        return {
          factory_sku: item.sku,
          id: item.id,
          images: JSON.parse(JSON.parse(item.images)),
          option: item.options,
          product_id: item?.product_id || null,
          sku: item.sku,
          status: item.is_active,
          title: item.title,
          price: {
            currency: 'Koin',
            value: price?.price_msrp || 0,
            min_qty: price?.min_qty || 1,
            price_qty: price?.price_qty || 1,
            uom: price?.unit || 'PCS',
            price_id: price?.price_area_id || '0',
            formula_id: null,
            formula_name: null,
            formula_variables: [],
            global_variables: [],
            product_variables: [],
            version: index + 1,
          },
        }
      })

      this.model.catalogs = {
        attributes: redeem.product.attributes[0],
        options: redeem.product.options[0],
        items: catalogItems.length ? catalogItems : [...DEFAULT_MODEL().catalogs.items],
        bundling: {
          type: redeem.product.bundling_type || null,
          bundles: redeem.product?.bundles || [],
        },
      }

      this.detailModel = {
        title: redeem.product.title,
        long_description: redeem.product.long_description,
      }
    },
    async saveDetail(ctx, showMessage = true) {
      if (this.saving.detail) return
      this.saving.detail = true

      this.saveOrUpdateDetailProduct(showMessage)

      this.saving.detail = false
    },
    async saveOrUpdateDetailProduct(showMessage = true) {
      // eslint-disable-next-line no-unused-vars
      const { detail, ...model } = this.model
      try {
        const { data: { data } } = await crudProduct({
          business_id: this.business.business_id,
          id: model.id || undefined,
          ...this.model.detail,
          is_new: this.isNewProduct,
        })

        this.model.id = data.id
        // this.model.status = data.status
        if (showMessage) {
          this.$message.success(this.model.id ? 'Perubahan berhasil disimpan' : 'Draft produk berhasil disimpan', 5)
        }
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    async saveImages(images, showMessage = true) {
      try {
        const undeletedImages = images.filter(image => !image.deleted)
        const product_id = this.model.id
        // Hit service if product_id available
        if (product_id && !this.$route.query.edit) {
          await saveProductImages({
            product_id,
            business_id: this.business.business_id,
            images: undeletedImages.map(({ id, name, url, ref_id, order, tags }) => ({
              id,
              name,
              url,
              ref_id,
              order,
              tags,
            })),
            images_delete: images.filter(image => image.deleted).map(({ ref_id }) => ref_id),
            status: this.model.status,
          })
        }

        this.model.images = undeletedImages
        if (showMessage && product_id && !this.$route.query.edit) this.$message.success('Gambar produk berhasil disimpan', 5)
      } catch (err) {
        this.$message.error('Oops, gambar produk gagal disimpan', 5)
        throw err
      }
    },
    async saveCatalogues(ctx, showMessage = true) {
      if (!this.productId) {
        // we can save the pending state, once product id is available we can save the catalogues
        return
      }

      try {
        const isCatalogIdFilled = this.model.catalogs.items.every((item) => !!item.id || (!item.id && !item.status)) 
        const { units, catalogs: { bundling } } = this.model
        const saveCatalogItems = async () => {
          const { data: { data: { catalogs } } } = await updateCatalogsProduct({
            attributes: this.model.catalogs.attributes,
            options: this.model.catalogs.options,
            business_id: this.business.business_id,
            product_id: this.model.id,
            catalogs: this.model.catalogs.items.map(item => ({
              ...item,
              price: undefined,
              title: item.title || [
                this.model.detail.title,
                (Object.values(item.option || {}) || []).join(', '),
              ].filter(Boolean).join(' - '),
            })),
            bundling: bundling && bundling.type && bundling.bundles.length ? {
              bundling_type: bundling.type,
              bundles: bundling.bundles.map(item => ({
                catalog_id: item.catalog_id,
                quantity: item.quantity,
                uom: item.uom || units[0].unit || null,
              })),
              product_id: this.productId,
            } : null,
            // bundling: {
            //   bundles: [],
            //   type: 'SOFT',
            //   product_id: this.model.id,
            // },
          })

          this.model.catalogs.items.forEach((item) => {
            const catalog = catalogs 
              // ? catalogs[index] 
              ? catalogs.find(c => item.id ? c.id === item.id : c.sku === item.sku)
              : null

            if (catalog) {
              item.id = catalog.id
            }
          })
        }

        // const saveCatalogPromise = saveCatalogItems()
        // if catalog id is not filled, then wait to save catalog items first, otherwise we can call savePrice parallelly
        // if (!isCatalogIdFilled) await saveCatalogPromise

        if (!isCatalogIdFilled) await saveCatalogItems()

        this.$nextTick(async () => await this.savePrice())
      } catch (err) {
        if (showMessage) this.$message.error('Oops, catalog produk gagal disimpan', 5)
        throw err
      }
    },
    async savePrice() {
      const { data: response } = await saveProductPrice({
        business_id: this.business.business_id,
        product_id: this.model.id,
        catalog_price: this.model.catalogs.items
          .filter(i => i.id)
          .map(item => ({
            catalog_id: item.id,
            price: [item.price],
          })),
      })

      const catalogPrices = response?.data?.catalog_price || []

      catalogPrices.forEach(catalogPrice => {
        const { price, catalog_id } = catalogPrice
        const catalog = this.model.catalogs.items.find(c => c.id === catalog_id)

        const mainPrice = price[0]

        catalog.price.price_id = mainPrice.price_id
      })
    },
    async saveWeightAndShipping(ctx, showMessage = true) {
      try {
        await Promise.all([
          this.saveOrUpdateDetailProduct(showMessage),
          saveProductShipping({
            business_id: this.business.business_id,
            product_id: this.model.id,
            product_units: this.model.units.map((unit) => ({ unit: unit.unit, ...unit.package })),
            product_units_delete: [],
            status: this.model.status,
          }),
          saveProductInventory({
            business_id: this.business.business_id,
            product_id: this.model.id,
            units: this.model.units.map(({ unit, denumerator, numerator }) => ({ unit, numerator, denumerator })),
            units_delete: [],
          }),
        ])
      } catch (err) {
        if (showMessage) this.$message.error('Oops, weight dan shipping produk gagal disimpan', 5)
        throw err
      }
    },
    async saveWarehousesAndInventories(ctx, showMessage = true, warehouses) {
      try {
        await addWarehouse({
          business_id: this.businessId,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            warehouses: warehouses || this.model.inventories,
          },
        })
      } catch (err) {
        if (showMessage) this.$message.error('Oops, inventory produk gagal disimpan', 5)
        throw err
      }
    },
    async saveChannels(showMessage = true) {
      const channels = this.model.channels.filter((c) => !c.deleted).map(({id}) => id)
      const deletedChannels = this.model.channels.filter((c) => c.deleted).map(({id}) => id)

      if (!channels.length && !deletedChannels.length) return 

      try {
        await addProductChannel({
          business_id: this.businessId,
          product_id: this.model.id,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            channels,
            channels_delete: deletedChannels,
          },
        })
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    getFirstError() {
      const errors = this.$refs.validationObserver.errors
      for (const key in errors) {
        if (errors[key].length) return errors[key][0]
      }
      return null
    },
    async saveDetailProduct() {
      this.submitting = true
      const valid = await this.$refs.validationObserver.validate()

      if (!valid && !this.$route.query.edit) {
        this.submitting = false
        const firstErrorElement = document.querySelector('.ant-form-explain')
        if (firstErrorElement) {
          window.scrollTo({
            behavior: 'smooth',
            top: firstErrorElement.getBoundingClientRect()?.top + window.pageYOffset - 225,
          })
        }
        return this.$message.error("Beberapa bidang tidak valid, silahkan isi terlebih dahulu") // Beberapa bidang masih kosong
      }

      // const today = new Date(moment().format("YYYY-MM-DD")).getTime();
      // const from = new Date(moment(this.model.detail.start_period).format("YYYY-MM-DD")).getTime();
      // const to = new Date(moment(this.model.detail.end_period).format("YYYY-MM-DD")).getTime();

      const data = {
        id: this.redeem.product.id,
        title: this.model.detail.title,
        long_description: this.model.detail.long_description,
        product: {
          category: this.model.detail.category_id[this.model.detail.category_id.length - 1],
          short_description: this.model.detail.short_description,
          base_unit: this.redeem.product.base_unit,
          is_bundling: this.redeem.product.is_bundling,
          bundling_type: this.redeem.product.bundling_type,
          options: this.model.catalogs.options,
          attributes: this.model.catalogs.attributes || [],
          status: this.redeem.product.status,
          is_physical: this.redeem.product.is_physical,
          is_new: this.redeem.product.is_new,
          is_imported: this.redeem.product.is_imported,
          master_product_id: this.redeem.product.master_product_id,
          video_url: this.redeem.product.video_url,
          brand_id: this.model.detail.brand_id,
          start_period: this.$moment(this.model.detail.start_period),
          end_period: this.$moment(this.model.detail.end_period),
          catalogs : this.model.catalogs.items?.map(item => {
            const temp = {
              id: item.id,
              sku : item.sku,
              brand_sku : "",
              product_id: item.product_id,
              title : item.title,
              options : item.option,
              status : item.status ? "ACTIVE" : "NOT_ACTIVE",
              is_active : item.status,
              images: item.images || [],
              short_description : "",
              long_description : "",
              master_catalog_id : item.id,
            }
            return temp
          }) || null,
          warehouse_ids: this.redeem.product.warehouse_ids,
        },
      }
      
      try {
        const response = await updateProduct({ 
          channel_id: this.listChannels,
          business_id: this.businessId,
          data,
        })

        if(response.data.message.toLowerCase() === 'success') {
          this.$message.success('Produk berhasil di Simpan!', 5)
          this.$refs.detailproduct.handleEdit()
          this.$emit('update')
        } else {
          this.$message.error(parseErrorMessage(''), 5)
        }
      } catch (err) {
        this.submitting = false
        const { message } = err?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        throw err
      } finally {
        this.submitting = false
      }
    },
    async savePriceProduct() {
      this.submitting = true
      const catalog_price = this.model.catalogs.items.map(item => {
        const { currency, price_qty, min_qty, value, uom } = item.price
        return {
          catalog_id: item.id,
          price: [{
            currency,
            price_qty,
            min_qty,
            value,
            uom,
            price_area_id: '0',
            customer_category_id: '',
            effective_date: this.$moment().format(),
          }],
        }
      })
      
      try {
        const response = await updatePriceArea({
          id: this.model.id,
          workspace_id: this.$route.query.workspace_id,
          channel_id: this.listChannels,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            catalog_price,
          },
        })
        
        if(response.data.message.toLowerCase() === 'success') {
          this.$message.success('Produk berhasil di Simpan!', 5)
          this.$refs.typeprice.handleEdit()
        } else {
          this.$message.error(parseErrorMessage(''), 5)
        }
      } catch(err) {
        this.submitting = false
        const { message } = err?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        throw err
      } finally {
        this.submitting = false
      }
    },
    async saveProducts() {
      this.submitting = true
      const valid = await this.$refs.validationObserver.validate()

      if (!valid && !this.$route.query.edit) {
        this.submitting = false
        const firstErrorElement = document.querySelector('.ant-form-explain')
        if (firstErrorElement) {
          window.scrollTo({
            behavior: 'smooth',
            top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
          })
        }
        return this.$message.error("Beberapa bidang tidak valid, silahkan isi terlebih dahulu") // Beberapa bidang masih kosong
      }

      /** @NOTES : MN-231 */
      const parseWarehouses = [...this.model.inventories].map(item => ({
        ...item,
        catalogs: item.catalogs.map(cat => ({ ...cat, id: ('' + cat.id).startsWith('TEMP_CATALOG_ID_') ? null : cat.id }) ),
      }))
      // console.log('saveProducts this.model: ', this.model)
      // console.log('saveProducts parseWarehouses: ', parseWarehouses)
      // if (this.model) {
      //   return
      // }

      try {
          let inventories = []
          const today = new Date(this.$moment().format("YYYY-MM-DD")).getTime();
          const from = new Date(this.$moment(this.model.detail.start_period).format("YYYY-MM-DD")).getTime();
          const to = new Date(this.$moment(this.model.detail.end_period).format("YYYY-MM-DD")).getTime();

          this.model.inventories.forEach(inventory => {
            inventory.catalogs.forEach(catalog => {
              inventories.push({
                catalog_id: catalog.id,
                business_id: this.businessId,
                product_id: this.model.id,
                warehouse_id: inventory.id,
                channel_id: this.listChannels,
                uom: catalog.uom,
                pre_order_status: catalog.pre_order_status,
              })
            })
          })

          let data = {
            unit: {
              bussiness_id: this.businessId,
              product_id: this.model.id,
              units: this.model.units.map(item => {
                return {
                  unit: item.unit,
                  numerator: item.numerator,
                  denumerator: item.denumerator,
                }
              }),
            },
            inventory: inventories,
            price: {
              channel_id: this.listChannels,
              workspace_id: this.$route.query.workspace_id,
              product_id: this.model.id,
              business_id: this.businessId,
              catalog_price: this.model.catalogs.items?.map(item => {
              const { price_qty, min_qty, value, uom } = item.price
              const temp = {
                catalog_id: item.id,
                price: [{
                  currency: 'Koin',
                  price_qty,
                  min_qty,
                  value,
                  uom,
                  price_area_id: '0',
                  customer_category_id: '',
                  effective_date: this.$moment(this.model.detail.start_period),
                }],
              }
              return temp
            }) || null,
            },
            product: {
              title : this.model.detail.title,
              category : this.model.detail.category_id[0],
              channel_id: this.listChannels,
              business_id: this.businessId,
              short_description : this.model.detail.short_description,
              long_description : this.model.detail.long_description,
              base_unit : this.model.units[0]?.unit,
              is_bundling : this.model.catalogs.bundling?.bundles.length > 0 ? 1 : 0,
              bundlingType : this.model.catalogs.bundling?.type,
              options : this.model.catalogs.options?.map(item => {
                const temp = {
                  variant: item,
                }
                return temp
              }) || null,
              attributes : this.model.catalogs.attributes || [],
              status : today >= from && today <= to ? "ACTIVE" : "NOT_ACTIVE",
              is_physical : this.model.detail.physical ? 1 : 0,
              is_new : this.model.detail.condition?.toLowerCase() === 'new' ? 1 : 0,
              is_imported : this.model.detail.imported ? 1 : 0,
              master_product_id : this.model.id,
              video_url : this.model.video_url,
              brand_id : "",
              start_period : this.$moment(this.model.detail.start_period),
              end_period : this.$moment(this.model.detail.end_period),
              catalogs : this.model.catalogs.items?.map(item => {
                const temp = {
                      sku : item.sku,
                      brand_sku : "",
                      title : item.title,
                      options : item.option ? this.separateObject(item.option) : [],
                      status : today >= from && today <= to ? "ACTIVE" : "NOT_ACTIVE",
                      is_active : today >= from && today <= to ? true : false,
                      short_description : "",
                      long_description : "",
                      master_catalog_id : item.id,
                      images: item.images || [],
                  }
                  return temp
              }) || null,
              images: this.model.images.map(item => {
                const temp = {
                  url: item.url,
                  name: item.name,
                  slug: item.slug,
                  order: item.order,
                  tags: item.tags,
                  ref_id: item.ref_id,
                }
                return temp
              }) || [],
              warehouse_ids: this.model.inventories.map(item => { return item.id }),
            },
            shippment: {
              business_id: this.businessId,
              product_id: this.model.id,
              shipping_units: this.model.units.map(item => {
                return {
                  unit: item.unit,
                  ...item.package,
                }
              }),
            },
          }

          if (this.$route.query.edit) {
            data = {
              id: this.model.id,
              title: this.model.detail.title,
              long_description : this.model.detail.long_description,
            }
          }

          let response = null
          if (this.$route.query.edit) {
            response = await updateProduct(
              { 
                channel_id: this.$route.params.id,
                business_id: this.$route.query.business_id || this.$store.state.user.businessList[0].business_id,
                data,
              },
            )
          } else {
            await CREATEPRODUCTREDEEM(
              { 
                // id: this.$route.params.id,
                business_id: this.$route.query.business_id || this.$store.state.user.businessList[0].business_id,
                data,
              },
            )
          }

          if (this.$route.query.edit || this.$route.query.master_product) {
            if (response.data.message?.toUpperCase() === 'SUCCESS' || response.data.status?.toUpperCase() === 'SUCCESS') {
              if (!this.$route.query.edit) {
                this.$message.success('Produk berhasil di Simpan!', 5)
                this.$refs.validationObserver.reset()

                if (this.model.status === 'DRAFT') {
                  this.model.status = response.data.data.status
                  await deleteProduct(this.model.id)
                }

                this.$nextTick(() => setTimeout(() => {
                  this.submitting = false
                  const { workspace_id, business_id, redirect_url } = this.$route.query
                  this.$router.push({ path: redirect_url, query: { workspace_id, business_id } })
                }, 150))
              } else {
                this.$message.success('Produk berhasil di Simpan!', 5)
                this.submitting = false
              }
            }
          } else if (!this.$route.query.edit && !this.$route.query.master_product) {
            await Promise.all([
              // this.saveOrUpdateDetailProduct(false),
              this.saveCatalogues(null, false),
              this.saveWeightAndShipping(null, false),
            ])

            await this.saveWarehousesAndInventories(null, false, parseWarehouses)

            if (this.model.status === 'DRAFT') {
              await productPublish({
                id: this.model.id,
                business_id: this.businessId,
              })
              this.model.status = 'ACTIVE'
            }

            // await this.saveChannels(false)

            this.$message.success('Produk berhasil di Simpan!', 5)
            this.$refs.validationObserver.reset()

            this.$nextTick(() => setTimeout(() => {
              this.submitting = false
              // this.businessId
              const { workspace_id, business_id, redirect_url } = this.$route.query
              this.$router.push({ path: redirect_url || '/product', query: { workspace_id, business_id } })
            }, 150))
          }
      } catch (err) {
        this.submitting = false
        const { message } = err?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        throw err
      } finally {
        this.submitting = false
      }
    },

    separateObject(obj) {
      const res = [];
      const keys = Object.keys(obj);
      keys.forEach(key => {
          res.push({
            [key]: obj[key],
          });
      });
      return res;
    },

    beforeRouteLeave(to, from, next) {
      const isDraftProduct = this.model.status === 'DRAFT'
      const dirty = this.$refs.validationObserver.flags.dirty

      if (!this.permission.includes('WRITE')) return next()

      if (!dirty && !(this.model.id && isDraftProduct)) return next()

      if (!this.editable.detail && !this.editable.photo && !this.editable.price) return next()

      this.$confirm({
        title: 'Data Belum Tersimpan',
        icon: () => null,
        content: isDraftProduct 
          ? 'Apakah Anda yakin akan menutup halaman ini, Data yang sudah diisi tidak akan disimpan' 
          : 'Perubahan akan hilang saat anda keluar dari halaman ini',
        okText: isDraftProduct ? 'Ya, Keluar dari halaman ini' : 'Ya, Keluar dari halaman ini',
        class: 'leaving-product-confirm',
        onOk: () => {
          this.deleteDraftProduct()
          next()
        },
      })

      next(false)
    },
    async deleteDraftProduct() {
      const isDraft = this.model.status === 'DRAFT'
      if (isDraft && this.model.id) await deleteProduct(this.model.id)
    },
    onCancel() {
      const redirectUrl = this.$route.query.redirect_url
      // eslint-disable-next-line no-unused-vars
      const { id, edit, ...query } = this.$route.query

      if (redirectUrl) {
        return this.$router.push(redirectUrl)
      }

      return this.$router.push({
        name: 'redeem-channel.redeem-product-channel',
        query,
      })
    },
    findChildren(item, matched) {
      if(item.id === matched) {
        return [item.parent_id, item.id]
      } else if(item.children) {
        let result = null
        let length = item.children.length
        for (let i = 0; i < length; i++) {
          result = this.findChildren(item.children[i], matched)
          if (result) length = i + 1
        }
        return result
      }
      return null
    },
    findParent(array, matched) {
      let found = null
      let length = array.length
      for (let i = 0; i < length; i++) {
        if ((!array[i].children || !array[i].children.length) && array[i].id == matched) {
          found = [array[i].id]
        } else {
          found = this.findChildren(array[i], matched)
          if (found) {
            if (!found.includes(array[i].id)) found.unshift(array[i].id)
            length = i + 1
          }
        }
      }
      return found || []
    },
    findCategory(lists, matched) {
      let length = lists.length
      let result = null
      for (let i = 0; i < length; i++) {
        if (lists[i].id == matched) {
          const { id, name } = lists[i]
          return { id, name }
        } else if (lists[i].children) {
          result = this.findCategory(lists[i].children, matched)
          if (result.id) return result
        }
      }
      return {}
    },
  },
})
</script>

<style scoped lang="scss">
.footer {
  bottom: 0;
  background-color: #F8F9FA;
  // border-top: 1px solid #efefef;
  z-index: 3;
}

ol {
  list-style: none;
  top: 130px;

  > li {
    position: relative;
    padding: .5rem 2rem;

    a {
      color: #999;
    }

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 20rem;
      background: #999;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      content: '';
      width: 1px;
      background: #999;
      top: 50%;
      left: 4.5px;
      transform: translateY(-50%);
      height: 100%;
      z-index: -1;
    }

    &:first-child::after {
      height: 50%;
      transform: none;
    }

    &:last-child::after {
      height: 50%;
      transform: none;
      top: 0;
    }

    &.active {
      a {
        color: var(--color-black);
        font-weight: 500;
      }

      &::before {
        background: var(--color-accent);
      }
    }
  }
}
</style>

<style lang="scss">
[data-kit-theme="default"] {
  .leaving-product-confirm {
    text-align: center;

    .ant-modal-confirm-title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .ant-modal-confirm-content {
      color: #999;
    }

    .ant-modal-confirm-btns {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      button {
        width: 100%;
        margin: 15px 0 0 0 !important;
        height: 45px;
        border: none;
      }
    }
  }
}
</style>
